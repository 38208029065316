// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/

export const BoundFillLayer = {
    id: 'boundary-fill-layer',
    type: 'fill',

    paint: {
      // Expressions reference: https://docs.mapbox.com/mapbox-gl-js/style-spec/expressions/#id
      // Note custom colours probably require setting up a data source that can easily be mapped
      // to the tiles (i.e. using mapbox's feature id number).
      'fill-color':  ['match', ["%", ['to-number', ['id']], 9],
                    0, '#285b9c',
                    1, '#56adf4',
                    2, '#65af98',
                    3, '#b3579a',
                    4, '#47bba6',
                    5, '#b3579a', // pink
                    6, '#99ccff',
                    7, '#AD7BB6', //grey
                    8, '#36916a',
                    '#0A659E'],
      'fill-opacity': 0.5,
      'fill-outline-color': '#444'

    }
  };


  export const AnalyticsLayer = (startCol, endCol) => {
    return {
    id: 'boundary-fill-layer',
    type: 'fill',

    paint: {
      'fill-color':
        ['case',
          ['==', ['feature-state', 'value'], 0], 'rgba(237, 241, 245, 1)',

          ['!=', ['feature-state', 'value'], '#000'],
        //   ['interpolate', ['linear'], ['get', 'value'], 0, 'pink', 500, 'blue'],
          ['interpolate', ['linear'], ['feature-state', 'value'], 0, startCol, 5, endCol],
          'rgba(255, 255, 255, 0)'],

      'fill-opacity': 0.75,
      'fill-outline-color': '#444'
    },
  }
};


  export const clusterLayer = {
    id: 'clusters',
    type: 'circle',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#285b9c', 150, '#47bba6', 1000, '#b3579a'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40]
    }
  };

  export const clusterCountLayer = {
    id: 'cluster-count',
    type: 'symbol',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
    paint: {
      'text-color': '#fff'
    }
  };

  export const unclusteredPointLayer = {
    id: 'unclustered-point',
    type: 'circle',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': '#56adf4',
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff'
    }
  };
