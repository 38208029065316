import React, { useState, useContext, useEffect } from 'react';
import AppContext from 'src/contexts-global/AppContext';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import PlaceIcon from '@mui/icons-material/Place';
import theme from 'src/themes';

const LocationSelector = () => {

  const { appState, setLocation, location, locationsList } = useContext(AppContext);

  // For Select element
  const [locationId, setLocationId] = useState(null)

  useEffect(() => {
    if (!location) {
      return
    }
    setLocationId(location?.id)
  }, [location])

  const handleLocationChange = (event) => {
    for (let i = 0; i < locationsList.length; i++) {
      if (locationsList[i].id === event.target.value) {
        setLocation(locationsList[i])  
      }
    }

    // console.log(event.target.value)
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel>Location</InputLabel>
        <Select
          startAdornment={<PlaceIcon sx={{
            color: theme.color.midBlue,
            marginRight: '5px',
          }}/>}
          sx={{
            fontFamily: "'Poppins', sans-serif",
            fontSize: '16px',
            color: theme.color.black,
          }}
          value={locationId ? locationId : ""}
          label="Location"
          onChange={handleLocationChange}
        >
          {locationsList?.map((location) => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default LocationSelector