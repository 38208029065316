import React, { useState, useLayoutEffect, useEffect, useRef, useContext } from 'react'
import moment from 'moment'
import AppContext from 'src/contexts-global/AppContext'
import Map from 'src/components/map/Map'
import PdfButton from 'src/components/pdf-button/PdfButton'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import HelpTooltip from 'src/components/common/tooltip-help'
import DataCard from 'src/components/common/data-display-card'
import MapCard from 'src/components/common/map-card'
import CardTitle from 'src/components/common/card-title-template'
import PageContainer from 'src/components/common/page-container'
import FlexColumnContainer from 'src/components/common/flex-column-container'
import FlexRowContainer from 'src/components/common/flex-row-container'
import DashboardToolbar from 'src/components/dashboard-toolbar/DashboardToolbar'
import ModularBarGraph from 'src/components/graphs-charts/modularBar'
import ModularPieChart from 'src/components/graphs-charts/modularPie'
import dayjs from 'dayjs'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'

import { GetTrafficMetrics } from 'src/requests/api-requests'
import theme from 'src/themes'
import MetricCard from 'src/components/metric-card'
import { blue } from '@mui/material/colors'
import CustomCard from 'src/components/common/custom-card'
import PersonaSelector from 'src/components/dashboard-toolbar/selectors/PersonaSelector'
import { Groups } from '@mui/icons-material'
import ModularLineGraph from 'src/components/graphs-charts/modularLine'
import { NivoLocationBar } from 'src/components/graphs-charts/nivoBar'

const isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

const TrafficPage = () => {
  // Global context (for persona)
  const {
    appState,
    setAppState,
    setIsLoading,
    setLocation,
    location,
    locationsList,
    allPersonas,
    setAllPersonas,
    setSelectedPersonas,
  } = useContext(AppContext)

  // Page state (for date, location, and filtered report data)
  const [reportData, setReportData] = useState(null)
  const [selectedMonthData, setSelectedMonthData] = useState(null)
  const [previousMonthData, setPreviousMonthData] = useState(null)

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [trafficLineGraphData, setTrafficLineGraphData] = useState(null)

  const [returningVsNewBarGraphData, setReturningVsNewBarGraphData] = useState(null)
  const [dwellTimeBarGraphData, setDwellTimeBarGraphData] = useState(null)
  const [summaryData, setSummaryData] = useState(null)

  const [personaDwellTimeData, setPersonaDwellTimeData] = useState()
  const [personaKeys, setPersonaKeys] = useState()

  const [personaTotalData, setPersonaTotalData] = useState()

  const trafficLineGraphOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
          boxWidth: 10,
        },
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  useLayoutEffect(() => {
    setIsLoading(false)
    setLocation(null)
    setSelectedPersonas([])
    setAllPersonas([])
    setSummaryData(null)
    setReturningVsNewBarGraphData(null)
    setDwellTimeBarGraphData(null)
    setTrafficLineGraphData(null)
    setStartDate(null)
    setEndDate(null)
  }, [])

  useEffect(() => {
    if (!locationsList) {
      return
    }

    if (locationsList[0]) {
      setLocation(locationsList[0])
    }
  }, [locationsList])

  useEffect(() => {
    if (location && localStorage.getItem('token')) {
      try {
        GetTrafficMetrics(startDate, endDate, location) // API request for metrics data
          .then(data => {
            if (data?.results) {
              for (const date in data.results) {
                if (data.results[date].overall) {
                  const total_count = data.results[date].overall.total_count || 0
                  const repeat_count = data.results[date].overall.repeat_count || 0
                  data.results[date].overall.first_time_count = total_count - repeat_count
                }
              }
            }
            return data
          })
          .then(data => {
            console.log(data?.results)
            setReportData(data?.results)
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }, [location, startDate, endDate])

  useEffect(() => {
    if (reportData) {
      console.log(reportData)
      const dateStringList = Object.keys(reportData)
      console.error(dateStringList)
      // Update date picker to show default dates

      if (!startDate && !endDate) {
        const defaultStartDate = dayjs(dateStringList[0])
        const defaultEndDate = dayjs(dateStringList[dateStringList.length - 1])

        setStartDate(defaultStartDate)
        setEndDate(defaultEndDate)
      }

      // Set bar graph labels and data

      if (startDate && endDate) {
        // Calculate the start date of the week based on endDate
        const endOfWeekDate = endDate
        const startOfWeekDate = endOfWeekDate.startOf('week')

        // Generate labels for the days of the week
        const daysOfWeek = [...Array(7)].map((_, index) => {
          const day = startOfWeekDate.add(index, 'day')
          return day.format('dddd')
        })

        // Find the index of endDate's day of the week
        const endDateDayOfWeek = endOfWeekDate.format('dddd')
        const endIndex = daysOfWeek.indexOf(endDateDayOfWeek) + 1

        // Reorder the labels based on the index of endDate's day
        let xLabels = daysOfWeek.slice(endIndex).concat(daysOfWeek.slice(0, endIndex))

        if(endDate.diff(startDate, 'day') < 6) {
          xLabels = xLabels.slice(6 - endDate.diff(startDate, 'day'))
        }

        console.log(xLabels)

        // Function for creating bar graph data in array form
        const getBarGraphData = (data, field, x = xLabels.length) => {
          const metricsForLastXDays = Object.keys(data)
            .slice(-x)
            .map(date => {
              const dateData = data[date]
              console.log(dateData)
              return dateData.overall && dateData.overall[field] ? dateData.overall[field] : 0
            })
          return metricsForLastXDays
        }

        if (xLabels.length > 0) {
          setReturningVsNewBarGraphData({
            labels: xLabels,
            datasets: [
              {
                label: 'Returning',
                data: getBarGraphData(reportData, 'repeat_count'),
                backgroundColor: [theme.color.bgBlue],
              },
              {
                label: 'New',
                data: getBarGraphData(reportData, 'first_time_count'),
                backgroundColor: [theme.color.blue],
              },
            ],
          })

          setDwellTimeBarGraphData({
            labels: xLabels,
            datasets: [
              {
                data: getBarGraphData(reportData, 'dwell_time'),
                backgroundColor: [theme.color.bgBlue],
              },
            ],
          })
        }

        // Create chips for persona selector
        const possiblePersonas = {}
        for (const date in reportData) {
          const personas = reportData[date].personas
          for (const persona in personas) {
            possiblePersonas[persona] = true
          }
        }
        const personasObject = Object.keys(possiblePersonas).map(persona => ({
          chipLabel: persona.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()),
          chipValue: persona,
        }))
        setAllPersonas(personasObject)
        setSelectedPersonas(
          personasObject.map(persona => {
            return persona.chipValue
          }),
        )
      }

      // Set summary data

      if (startDate && endDate) {
        const endDateMonthYearString = endDate.format('YYYY-MM') // Extract YYYY-MM from endDate
        const startDateObj = startDate
        const endDateObj = endDate
        

        // Filter data for the selected month (endDate's month)
        setSelectedMonthData(
          dateStringList.reduce((result, date) => {
            let curDateObj = new Date(date.replace(/-/g,'/'));  

            if (curDateObj >= startDateObj && curDateObj <= endDateObj) {
              result[date] = reportData[date]
            }
            return result
          }, {}),
        )

        // Calculate the month prior to endDate
        const previousMonthEndDate = endDate.subtract(1, 'month')
        const previousMonthEndDateMonthYearString = previousMonthEndDate.format('YYYY-MM')

        // Filter data for the month prior to endDate
        setPreviousMonthData(
          dateStringList.reduce((result, date) => {
            const monthYearString = dayjs(date).format('YYYY-MM') // Extract YYYY-MM from item's date
            if (monthYearString === previousMonthEndDateMonthYearString) {
              result[date] = reportData[date]
            }
            return result
          }, {}),
        )
      }
    }
  }, [reportData])

  useEffect(() => {
    // Function for calculating average dwell times in minutes
    const calculateAverageDwellTime = data => {
      const dwellTimeData = Object.values(data)
      const totalDwellTime = dwellTimeData.reduce((sum, dateData) => {
        return (
          (sum + (dateData.overall && dateData.overall.dwell_time ? dateData.overall.dwell_time : 0))
        )
      }, 0) / 60
      const averageDwellTime = Math.round(totalDwellTime / dwellTimeData.length)
      console.log(averageDwellTime)
      return averageDwellTime
    }

    if (selectedMonthData && previousMonthData) {
      console.log(selectedMonthData)
      setSummaryData({
        totalVisits: Object.values(selectedMonthData).reduce((sum, dateData) => {
          return (
            sum +
            (dateData.overall && dateData.overall.total_count ? dateData.overall.total_count : 0)
          )
        }, 0),
        returningVisits: Object.values(selectedMonthData).reduce((sum, dateData) => {
          return (
            sum +
            (dateData.overall && dateData.overall.repeat_count ? dateData.overall.repeat_count : 0)
          )
        }, 0),
        firstTimeVisits: Object.values(selectedMonthData).reduce((sum, dateData) => {
          return (
            sum +
            (dateData.overall && dateData.overall.first_time_count
              ? dateData.overall.first_time_count
              : 0)
          )
        }, 0),
        avgDwellTime: calculateAverageDwellTime(selectedMonthData),
        prevTotalVisits: Object.values(previousMonthData).reduce((sum, dateData) => {
          return (
            sum +
            (dateData.overall && dateData.overall.total_count ? dateData.overall.total_count : 0)
          )
        }, 0),
        prevReturningVisits: Object.values(previousMonthData).reduce((sum, dateData) => {
          return (
            sum +
            (dateData.overall && dateData.overall.repeat_count ? dateData.overall.repeat_count : 0)
          )
        }, 0),
        prevFirstTimeVisits: Object.values(previousMonthData).reduce((sum, dateData) => {
          return (
            sum +
            (dateData.overall && dateData.overall.first_time_count
              ? dateData.overall.first_time_count
              : 0)
          )
        }, 0),
        prevAvgDwellTime: calculateAverageDwellTime(previousMonthData),
      })

      setTrafficLineGraphData({
        labels: Object.keys(selectedMonthData),
        datasets: [
          {
            label: 'Returning',
            data: Object.values(selectedMonthData).map(singleDayData => {
              return singleDayData.overall.repeat_count
            }),
            borderColor: theme.color.bgBlue,
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'New',
            data: Object.values(selectedMonthData).map(singleDayData => {
              return singleDayData.overall.first_time_count
            }),
            borderColor: theme.color.blue,
            borderWidth: 2,
            fill: false,
          },
        ],
      })
    }
  }, [selectedMonthData, previousMonthData, startDate, endDate])

  // Date Handler

  const handleDateChange = newDateRange => {
    setStartDate(newDateRange[0])
    setEndDate(newDateRange[1])
  }

  const customColors = ['pink', 'teal']

  const returningVsNewBarGraphOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
          boxWidth: 10,
        },
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    // barThickness: 30,

    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  const dwellTimeBarGraphOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
        reverse: true,
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    // barThickness: 30,

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  const hourlyOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
        reverse: true,
      },
      title: {
        display: true,
        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  // Calculate the start date of the week based on endDate
  const [hourlyData, setHourlyData] = useState([])
  const [hourlyLabels, setHourlyLabels] = useState([])

  useEffect(() => {
    let hourlyLabels = []
    let hourlyData = []

    for (let i = 0; i < 10; i++) {
      let amOrPm = 'am'
      let hour = i + 9
      if (hour > 12) {
        hour = hour - 12
        amOrPm = 'pm'
      }
      hourlyLabels.push(`${hour}:00 ${amOrPm}`)
      // hourlyData.push(Math.random() * 300)
    }

    hourlyData = [453, 702, 1032, 1625, 1832, 1879, 1796, 1642, 1529, 1221]
    setHourlyLabels(hourlyLabels)
    setHourlyData(hourlyData)
  }, [])

  useEffect(() => {
    if(summaryData?.prevFirstTimeVisits) {
      console.log(summaryData.prevFirstTimeVisits)
    } else {
      console.log('summaryData.prevFirstTimeVisits NOT FOUND')
    }
  }, [summaryData?.prevFirstTimeVisits])

  const trafficLabels = ['January', 'February', 'March', 'April', 'May', 'June']
  const trafficData = [546, 679, 400, 694, 700, 759]

  // styles
  const valueTooltipContainerStyle = {
    minWidth: '200px',
    margin: '10px',
    backgroundColor: theme.color.turquoise,
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
    borderRadius: '12px',
  }

  const graphContainerStyle = {
    minWidth: '200px',
    margin: '.5rem',
    boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
  }

  // Calculate the percentage difference for prev month summary comparison
  const calculatePercentageDifference = (currentValue, prevValue) => {
    if (prevValue === 0) {
      return 0 // Handle division by zero
    }
    return ((currentValue - prevValue) / prevValue) * 100
  }

  // Utility function
  const titleCaseToSnakeCase = string => {
    return string
      .split(' ')
      .filter(x => x.length > 0)
      .map((x, index) => (index === 0 ? x.toLowerCase() : '_' + x.toLowerCase()))
      .join('')
  }

  // Total visitor dwell
  useEffect(() => {
    if (
      !appState.selectedPersonas ||
      !appState.selectedPersonas.length ||
      !startDate ||
      !endDate ||
      !reportData
    ) {
      return
    }

    let tempDwellTimeData = []
    let tempTotalData = []

    // Calculate the start date of the week based on endDate
    const endOfWeekDate = endDate
    const startOfWeekDate = endOfWeekDate.startOf('week')

    // Generate labels for the days of the week
    const daysOfWeek = [...Array(7)].map((_, index) => {
      const day = startOfWeekDate.add(index, 'day')
      return day.format('dddd')
    })

    // Find the index of endDate's day of the week
    const endDateDayOfWeek = endOfWeekDate.format('dddd')
    const endIndex = daysOfWeek.indexOf(endDateDayOfWeek) + 1

    // Reorder the labels based on the index of endDate's day
    const xLabels = daysOfWeek.slice(endIndex).concat(daysOfWeek.slice(0, endIndex))

    // Function for creating bar graph data in array form
    const getPersonaBarGraphData = (data, field, persona, x = xLabels.length) => {
      const metricsForLastXDays = Object.keys(data)
        .slice(-x)
        .map(date => {
          const dateData = data[date]
          return dateData.personas[persona] && dateData.personas[persona][field]
            ? dateData.personas[persona][field]
            : 0
        })
      return metricsForLastXDays
    }

    // for (let i = 0; i < xLabels.length; i++) {
    //   let tempDwellTimeDataItem = {
    //     time: xLabels[i],
    //   }
    //   let tempTotalDataItem = {
    //     time: xLabels[i],
    //   }

    //   for (let personaIndex = 0; personaIndex < appState.selectedPersonas.length; personaIndex++) {
    //     // tempDataItem[appState.selectedPersonas[personaIndex]] = getDummyValue()
    //     // tempTotalDataItem[appState.selectedPersonas[personaIndex]] = getDummyValue()
    //     tempDwellTimeDataItem[appState.selectedPersonas[personaIndex]] = getPersonaBarGraphData(
    //       reportData,
    //       'dwell_time',
    //       titleCaseToSnakeCase(appState.selectedPersonas[personaIndex]),
    //     )[i]
    //     tempTotalDataItem[appState.selectedPersonas[personaIndex]] = getPersonaBarGraphData(
    //       reportData,
    //       'total_count',
    //       titleCaseToSnakeCase(appState.selectedPersonas[personaIndex]),
    //     )[i]
    //   }
    //   tempDwellTimeData.push(tempDwellTimeDataItem)
    //   tempTotalData.push(tempTotalDataItem)
    // }


    for (let i = 0; i < appState.selectedPersonas.length; i++) {
      let tempDwellTimeDataItem = {
        persona: appState.selectedPersonas[i],
        data: Object.values(reportData).reduce((a, b, index) => {
          let c
          try {
            c = b['personas'][appState.selectedPersonas[i]]['dwell_time']
          } catch(e) {
            return null
          }
          return a + c
        }, 0)
      }

      let tempTotalDataItem = {
        persona: appState.selectedPersonas[i],
        data: Object.values(reportData).reduce((a, b, index) => {
          let c
          try {
            c = b['personas'][appState.selectedPersonas[i]]['total_count']
          } catch(e) {
            return null
          }
          return a + c
        }, 0)
      }

      // console.log(tempDwellTimeDataItem)
      // console.log(tempTotalDataItem)
      // console.log(reportData)

      tempDwellTimeData.push(tempDwellTimeDataItem)
      tempTotalData.push(tempTotalDataItem)

    }



    // // Keys
    // let keyList = []
    // for (let personaIndex = 0; personaIndex < appState.selectedPersonas.length; personaIndex++) {
    //   keyList.push(appState.selectedPersonas[personaIndex])
    // }
    // setPersonaKeys(keyList)
    setPersonaDwellTimeData(tempDwellTimeData)
    setPersonaTotalData(tempTotalData)
    console.log('tempDwellTimeData:')
    console.log(tempDwellTimeData)
    console.log('tempTotalData:')
    console.log(tempTotalData)
  }, [appState.selectedPersonas, reportData])

  return (
    <PageContainer>
      <div id="pdf-content" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {/* Dash Controls */}
        <Box
          container
          sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
            marginTop: '32px',
            marginBottom: '32px',
          }}
        >
          <DashboardToolbar
            page="traffic"
            startDate={startDate}
            endDate={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            onDateChange={handleDateChange}
          />
        </Box>

        <Box
          container
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <MetricCard
            title="Total Visits"
            metric={summaryData?.totalVisits ? summaryData.totalVisits : 0}
            helpText="The total number of visits this month."
            changeLabel={
              summaryData?.prevTotalVisits ? 
                <Typography
                  sx={{
                    fontSize: '16px',
                  }}
                  color={'rgba(0,0,0,0.6)'}
                >
                  <ArrowDownwardIcon
                    fontSize="16px"
                    sx={{
                      color: 'rgba(0,0,0,0.6)',
                    }}
                  />
                  {` ${calculatePercentageDifference(
                    summaryData?.totalVisits,
                    summaryData?.prevTotalVisits,
                  ).toFixed(2)}% than last month`}
                </Typography>
              : null
            }
          />
          <MetricCard
            title="First Time Visits"
            metric={summaryData?.firstTimeVisits ? summaryData.firstTimeVisits : 0}
            helpText="The total number of first time visits this month."
            changeLabel={
              summaryData?.prevFirstTimeVisits ? 
                <Typography
                  sx={{
                    fontSize: '16px',
                  }}
                  color={'rgba(0,0,0,0.6)'}
                >
                  <ArrowUpwardIcon
                    fontSize="16px"
                    sx={{
                      color: 'rgba(0,0,0,0.6)',
                    }}
                  />
                  {` ${calculatePercentageDifference(
                    summaryData?.firstTimeVisits,
                    summaryData?.prevFirstTimeVisits,
                  ).toFixed(2)}% than last month`}
                </Typography>
              : null
            }
          />
          <MetricCard
            title="Repeat Visits"
            metric={summaryData?.returningVisits ? summaryData.returningVisits : 0}
            helpText="The total number of repeat visits this month."
            changeLabel={
              summaryData?.prevReturningVisits ? 
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                >
                  <ArrowDownwardIcon
                    fontSize="16px"
                    sx={{
                      color: 'rgba(0,0,0,0.6)',
                    }}
                  />

                  {` ${calculatePercentageDifference(
                    summaryData?.returningVisits,
                    summaryData?.prevReturningVisits,
                  ).toFixed(2)}% than last month`}
                </Typography>
              : null
            }
          />
          <MetricCard
            title="Average Dwell Time"
            metric={summaryData?.avgDwellTime ? summaryData.avgDwellTime.toString() + ` mins` : `0 mins`}
            helpText="The average amount of time spent at this location."
            changeLabel={
              summaryData?.prevAvgDwellTime ? 
                <Typography
                  sx={{
                    fontSize: '16px',
                    color: 'rgba(0,0,0,0.6)',
                  }}
                  color={theme.color.darkTurquoise}
                >
                  <ArrowUpwardIcon
                    fontSize="16px"
                    sx={{
                      color: 'rgba(0,0,0,0.6)',
                    }}
                  />
                  {` ${calculatePercentageDifference(
                    summaryData?.avgDwellTime,
                    summaryData?.prevAvgDwellTime,
                  ).toFixed(2)}% than last month`}
                </Typography>
              : null
            }
          />
        </Box>
        <Grid
          container
          mt="16px"
          rowSpacing="32px"
          sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <Grid item md={12}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <HelpTooltip
                    sx={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                    }}
                    tooltip={
                      'The number of returning vs new visitors for each day of the specified week.'
                    }
                  />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2">Traffic</Typography>
                    <DataCard>
                      {trafficLineGraphData && (
                        <ModularLineGraph
                          data={trafficLineGraphData}
                          options={trafficLineGraphOptions}
                          height="300"
                          width="400"
                        />
                      )}
                    </DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>

          <Grid item md={6}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <HelpTooltip
                    sx={{
                      position: 'absolute',
                      right: '10px',
                      top: '10px',
                    }}
                    tooltip={
                      'The number of returning vs new visitors for each day of the specified week.'
                    }
                  />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2">
                      First Time &amp; Returning Visitors
                      <br></br>
                      (Latest Week)
                    </Typography>
                    <DataCard>
                      {returningVsNewBarGraphData && (
                        <ModularBarGraph
                          data={returningVsNewBarGraphData}
                          options={returningVsNewBarGraphOptions}
                          height="300"
                          width="400"
                        />
                      )}
                    </DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>
          <Grid item md={6}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <HelpTooltip
                    sx={{
                      position: 'absolute',
                      right: '16px',
                      top: '16px',
                    }}
                    tooltip={
                      'The average dwell time per visit (in minutes) for each day of the specified week.'
                    }
                  />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2">
                      Average Dwell Time
                      <br></br>
                      (Latest Week)
                    </Typography>
                    <DataCard>
                      {dwellTimeBarGraphData && (
                        <ModularBarGraph
                          data={dwellTimeBarGraphData}
                          options={dwellTimeBarGraphOptions}
                          height="300"
                          width="400"
                        />
                      )}
                    </DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>
          <Grid item md={6}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <HelpTooltip
                    sx={{
                      position: 'absolute',
                      right: '16px',
                      top: '16px',
                    }}
                    tooltip={
                      'The average dwell time per visit (in minutes) for each day of the specified week.'
                    }
                  />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2" mb="16px">
                      Customer Frequency
                    </Typography>
                    <DataCard>
                      <ModularPieChart
                        colors={[
                          theme.color.blue,
                          theme.color.bgBlue,
                          theme.color.turquoise,
                          theme.color.darkTurquoise,
                          theme.color.pink,
                        ]}
                        data={[550, 100, 30, 20, 5]}
                        inputLabel={[
                          '1 visit',
                          '2 visits',
                          '3-5 Visits',
                          '5-10 Visits',
                          '10+ Visits',
                        ]}
                      />
                    </DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>
          <Grid item md={6}>
            {/* <CustomCard
              body={
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  borderRadius: '12px',
                  padding: '16px',
          
              }}>
              <HelpTooltip 
                sx={{
                  position: 'absolute',
                  right: '16px',
                  top: '16px',
                }}              
                tooltip={'The average dwell time per visit (in minutes) for each day of the specified week.'} />
                <Box style={{ textAlign: 'center', 
                  display: 'flex', 
                  flexGrow: 1,
                  flexDirection: 'column',
                  }}>
                <Typography variant="h2" mb="16px">
                  Average Hourly Traffic
                </Typography>
                <DataCard>
                  <ModularBarGraph
                      data={{
                          labels: hourlyLabels,
                          datasets: [{
                            label: 'Hourly Traffic',
                            data: hourlyData,
                            backgroundColor: theme.color.bgBlue,
                          }]
                        }}
                      options={hourlyOptions}
                      height='300'
                      width='400'
                    /> 
                </DataCard>  
              </Box>
              </Box>
          }/> */}
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'block',
            marginTop: '64px',
            backgroundImage: "url('images/header-fade@2x.png')",
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'center top',
            width: '100%',
          }}
        >
          <Grid container padding="32px" mb="32px" mt="16px" rowSpacing={'16px'}>
            <Grid item md={8}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Groups
                  fontSize="50px"
                  marginRight="8px"
                  sx={{
                    fontSize: '55px',
                    color: theme.color.white,
                    marginRight: '12px',
                  }}
                />
                <Typography
                  variant="h1"
                  sx={{
                    color: theme.color.white,
                    fontSize: '50px',
                  }}
                >
                  Persona Analysis
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: theme.color.white,
                  fontSize: '18px',
                  marginTop: '8px',
                }}
              >
                Gain insights by understanding how your customers behave. <br /> Personas give you a
                sense of what market segments visit you, and the differences between them.
              </Typography>
            </Grid>
            <Grid item md={4}>
              <PersonaSelector />
            </Grid>
            {/* <Grid item md={12}>
                  <CustomCard
                    backgroundColor='rgba(255, 255, 255, 0.9)'
                    body={
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        borderRadius: '12px',
                        padding: '16px',
                    }}>
                    <HelpTooltip 
                      sx={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                      }}              
                      tooltip={'The average dwell time per visit (in minutes) for each day of the specified week.'} />
                      <Box style={{ textAlign: 'center', 
                        display: 'flex', 
                        flexGrow: 1,
                        flexDirection: 'column',
                        }}>
                      <Typography variant="h2">
                        Total Visits (Personas)
                      </Typography>
                        {totalKeys && totalKeys.length > 0 ? (
                          <Box sx={{
                            width: '100%',
                            height: '300px',
                          }}>
                            <NivoLocationBar
                              data={personaTotalData}
                              keys={totalKeys}
                              yAxisLabel={"Visitors"}
                              xAxisLabel={"Time"}
                            />
                          </Box>
                        ) : (
                          <Typography variant="h3" sx={{
                            fontSize: '20px',
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '150px',
                          }}>
                            Select a persona above
                          </Typography>
                        )}
                    </Box>
                    </Box>
                }/>
          </Grid> */}
            <Grid item md={12} mt="16px">
              <CustomCard
                backgroundColor="rgba(255, 255, 255, 0.9)"
                body={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      borderRadius: '12px',
                      padding: '16px',
                    }}
                  >
                    <HelpTooltip
                      sx={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                      }}
                      tooltip={
                        'The average dwell time per visit (in minutes) for each day of the specified week.'
                      }
                    />
                    <Box
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="h2">Total Visits (Personas)</Typography>
                      {personaTotalData ? (
                        <Box
                          sx={{
                            width: '100%',
                            height: '300px',
                          }}
                        >
                          <NivoLocationBar
                            data={personaTotalData}
                            // keys={personaKeys}
                            yAxisLabel={'Visits'}
                            xAxisLabel={'Persona'}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: '20px',
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '150px',
                          }}
                        >
                          Select a persona above
                        </Typography>
                      )}
                    </Box>
                  </Box>
                }
              />
            </Grid>
            <Grid item md={12} mt="16px">
              <CustomCard
                backgroundColor="rgba(255, 255, 255, 0.9)"
                body={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      borderRadius: '12px',
                      padding: '16px',
                    }}
                  >
                    <HelpTooltip
                      sx={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                      }}
                      tooltip={
                        'The average dwell time per visit (in minutes) for each day of the specified week.'
                      }
                    />
                    <Box
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="h2">Average Dwell Time (Personas)</Typography>
                      {personaDwellTimeData ? (
                        <Box
                          sx={{
                            width: '100%',
                            height: '300px',
                          }}
                        >
                          <NivoLocationBar
                            data={personaDwellTimeData}
                            // keys={personaKeys}
                            yAxisLabel={'Dwell Time (mins)'}
                            xAxisLabel={'Persona'}
                          />
                        </Box>
                      ) : (
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: '20px',
                            color: 'rgb(0, 0, 0, 0.6)',
                            marginTop: '150px',
                          }}
                        >
                          Select a persona above
                        </Typography>
                      )}
                    </Box>
                  </Box>
                }
              />
            </Grid>
          </Grid>
        </Box>
        {/* <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', paddingBottom: '2rem' }}>
          <div style={{ width: '50%'}}>
            <Card style={graphContainerStyle}>
              <HelpTooltip tooltip={'Total Visit by persona'} />
              <CardContent style={{ textAlign: 'center' }}>
                <CardTitle>
                  Visits:
                </CardTitle>
                <DataCard>
                  <ModularPieChart
                    color={customColors}
                    data={filteredReturningVsNewPersonasData}
                    inputLabel={appState.selectedPersonas}
                    style={{ height: '80%', width: 'auto' }}
                  />
                </DataCard>  
              </CardContent>
            </Card>
          </div>
          <div style={{ width: '50%'}}>
            <Card style={graphContainerStyle}>
              <HelpTooltip tooltip={'Average Dwell Time (in seconds) by persona'} />
              <CardContent style={{ textAlign: 'center' }}>
                <CardTitle>
                  Average Dwell Time:
                </CardTitle>
                <DataCard>
                  <ModularPieChart
                    color={customColors}
                    data={filteredDwellTimePersonasData}
                    inputLabel={appState.selectedPersonas}
                    style={{ height: '80%', width: 'auto' }}
                  />
                </DataCard>  
              </CardContent>
            </Card>
          </div>
        </div> */}
      </div>
    </PageContainer>
  )
}

export default TrafficPage
