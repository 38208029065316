import $ from 'jquery'
import moment from 'moment'
import React, { Component, useEffect } from 'react'
import ReactMapGL, { Source, Layer, Marker } from 'react-map-gl'
import MapGL, { FlyToInterpolator } from 'react-map-gl'
import { AnalyticsLayer } from './map-style.js'

import { BASE_URL } from './utils/settings.js'
import { KM_TO_MILES } from 'src/utils/constants.ts'

import { styles } from './styles'

import { makeAuthenticatedRequest } from 'src/requests/api-requests.js'


// import "./DistanceTravelledMap.css";

const MAPBOX_TOKEN =
  'pk.eyJ1Ijoibnl0Z3JhcGhpY3MiLCJhIjoiY2o5YTlyb3ptMTB5bDMybXF2ODRjdThlYyJ9.cytRa2y0Qt3Nedj4eUX67w'
//'pk.eyJ1IjoicnNiYXVtYW5uIiwiYSI6ImNqd2YyNTU0MjE3cHgzem51b3dzNjExYTgifQ.HtsOFBVFj1E0H2ttW5tMLA';
//'pk.eyJ1IjoiZGFya21ldHJvaWQiLCJhIjoiY2s1d3dzOXFvMXp2NDNubjE5eWZpYnh4OSJ9.wdeQ2I7wAluU2SN5Jopvhw'; // Set your mapbox token here

//Different v
const MAP_TYPE_ANALYTICS = 'indvAnalytics'

const LAYER_LIST = [
  'boundary-fill-layer',
  'boundary-fill-layer-analytics',
  'boundary-line-layer',
  'clusters',
  'cluster-count',
  'unclustered-point',
]

export default class DistanceTravelledMap extends Component {
  constructor(props) {
    super(props)
    const current = new Date().getTime()

    this.state = {
      dataLookup: null,
      countyData: null,
      canadaProvinceData: null,
      hoveredFeature: null,

      // The specific category being looked up (e.g. Dwell time, Repeat Visits)
      analyticsCategory: { value: 'totalVisits', label: 'Percentage from' },

      // Which map should we show right now.
      mapType: { label: 'Detailed Analytics', value: MAP_TYPE_ANALYTICS },
      currentLayer: 'boundary-fill-layer',
      viewport: {
        latitude: this.props.location ? this.props.location.center.latitude : 47.674633,
        longitude: this.props.location ? this.props.location.center.longitude : 11.1039024,
        // latitude: 48.2356275612325,
        // longitude: 16.358661029971348,
        zoom: 7,
        bearing: 0,
        pitch: 0,
      },

      // Analytics variables
      regionValue: this.props.regionValue,
      datePicked: this.props.date,
      displayName: '',
      displayValue: '',
      whenValue: { value: 'day', label: 'Daily' },
      startDate: new Date(),
      endDate: new Date(),
      timeHeading: '',
      poiFilter: false,

      // Analytic Scrubber.
      breakDownData: '',
      showAll: true,
      startTime: current,
      endTime: current,
      scrubberStep: 1,
      scrubberMaxStep: 1,
      scrubberLabel: '',
      showScrubber: false,
    }

    this.mapRef = React.createRef()
    this.layerRef = React.createRef()
    this.initAnalytics = this.initAnalytics.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.goToViewport = this.goToViewport.bind(this)
    this.onLoad = this.onLoad.bind(this)
    this.onHover = this.onHover.bind(this)
    this.layerReset = this.layerReset.bind(this)
  }

  componentDidMount() {
    let date = new Date()
    let startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1)
    let startDatePrior = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 2)
    this.setState({ startDateDiffCurrent: startDate, startDateDiffPrior: startDatePrior })

    const map = this.mapRef.current.getMap()

    map.loadImage('assets/images/locations-map/search-marker.png', (error, image) => {
      if (error) {
        return
      }
      // Add the image to the map style.
      map.addImage('home-icon', image)
    })

    map.on('load', () => {
      // The map has fully loaded, now you can use map.getStyle() safely.
      // console.log(map.getStyle())
    })

    return () => {
      if (map.hasImage('home-icon')) {
        map.removeImage('home-icon')
      }
    }
  }

  _onViewportChange = viewport =>
    this.setState({
      viewport: { ...this.state.viewport, ...viewport },
    })

  // TODO: Load and unload on demand. This is alot to store, but maybe necessary
  // for fast performance?
  onLoad() {
    // console.log('Loading')
    const map = this.mapRef.current.getMap()
    map.addSource('admin-0', {
      type: 'vector',
      url: 'mapbox://mapbox.boundaries-adm0-v3',
    })
    map.addSource('admin-1', {
      type: 'vector',
      url: 'mapbox://mapbox.boundaries-adm1-v3',
    })
    map.addSource('admin-2', {
      type: 'vector',
      url: 'mapbox://mapbox.boundaries-adm2-v3',
    })
    map.addSource('admin-3', {
      type: 'vector',
      url: 'mapbox://mapbox.boundaries-adm3-v3',
    })
    map.addSource('postal-3', {
      type: 'vector',
      url: 'mapbox://mapbox.boundaries-pos3-v3',
    })
    map.addSource('postal-4', {
      type: 'vector',
      url: 'mapbox://mapbox.boundaries-pos4-v3',
    })
    map.addSource('stats-2', {
      type: 'vector',
      url: 'mapbox://mapbox.boundaries-sta2-v3',
    })

    try{
      this.initAnalytics(this.state.regionValue, 'regionValue')
    } catch(e) {
      console.error(e)
      return
    }
  }

  onHover(event) {
    const {
      features,
      srcEvent: { offsetX, offsetY },
    } = event
    var hoveredFeature
    const map = this.mapRef.current.getMap()
    if (map == undefined) {
      return
    }
    // console.error(map)
    if (map.getLayer('boundary-fill-layer')) {
      hoveredFeature = features && features.find(f => f.layer.id === 'boundary-fill-layer')
      if (hoveredFeature) {
        if ('mapbox_id' in hoveredFeature.properties) {
          // console.error(hoveredFeature)
          // console.error(this.state.dataLookup)
          if (hoveredFeature.properties.mapbox_id in this.state.dataLookup) {
            // console.error(hoveredFeature.properties.mapbox_id)
            // console.error(this.state.dataLookup)
            let val = this.state.dataLookup[hoveredFeature.properties.mapbox_id]?.['value']
            this.setState({
              displayName: this.state.dataLookup[hoveredFeature.properties.mapbox_id]?.['name'],
              displayValue: 'Percentage from: ' + val,
            })
          }
        }
        this.setState({ hoveredFeature, x: offsetX, y: offsetY })
      }
    } else if (map.getLayer('unclustered-point')) {
      hoveredFeature = features && features.find(f => f.layer.id === 'unclustered-point')
      if (hoveredFeature) {
        this.setState({
          displayName: hoveredFeature.properties?.name,
          locationAddress: hoveredFeature.properties.address,
        })
        this.setState({ hoveredFeature, x: offsetX, y: offsetY })
      }
    } else if (map.getLayer('boundary-fill-layer-analytics')) {
      hoveredFeature =
        features && features.find(f => f.layer.id === 'boundary-fill-layer-analytics')
      //   console.error(map.querySourceFeatures('boundary-fill-layer-analytics'))
      //   console.error(features.find(f => f.layer.id === 'boundary-fill-layer-analytics'))
      //   console.error(hoveredFeature)

      if (hoveredFeature) {
        if ('mapbox_id' in hoveredFeature.properties) {
          if (hoveredFeature.properties.mapbox_id in this.state.dataLookup) {
            let val = this.state.dataLookup[hoveredFeature.properties.mapbox_id]?.['value']
            var debugFeatures = map.queryRenderedFeatures({
              layers: ['boundary-fill-layer-analytics'],
            })
            // console.error(debugFeatures)
            // console.error(map.queryRenderedFeatures({ layers: ['boundary-fill-layer-analytics'] }))
            let features = map.queryRenderedFeatures({ layers: ['boundary-fill-layer-analytics'] })
            // hoveredFeature.setFeatureState({ value: val, type: 'overall' })
            this.setState({
              displayName: this.state.dataLookup[hoveredFeature.properties.mapbox_id]?.['name'],
              displayValue: 'Percentage from: ' + val + '%',
            })
          }

          // OLD v3 API
        } else {
          var debugFeatures = map.queryRenderedFeatures({
            layers: ['boundary-fill-layer-analytics'],
          })
          // console.error(debugFeatures)

          let val = this.state.dataLookup[hoveredFeature.id]?.['value']
          // console.error(hoveredFeature)
          let features = map.queryRenderedFeatures({ layers: ['boundary-fill-layer-analytics'] })
          // console.error(features)
          // hoveredFeature.setFeatureState({ value: val, type: 'overall' })

          this.setState({
            displayName: this.state.dataLookup[hoveredFeature.id]?.['name'],
            displayValue: 'Percentage from: ' + val + '%',
          })
        }
        this.setState({ hoveredFeature, x: offsetX, y: offsetY })
      }
    }
    // console.error(features)
    if (features.find(f => f.layer.id === 'home-icon')) {
      this.setState({
        displayName: this.props.location?.name,
        displayValue: this.props.location?.address?.lines[0],
      })
    }
    if (hoveredFeature === undefined) {
      this.setState({ displayName: '' })
    }
  }

  _renderTooltip() {
    const { hoveredFeature, x, y } = this.state
    let displayValue = this.state.displayValue

    // Dummy random values for now.

    if (hoveredFeature && this.state.mapType.value == MAP_TYPE_ANALYTICS) {
      if (
        this.state.analyticsCategory.value == 'totalVisits' ||
        this.state.analyticsCategory.value == 'repeatVisits' ||
        this.state.analyticsCategory.value == 'newVisits'
      ) {
        displayValue = this.state.displayValue.toLocaleString()
      }
    }

    return (
      <>
        {this.state.mapType.value == MAP_TYPE_ANALYTICS && this.state.displayName && (
          <div style={{ ...styles.tooltipMap, left: x, top: y }}>
            <div style={styles.toolTipHeader}>
              <strong>{this.state.displayName}</strong>
            </div>
            <div>{displayValue}</div>
          </div>
        )}
      </>
    )
  }

  handleChange(e, name) {
    //this.layerReset();
    this.setState({ [name]: e }, () => {
      try {
        this.initAnalytics(this.state.regionValue, 'regionValue')
      } catch(e) {
        console.error(e)
      }
    })
  }

  goToViewport(longitude, latitude, zoom) {
    this._onViewportChange({
      longitude,
      latitude,
      zoom: zoom,
      transitionInterpolator: new FlyToInterpolator({ speed: 2 }),
      transitionDuration: 'auto',
    })
  }

  layerReset() {
    const map = this.mapRef?.current?.getMap()
    if (map == undefined) {
      return
    }
    // console.log('Layer Reset')
    LAYER_LIST.map(function (layerName) {
      if (map.getLayer(layerName)) {
        map.removeLayer(layerName)
      }
    })
    this.setState({ currentLayer: null })
  }

  initAnalytics(e, name) {
    const map = this.mapRef.current.getMap()
    // console.log(map)
    // console.log(map.style)
    // if (map == undefined || map.style == undefined || typeof map.getStyle === "function" ){
    if (!map || !map.style) {
      console.log('Map or map style is undefined')
      this.layerReset()
      return
    }
    if (e.value.split('-').length < 2) {
      return
    }

    var regionCode = e.value.split('-')[1]
    var admZone = e.value.split('-')[0]
    var admSource = ''
    var sourceLayer = ''

    try {
      var layers = map.getStyle().layers
    } catch(e) {
      console.error(e)
      return
    }

    // console.log(admZone)

    admSource = 'postal-3'
    sourceLayer = 'boundaries_postal_3'

    if (admZone == 'adm0' || admZone == 'country') {
      admSource = 'admin-0'
      sourceLayer = 'boundaries_admin_0'
    } else if (admZone == 'adm1') {
      admSource = 'admin-1'
      sourceLayer = 'boundaries_admin_1'
    } else if (admZone == 'adm2') {
      admSource = 'admin-2'
      sourceLayer = 'boundaries_admin_2'
    } else if (admZone == 'adm3') {
      admSource = 'admin-3'
      sourceLayer = 'boundaries_admin_3'
    } else if (admZone == 'pos3') {
      admSource = 'postal-3'
      sourceLayer = 'boundaries_postal_3'
    } else if (admZone == 'pos4') {
      admSource = 'postal-4'
      sourceLayer = 'boundaries_postal_4'
    } else if (admZone == 'sta2') {
      admSource = 'stats-2'
      sourceLayer = 'boundaries_stats_2'
    }

    // admSource = 'stats-2'
    // sourceLayer = 'boundaries_stats_2'

    // Find the first symbol layer, so our polgons can appear behind it.
    var firstSymbolId
    for (var i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol') {
        firstSymbolId = layers[i].id
        break
      }
    }

    if (this.state.mapType.value == MAP_TYPE_ANALYTICS) {
      // Load the data and redraw the map.

      let latitude = 0
      let longitude = 0
      let locationId = ''
      let persona = 'overall'
      if (this.props.location) {
        latitude = this.props.location.center.latitude
        longitude = this.props.location.center.longitude
        locationId = this.props.location.id
      }

      if (this.props.personas) {
        persona = this.props.personas
      }
      // $.ajax({
      //   type : 'GET',
      //   url : BASE_URL + '/distanceTravelled',
      //   data: {
      //     'analyticsCategory': this.state.analyticsCategory.value,
      //     'regionCode': regionCode,
      //     'fileName': admZone + '.json',
      //     'whenValue': 'month',
      //     'month': this.props.date ? this.props.date.month : 11,
      //     'year': this.props.date ? this.props.date.year : 2022,
      //     'locationId': locationId,
      //     "latitude": this.props.latitude,
      //     "longitude": this.props.longitude,
      //     "persona": persona,
      //   }

      let url =
        BASE_URL +
        '/distanceTravelled?' +
        new URLSearchParams({
          analyticsCategory: this.state.analyticsCategory.value,
          regionCode: regionCode,
          fileName: admZone + '.json',
          whenValue: 'month',
          month: this.props.date ? this.props.date.month : 11,
          year: this.props.date ? this.props.date.year : 2022,
          locationId: locationId,
          latitude: latitude,
          longitude: longitude,
          persona: persona,
        })

      // console.log(url)

      if (latitude && longitude) {
        makeAuthenticatedRequest(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then(function (result) {
            return result.json()
          })
          .then(
            function (result) {
              // console.log('call returned')
              // console.log(result)
              let dataLookup = {}
              // this.props.setMean(result['mean'])
              // this.props.setMedian(result['median'])
              // this.props.setTopRegion(result['topRegion'])
              // this.props.setTopTenData(result['topTenData'])
              // City view supports an 'outside the GTA' chart.

              if (admZone == 'sta2') {
                // this.props.setTopTenOutsideGTAData(result['topTenOutsideGTAData'])
              }

              // this.props.setTopTenHeading(result['heading'])
              this.layerReset()

              this.setState({ dataLookup: dataLookup, timeHeading: result['heading'] }, () => {
                const personaColors = {
                  overall: ['rgba(222,235,247,1)', 'rgba(34,72, 137,1)'],
                  coffee_shop_lovers: ['rgba(74, 42, 10, .1)', 'rgba(26, 15, 4, 1)'],
                  discount_shoppers: ['rgba(92, 87, 3, .1)', 'rgba(92, 87, 3, 1)'],
                  grocery_shoppers: ['rgba(5, 77, 24, .1)', 'rgba(5, 77, 24, 1)'],
                  fast_food: ['rgba(92, 13, 3, .1)', 'rgba(92, 13, 3, 1)'],
                }

                // console.error(persona)
                let startCol = personaColors['overall'][0]
                let endCol = personaColors['overall'][1]

                if (persona in personaColors) {
                  startCol = personaColors[persona][0]
                  endCol = personaColors[persona][1]
                }
                let layer = AnalyticsLayer(startCol, endCol)

                // OLD v3 API
                //   console.error('what')
                //   console.error(e)
                //   console.error(regionCode)
                //   console.error(result)
                if (regionCode == 'CA' || regionCode == 'DE' || regionCode == 'US') {
                  map.addLayer(
                    {
                      ...layer,
                      source: admSource,
                      promoteId: 'mapbox_id',

                      id: 'boundary-fill-layer-analytics',
                      'source-layer': sourceLayer,
                    },
                    firstSymbolId,
                  )

                  console.log(result['overall'])

                  if (result['overall'].length > 0) {
                    result['overall'].forEach(function (row) {
                      dataLookup[row['REGION_ID']] = row
                      map.setFeatureState(
                        { source: admSource, sourceLayer: sourceLayer, id: row['REGION_ID'] },
                        { value: dataLookup[row['REGION_ID']]['value'], type: 'overall' },
                      )
                    })
                  }


                }
                //   console.error(layer)
                //   console.error(sourceLayer)
                // Add a layer with boundary lines
                // map.addLayer({
                //   id: 'boundary-line-layer',
                //   type: 'line',
                //   source: admSource,
                //   'source-layer': sourceLayer,
                //   paint: {
                //     'line-color': '#eee',
                //     'line-width': 1
                //   }
                // }, firstSymbolId);
                //
                // map.setFilter('boundary-line-layer', ['==', 'iso_3166_1', regionCode]);
                map.setFilter('boundary-fill-layer-analytics', ['==', 'iso_3166_1', regionCode])
                //   map.setFilter('boundary-fill-layer-analytics', ['==', ['feature-state', 'type'], 'overall']);
                // console.error("hi")
                // console.error(
                //   map.queryRenderedFeatures({ layers: ['boundary-fill-layer-analytics'] }),
                // )
                //   console.error(map)
                //   console.error(map.getStyle().sources);
              })
            }.bind(this),
          )
      }
    }
    this.setState({ [name]: e })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.date !== this.props.date || prevProps.location !== this.props.location) {
      // console.error("prop change flag")
      this.setState({ date: this.props.date, location: this.props.location, regionValue: this.props.regionValue }, () => {
        // this.initAnalytics(prevState.regionValue, 'regionValue')
        const map = this.mapRef.current.getMap()
        map.on('load', () => {
          // The map has fully loaded, now you can use map.getStyle() safely.
          // console.log(map.getStyle().layers)
          try {
            this.initAnalytics(this.state.regionValue, 'regionValue')
          } catch(e) {
            console.error(e)
            return
          }
        })
      })
      // this.layerReset();
      try {
        this.initAnalytics(prevState.regionValue, 'regionValue')
      } catch(e) {
        console.error(e)
        return
      }
    }
  }

  componentWillUnmount() {
    // Component cleanup when it is about to unmount
  }

  _handleScrubChange = step => {
    this.setState({ scrubberStep: step }, () => {
      try {
        this.initAnalytics(this.state.regionValue, 'regionValue')
      } catch(e) {
        console.error(e)
        return
      }
    })
  }

  render() {
    const { viewport, data } = this.state

    return (
      <div
        className="control-panel-wrapper"
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        {/* <ControlPanel
          containerComponent={this.props.containerComponent}
          settings={this.state}
          regionValue={this.state.regionValue}
          countryValue={this.state.countryValue}
          countryChange={this.countryChange}
          regionChange={this.initAnalytics}
          handleChange={this.handleChange}
          timeSelectUpdate={this.timeSelectUpdate}
          timeSelectDiffUpdate={this.timeSelectDiffUpdate}
          mapRef={this.mapRef}
          mapType={this.state.mapType}
          poiFilterCheck={this._handleChangePoiFilter}
          poiFilter={this.state.poiFilter}

          analyticsCategory={this.state.analyticsCategory}
          whenValue={this.state.whenValue}
          startDate={this.state.startDate}
          endDate={this.state.endDate}
          showAll={this.state.showAll}
          onChangeDay={this._handleScrubChange}
          onChangeAllDay={this._handleChangeAllDay}
          showScrubber={this.state.showScrubber}
          scrubberLabel={this.state.scrubberLabel}
          scrubberStep={this.state.scrubberStep}
          scrubberMaxStep={this.state.scrubberMaxStep}
          handlePDFDownload={this.props.handlePDFDownload}
       /> */}
        <ReactMapGL
          {...viewport}
          ref={this.mapRef}
          width="100%"
          height="100%"
          mapStyle="mapbox://styles/mapbox/light-v9"
          onViewportChange={this._onViewportChange}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          onHover={this.onHover}
          onLoad={this.onLoad}
          preserveDrawingBuffer={true}
        >
          <>
            <Source
              id="centerCoordinate"
              type="geojson"
              data={{
                type: 'FeatureCollection',
                features: [
                  {
                    type: 'Feature',
                    geometry: {
                      type: 'Point',
                      coordinates: [
                        this.props.location.center.longitude,
                        this.props.location.center.latitude,
                      ],
                    },
                  },
                ],
              }}
            >
              <Layer
                id="home-icon"
                type="symbol"
                layout={{
                  'icon-image': 'home-icon',
                  // 'icon-size': 0.5,
                  'icon-offset': [0, -15],
                  'icon-allow-overlap': true,
                  'icon-ignore-placement': true,
                }}
              />
            </Source>
          </>
          {this._renderTooltip()}
        </ReactMapGL>
      </div>
    )
  }
}
