
export const styles = {
    tooltipMap: {
        position: "absolute",
        margin: "8px",
        padding: "10px",
        background: "rgba(255, 255, 255, 0.95)",
        border: "solid 1px #888",
        color: "#000",
        maxWidth: "400px",
        fontSize: "12px",
        zIndex: 9,
        pointerEvents: "none",
        fontFamily: "Open Sans, sans-serif",
        fontWeight: 400,
    },

    toolTipHeader: {
        fontSize: "14px",
        marginBottom: "5px",
    },
    controlPanelWrapper: {
        position: "relative",
        width: "100%",
    },
}
