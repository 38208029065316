import React, {useState, useCallback} from 'react';
import {render} from 'react-dom';
// import Map, {StaticMap} from 'react-map-gl';
import DeckGL from '@deck.gl/react';
import {LinearInterpolator} from '@deck.gl/core';
import {CartoLayer, setDefaultCredentials, API_VERSIONS, MAP_TYPES} from '@deck.gl/carto';
import ControlPanel from 'src/components/economic-impact/control-panel';

const MAPBOX_TOKEN = 'pk.eyJ1IjoicnNiYXVtYW5uIiwiYSI6ImNqd2YyNTU0MjE3cHgzem51b3dzNjExYTgifQ.HtsOFBVFj1E0H2ttW5tMLA';
// 'pk.eyJ1IjoicnNiYXVtYW5uIiwiYSI6ImNqd2YyNTU0MjE3cHgzem51b3dzNjExYTgifQ.HtsOFBVFj1E0H2ttW5tMLA'; // Set your mapbox token here

const INITIAL_VIEW_STATE = {
  latitude: 40.7368521,
  longitude: -73.9936065,
  zoom: 11,
  pitch: 60,
  bearing: 0
};

// Colors for the breaks of the polygon layer
const POLYGON_COLORS = {
  COLOR_1: [40, 90, 156],
  COLOR_2: [86, 173, 244],
  COLOR_3: [71, 187, 166],
  COLOR_4: [101, 175, 152],
  COLOR_5: [179, 87, 154],
  COLOR_6: [99, 16, 77],
  OTHER: [39, 8, 71]
};

setDefaultCredentials({
  apiVersion: API_VERSIONS.V2,
  username: 'public',
  apiKey: 'SdBjYyF8NhILWw7kU0n2NQ'
});

const transitionInterpolator = new LinearInterpolator();

export default function AffiliateTrafficMap({
  mrliIndex = 'txn_amt',
  industry = 'ret',
  week = ['2020-01-01', '2020-01-05'],
  mapStyle = 'https://basemaps.cartocdn.com/gl/positron-gl-style/style.json'
}) {
  const [viewState, updateViewState] = useState(INITIAL_VIEW_STATE);

  const rotateCamera = useCallback(() => {
    updateViewState(v => ({
      ...v,
      bearing: v.bearing + 0.5,
      transitionDuration: 1000,
      transitionInterpolator,
      onTransitionEnd: rotateCamera
    }));
  }, []);

  const SQL = `SELECT the_geom_webmercator, avg(${mrliIndex}) as index
  FROM mrli_ny_jan WHERE industry ='${industry}' AND timeinstant BETWEEN '${week[0]}' AND '${week[1]}'
  GROUP BY the_geom_webmercator`;

  const layers = [
    new CartoLayer({
      id: 'carto-layer',
      type: MAP_TYPES.QUERY,
      data: SQL,
      getFillColor: object => {
        if (object.properties.index > 1000) {
          return POLYGON_COLORS.COLOR_1;
        } else if (object.properties.index > 500) {
          return POLYGON_COLORS.COLOR_2;
        } else if (object.properties.index > 300) {
          return POLYGON_COLORS.COLOR_3;
        } else if (object.properties.index > 100) {
          return POLYGON_COLORS.COLOR_4;
        } else if (object.properties.index > 50) {
          return POLYGON_COLORS.COLOR_5;
        } else if (object.properties.index > 25) {
          return POLYGON_COLORS.COLOR_6;
        }
        return POLYGON_COLORS.OTHER;
      },
      getLineColor: [0, 0, 0, 0],
      lineWidthMinPixels: 0,
      pickable: true,
      filled: true,
      extruded: true,
      wireframe: true,
      getElevation: f => {
        return f.properties.index ? f.properties.index : 0;
      },
      transitions: {
        getElevation: {
          duration: 1000,
          enter: () => [0]
        }
      }
    })
  ];

  const getTooltip = ({object}) => {
    if (!object) return false;
    const {index} = object.properties;
    return `Index: ${index.toFixed(2)}`;
  };

  return (
    <DeckGL
      controller={true}
      viewState={viewState}
      layers={layers}
      getTooltip={getTooltip}
      onLoad={rotateCamera}
      onViewStateChange={v => updateViewState(v.viewState)}
      width="100%"
      height="100%"

    >
      {/* <Map reuseMaps
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle={mapStyle}
       preventStyleDiffing={true}
      /> */}
      { false &&
        <ControlPanel />
      }
    </DeckGL>
  );
}
