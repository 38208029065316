import React, { useState, useLayoutEffect, useEffect, useRef, useContext } from 'react'
import AppContext from 'src/contexts-global/AppContext'
import PageContainer from 'src/components/common/page-container'
import FlexColumnContainer from 'src/components/common/flex-column-container'
import FlexRowContainer from 'src/components/common/flex-row-container'
import DashboardToolbar from 'src/components/dashboard-toolbar/DashboardToolbar'
import PdfButton from 'src/components/pdf-button/PdfButton'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import AffiliateBrandsMap from 'src/components/affiliate-brands/AffiliateBrandsMap'
import HelpTooltip from 'src/components/common/tooltip-help'
import DataCard from 'src/components/common/data-display-card'
import ModularBarGraph from 'src/components/graphs-charts/modularBar'
import { GetAffiliates } from 'src/requests/api-requests'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import MetricCard from 'src/components/metric-card'
import theme from 'src/themes'
import CustomCard from 'src/components/common/custom-card'
import { Groups } from '@mui/icons-material'
import PersonaSelector from 'src/components/common/persona-selector'

import { GetTrafficMetrics } from 'src/requests/api-requests'
import dayjs from 'dayjs'

var seedrandom = require('seedrandom')

const rngRange = (rng, min, max) => {
  var random = rng()
  var range = max - min
  var adjustment = range * random
  var result = min + adjustment
  return Math.round(result * 100) / 100
}

const AffiliateBrandsPage = () => {
  // Global context
  const {
    setIsLoading,
    setLocation,
    location,
    locationsList,
    setAllPersonas,
    setSelectedPersonas,
    sponsorsList,
    setSponsorsList,
    selectedSponsor,
    setSelectedSponsor,
  } = useContext(AppContext)

  // Page state (for date, location, and filtered report data)
  const [reportData, setReportData] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [conversionBarGraphData, setConversionBarGraphData] = useState(null)
  const [upliftBarGraphData, setUpliftBarGraphData] = useState(null)
  const [summaryData, setSummaryData] = useState(null)

  const [affiliates, setAffiliates] = useState([])

  useLayoutEffect(() => {
    setIsLoading(false)
    setReportData(null)
    setLocation(null)
    setAffiliates([])
    setSelectedPersonas([])
    setAllPersonas([])
    setStartDate(null)
    setEndDate(null)
  }, [])

  useEffect(() => {
    if (!locationsList) {
      return
    }

    if (locationsList[0]) {
      setLocation(locationsList[0])
    }
  }, [locationsList])

  // Temp default dates from Traffic
  useEffect(() => {
    if (location && localStorage.getItem('token')) {
      try {
        GetTrafficMetrics(startDate, endDate, location) // API request for metrics data
          .then(data => {
            if (data?.results) {
              for (const date in data.results) {
                if (data.results[date].overall) {
                  const total_count = data.results[date].overall.total_count || 0
                  const repeat_count = data.results[date].overall.repeat_count || 0
                  data.results[date].overall.first_time_count = total_count - repeat_count
                }
              }
            }
            return data
          })
          .then(data => {
            if (data?.results) {
              const dateStringList = Object.keys(data?.results)
              console.log(dateStringList)

              if (!startDate && !endDate) {
                const defaultStartDate = dayjs(dateStringList[0]).add(1, 'month')
                const defaultEndDate = dayjs(dateStringList[dateStringList.length - 1])
  
                setStartDate(defaultStartDate)
                setEndDate(defaultEndDate)
              }
            } else {
              console.error('data not found')
            }
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }, [location, endDate])

  useEffect(() => {
    if (location?.affiliate_groups[0]) {
      console.log(location)
      const affiliateGroups = location.affiliate_groups

      // Set options for selector
      setSponsorsList(affiliateGroups)

      // Set selector to the first sponsor in the list of the location'
      setSelectedSponsor(affiliateGroups[0])
    }
  }, [location, endDate])

  useEffect(() => {
    if (selectedSponsor) {
      const fetchData = async () => {
        try {
          // Get affiliates data
          console.log(location)
          await GetAffiliates(selectedSponsor.id, location.id).then(data => {
            if (location?.id === 'BMOField') {
              console.log('location.id = BMOField')
              data.affiliates.map((affiliate) => {
                affiliate.conversion = Math.random() * (0.025 - 0.001) + 0.001
                return affiliate
              })
            }
            setAffiliates(data.affiliates)
          })
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      fetchData()
    }
  }, [selectedSponsor])

  useEffect(() => {
    console.error(affiliates)
    const totalLocations = affiliates?.length

    // TODO: Replace dummyData

    const dummyData = {
      overallConversion: 203,
      overallUplift: 265,
      overallTrafficOverlap: 267,
      totalLocations: totalLocations,
      prevOverallConversion: 243,
      prevOverallUplift: 223,
      prevOverallTrafficOverlap: 255,
      prevTotalLocations: totalLocations, // How should this be different from current totalLocations?
    }
    setReportData(dummyData)

    // Set conversion graph labels and data
    if (!affiliates) {
      return
    }

    const topTenConversionAffiliates = affiliates
      .sort((a, b) => b.conversion - a.conversion)
      .slice(0, 10)
    console.log(topTenConversionAffiliates)

    const conversionBarGraphXLabels = topTenConversionAffiliates.map(affiliate => {
      return affiliate.name
    })
    const conversionBarGraphMetrics = topTenConversionAffiliates.map(affiliate => {
      return affiliate.conversion
    })

    setConversionBarGraphData({
      labels: conversionBarGraphXLabels,
      datasets: [
        {
          data: conversionBarGraphMetrics,
          backgroundColor: ['#1d4880'],
          borderColor: [],
          // borderWidth: 1
        },
      ],
    })

    // TODO: Replace hardcoded data for uplift
    const upliftBarGraphXLabels = conversionBarGraphXLabels
    const upliftBarGraphMetrics = topTenConversionAffiliates.map((affiliate) => {
      let rng = seedrandom(affiliate.id)
      let uplift = affiliate.conversion * 100 * rngRange(rng, 0.1, 0.25)
      uplift = uplift.toFixed(4)
      return uplift
    })

    setUpliftBarGraphData({
      labels: upliftBarGraphXLabels,
      datasets: [
        {
          data: upliftBarGraphMetrics,
          backgroundColor: ['#1d4880'],
          borderColor: [],
          // borderWidth: 1
        },
      ],
    })
  }, [affiliates])

  useEffect(() => {
    if (reportData) {
      // TODO: Update date picker to show default dates
      console.error('why')
      console.error(affiliates)
      // Set summary data
      setSummaryData({
        overallConversion: affiliates.length ? affiliates[0] : '',
        overallUplift: reportData.overallUplift,
        overallTrafficOverlap: reportData.overallTrafficOverlap,
        totalLocations: reportData.totalLocations ? reportData.totalLocations : null,
        prevOverallConversion: reportData.prevOverallConversion,
        prevOverallUplift: reportData.prevOverallUplift,
        prevOverallTrafficOverlap: reportData.prevOverallTrafficOverlap,
        prevTotalLocations: reportData.prevTotalLocations ? reportData.prevTotalLocations : null,
      })
    }
  }, [reportData, affiliates])

  const barGraphOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    // barThickness: 30,

    scales: {
      x: {
        ticks: {
          // For a category axis, the val is the index so the lookup via getLabelForValue is needed
          callback: function (n) {
            let label = this.getLabelForValue(n)
            if (label.length > 30) {
              return label.slice(0, 30) + '...'
            }
            return label
          },
        },
      },
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  // styles
  const valueTooltipContainerStyle = {
    minWidth: '200px',
    margin: '.5rem',
  }

  const mapContainerStyle = {
    minWidth: '200px',
    margin: '.5rem',
  }

  const graphContainerStyle = {
    minWidth: '200px',
    margin: '.5rem',
  }

  const sampleLocation = {
    id: 'RogersCentre',
    name: 'Rogers Centre',
    sensorData: null,
    address: {
      lines: ['1 Blue Jays Way'],
      city: 'Toronto',
      region: 'ON',
      country: 'CA',
      postalCode: 'M5V 1J1',
    },
    center: {
      latitude: 43.641935061396666,
      longitude: -79.38914370238228,
    },
    type: 'address',
  }
  const sampleRegion = { label: 'City', value: 'sta2-CA' }
  const sampleDate = { day: 1, month: 9, year: 2023 }

  // Calculate the percentage difference for prev month summary comparison
  const calculatePercentageDifference = (currentValue, prevValue) => {
    if (prevValue === 0) {
      return 0 // Handle division by zero
    }
    return ((currentValue - prevValue) / prevValue) * 100
  }

  // Date Handler
  const handleDateChange = newDateRange => {
    setStartDate(newDateRange[0])
    setEndDate(newDateRange[1])
  }

  return (
    <PageContainer>
      <div id="pdf-content" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box
          container
          sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
            marginTop: '32px',
            marginBottom: '32px',
          }}
        >
          <DashboardToolbar
            page="affiliate-brands"
            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        </Box>
        <Box
          container
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <MetricCard
            title="Overall Affiliate Conversion"
            // metric={'N/A'}
            metric={location?.id == 'BMOField' ? '1.26%' : 'N/A'}
            helpText="The percentage of visitors that visited a sponsor this month."
            changeLabel={
              <Typography
                sx={{
                  fontSize: '16px',
                }}
                color={theme.color.darkTurquoise}
              >
                <ArrowUpwardIcon
                  fontSize="16px"
                  sx={{
                    color: theme.color.darkTurquoise,
                  }}
                />
                {/* {` ${calculatePercentageDifference(
                  summaryData?.overallConversion,
                  summaryData?.prevOverallConversion,
                ).toFixed(2)}% than last month`} */}
                {'0.11% than last month'}
              </Typography>
            }
          />
          <MetricCard
            title="Overall Affiliate Uplift"
            // metric={'N/A'}
            metric={location?.id === 'BMOField' ? '0.32%' : 'N/A'}
            helpText="The percentage of visitors that visited a sponsor this month."
            changeLabel={
              <Typography
                sx={{
                  fontSize: '16px',
                }}
                color={theme.color.darkTurquoise}
              >
                <ArrowUpwardIcon
                  fontSize="16px"
                  sx={{
                    color: theme.color.darkTurquoise,
                  }}
                />
                {/* {` ${calculatePercentageDifference(
                  summaryData?.overallUplift,
                  summaryData?.prevOverallUplift,
                ).toFixed(2)}% than last month`} */}
                {'0.04% than last month'}
              </Typography>
            }
          />
          <MetricCard
            title="Overall Affiliate Locations"
            metric={affiliates ? affiliates.length : 'N/A'}
            helpText="The total number of affiliate locations."
            changeLabel={
              <Typography
                sx={{
                  fontSize: '16px',
                }}
                color={theme.color.darkTurquoise}
              >
                {/* <ArrowUpwardIcon
                  fontSize="16px"
                  sx={{
                    color: theme.color.darkTurquoise,
                  }}
                />
                {` ${calculatePercentageDifference(
                  summaryData?.firstTimeVisits,
                  summaryData?.prevFirstTimeVisits,
                ).toFixed(2)}% than last month`} */}
              </Typography>
            }
          />
        </Box>
        <Grid
          container
          mt="16px"
          rowSpacing={'32px'}
          sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <Grid item md={12}>
            {location && (
              <CustomCard
                height="500px"
                body={
                  <AffiliateBrandsMap
                    affiliates={affiliates}
                    center={{
                      latitude: location?.latitude,
                      longitude: location?.longitude,
                    }}
                  />
                }
              />
            )}
          </Grid>
          <Grid item md={6}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <HelpTooltip tooltip={'Top Conversion % by Affiliate'} />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2">Top Conversion % by Affiliate</Typography>
                    <DataCard>
                      {conversionBarGraphData && (
                        <ModularBarGraph
                          data={conversionBarGraphData}
                          options={barGraphOptions}
                          height="300"
                          width="400"
                        />
                      )}
                    </DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>
          <Grid item md={6}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <HelpTooltip tooltip={'Top Uplift % by Affiliate'} />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2">Top Uplift % by Affiliate</Typography>
                    <DataCard>
                      {upliftBarGraphData && (
                        <ModularBarGraph
                          data={upliftBarGraphData}
                          options={barGraphOptions}
                          height="300"
                          width="400"
                        />
                      )}
                    </DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>
        </Grid>

        <Box
          sx={{
            display: 'block',
            marginTop: '64px',
            backgroundImage: "url('images/header-fade@2x.png')",
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'center top',
            width: '100%',
          }}
        >
          {/* <Grid container padding="32px" mb="32px" mt="16px" rowSpacing={"16px"}>
                <Grid item md={8}>

                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    <Groups fontSize='50px' marginRight="8px" 
                      sx={{
                        fontSize: '55px',
                        color: theme.color.white,
                        marginRight: '12px',
                      }}
                    />
                    <Typography variant="h1" sx={{
                    color: theme.color.white,
                    fontSize: '50px'
                  }}>
                    Persona Analysis
                  </Typography>
                </Box>
                <Typography variant="body1" sx={{
                  color: theme.color.white,
                  fontSize: '18px',
                  marginTop: '8px',
                }}>
                  Gain insights by understanding how your customers behave. <br/> Personas give you a sense of what market segments visit you, and the differences between them.
                </Typography>
                </Grid>
                <Grid item md={4}>
                  <PersonaSelector />
                </Grid>
                <Grid item md={12}>
                  <CustomCard
                    backgroundColor='rgba(255, 255, 255, 0.9)'
                    body={
                      <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                        borderRadius: '12px',
                        padding: '16px',
                    }}>
                    <HelpTooltip 
                      sx={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                      }}              
                      tooltip={'The average dwell time per visit (in minutes) for each day of the specified week.'} />
                      <Box style={{ textAlign: 'center', 
                        display: 'flex', 
                        flexGrow: 1,
                        flexDirection: 'column',
                        }}>
                      <Typography variant="h2">
                        Placeholder
                      </Typography>
                      <DataCard>
                      </DataCard>  
                    </Box>
                    </Box>
                }/>
          </Grid>
              </Grid> */}
        </Box>
      </div>
    </PageContainer>
  )
}

export default AffiliateBrandsPage
