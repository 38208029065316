'use strict';
import $ from 'jquery';
import moment from 'moment';

import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ResponsiveBar } from '@nivo/bar'

import DatePicker from 'react-datepicker';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { Box, Grid, Typography } from '@mui/material';
import theme from 'src/themes';

export const NivoLocationBar = ({ data, yAxisLabel, xAxisLabel }) => (
    <ResponsiveBar
        data={data}
        keys={['data']}
        indexBy='persona'
        groupMode='grouped'
        margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
        padding={0.3}
        colors={[theme.color.bgBlue, theme.color.blue, theme.color.turquoise, theme.color.pink]}
        borderColor={{ from: 'color', modifiers: [ [ 'brighter', 1.6 ] ] }}
        borderWidth={1}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xAxisLabel,
            legendPosition: 'middle',
            legendOffset: 32,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisLabel,
            legendPosition: 'middle',
            legendOffset: -40
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 5,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
)
