import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface TypographyVariants {
    label: React.CSSProperties
    large: React.CSSProperties
    linkMedium: React.CSSProperties
    linkLarge: React.CSSProperties
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    label?: React.CSSProperties
    large?: React.CSSProperties
    linkMedium?: React.CSSProperties
    linkLarge?: React.CSSProperties
  }

  interface ButtonPropsVariantOverrides {
    textGrey?: React.CSSProperties
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    large: true
    label: true
    linkMedium: true
    linkLarge: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    textGrey: true
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    color: {
      bgBlue: React.CSSProperties['color']
      blue: React.CSSProperties['color']
      midBlue: React.CSSProperties['color']
      offWhite: React.CSSProperties['color']
      turquoise: React.CSSProperties['color']
      turquoiseA80: React.CSSProperties['color']
      darkTurquoise: React.CSSProperties['color']
      pink: React.CSSProperties['color']
      pinkA80: React.CSSProperties['color']

      white: React.CSSProperties['color']
      black: React.CSSProperties['color']
      grey1: React.CSSProperties['color']
      grey2: React.CSSProperties['color']
      grey3: React.CSSProperties['color']
      grey4: React.CSSProperties['color']
      grey5: React.CSSProperties['color']
      grey6: React.CSSProperties['color']
      grey7: React.CSSProperties['color']
      grey8: React.CSSProperties['color']
      grey9: React.CSSProperties['color']
      grey10: React.CSSProperties['color']
      border: React.CSSProperties['color']
      brand1: React.CSSProperties['color'] // ROYALE
      brand2: React.CSSProperties['color'] // MERMAID
      brand3: React.CSSProperties['color'] // FOGGY SKY
      brand4: React.CSSProperties['color'] // BLUE WINTER
      brand5: React.CSSProperties['color'] // SPEAR MINT
      error: React.CSSProperties['color'] // BERRY
      success1: React.CSSProperties['color'] // SPRINGLEAF
      success2: React.CSSProperties['color'] // LIME
      warning1: React.CSSProperties['color'] // SUNSET
      warning2: React.CSSProperties['color'] // LEMON
    }
    width: {
      buttonPrimarySm: React.CSSProperties['width']
      buttonPrimaryLg: React.CSSProperties['width']
      buttonPrimaryFull: React.CSSProperties['width']
    }
  }

  interface ThemeOptions {
    color: {
      bgBlue: React.CSSProperties['color']
      blue: React.CSSProperties['color']
      midBlue: React.CSSProperties['color']
      offWhite: React.CSSProperties['color']
      turquoise: React.CSSProperties['color']
      turquoiseA80: React.CSSProperties['color']
      darkTurquoise: React.CSSProperties['color']
      pink: React.CSSProperties['color']
      pinkA80: React.CSSProperties['color']
      white: React.CSSProperties['color']
      black: React.CSSProperties['color']
      grey1: React.CSSProperties['color']
      grey2: React.CSSProperties['color']
      grey3: React.CSSProperties['color']
      grey4: React.CSSProperties['color']
      grey5: React.CSSProperties['color']
      grey6: React.CSSProperties['color']
      grey7: React.CSSProperties['color']
      grey8: React.CSSProperties['color']
      grey9: React.CSSProperties['color']
      grey10: React.CSSProperties['color']
      border: React.CSSProperties['color']
      brand1: React.CSSProperties['color'] // ROYALE
      brand2: React.CSSProperties['color'] // MERMAID
      brand3: React.CSSProperties['color'] // FOGGY SKY
      brand4: React.CSSProperties['color'] // BLUE WINTER
      brand5: React.CSSProperties['color'] // SPEAR MINT
      error: React.CSSProperties['color'] // BERRY
      success1: React.CSSProperties['color'] // SPRINGLEAF
      success2: React.CSSProperties['color'] // LIME
      warning1: React.CSSProperties['color'] // SUNSET
      warning2: React.CSSProperties['color'] // LEMON
    }
    width: {
      buttonPrimarySm: React.CSSProperties['width']
      buttonPrimaryLg: React.CSSProperties['width']
      buttonPrimaryFull: React.CSSProperties['width']
    }
  }
}

const gliColors = {
  bgBlue: 'rgb(16,57,101)',
  blue: 'rgb(86, 173, 244)',
  midBlue: 'rgb(53,83,118)',
  darkBlue: '#010b1c',
  turquoise: '#2cbba5',
  turquoiseA80: 'rgba(44, 187, 165, 0.8)',
  darkTurquoise: 'rgb(5 97 83)',
  pink: '#b3579a',
  pinkA80: 'rgba(179, 87, 154, 0.8)',
  offWhite: '#fcfcfc',
  white: '#FFFFFF',
  black: '#000000',
  grey1: '#f9fafb',
  grey2: '#f3f4f6',
  grey3: '#e5e7eb',
  grey4: '#d1d5db',
  grey5: '#9ca3af',
  grey6: '#6b7280',
  grey7: '#4b5563',
  grey8: '#374151',
  grey9: '#1f2937',
  grey10: '#111827',
  border: '#F3F4F6',
  brand1: '#005151', // FIG GREEN
  brand2: '#CFFAFE', // MERMAID
  brand3: '#FAFAFA', // FOGGY SKY
  brand4: '#F5FEFF', // BLUE WINTER
  brand5: '#ECFBF7', // SPEAR MINT
  error: '#B50000', // BERRY
  success1: '#84CC16', // SPRINGLEAF
  success2: '#ECFCCB', // LIME
  warning1: '#F59E0B', // SUNSET
  warning2: '#FEF3C7  ', // LEMON
}

const theme = createTheme({
  color: {
    ...gliColors,
  },
  width: {
    buttonPrimarySm: '260px', // '358px', // TO UNDO
    buttonPrimaryLg: '358px',
    buttonPrimaryFull: '100%',
  },
  palette: {
    background: {
      default: gliColors.grey3
    },
    primary: {
      main: gliColors.midBlue,
    },
    error: {
      main: gliColors.error,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
    body1: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 'normal',
      fontFamily: "'Poppins', sans-serif",
      color: gliColors.grey6,
    },
    body2: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: 'normal',
      fontFamily: "'Poppins', sans-serif",
      color: gliColors.grey6,
    },
    subtitle1: {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      color: gliColors.grey6,
    },
    h1: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '32px',
      lineHeight: 'normal',
      fontFamily: "'Figtree', sans-serif",
      color: gliColors.grey9,
    },
    h2: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '22px',
      lineHeight: 'normal',
      fontFamily: "'Figtree', sans-serif",
      color: 'rgba(0, 0, 0, 0.8)',
    },
    h3: {
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '24px',
      lineHeight: 'normal',
      fontFamily: "'Figtree', sans-serif",
      color: gliColors.grey9,
    },
    h5: {
      fontStyle: 'normal',
      fontFamily: "'Poppins', sans-serif",
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
      color: gliColors.darkBlue,
 
    },
    label: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '20px',
      color: gliColors.grey9,
      // for some reason this is needed. Even though it should be inherited by root.
      fontFamily: "'Poppins', sans-serif",
    },
    linkMedium: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '20px',
      color: gliColors.brand1,
    },
    linkLarge: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '22px',
      color: gliColors.brand1,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-display: swap;
          font-weight: 400;
        }
      `,
    },
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
        disableRipple: true, // No more ripple, on the whole application
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'inherit',
          fontFamily: "'Figtree', sans-serif",
          minWidth: 'auto',
          fontWeight: 400,
          fontSize: '16px',
          borderRadius: '14px !important',
          border: 'none',
          padding: '18px !important',
          color: gliColors.white,
          backgroundColor: gliColors.turquoise,
          '&:hover': {
            backgroundColor: `${gliColors.turquoiseA80} !important`,
          },
        },
      },
      variants: [
        {
          props: { variant: 'outlined' },
          style: {
            border: `1.5px solid ${gliColors.brand1}`,
            '&:hover': {
              backgroundColor: gliColors.brand5,
            },
            '&.Mui-disabled': {
              color: gliColors.grey6,
              border: `1px solid ${gliColors.grey4}`,
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            borderRadius: '3px',
            padding: '4px',
            width: 'auto',
            height: '32px',
            lineHeight: '22px',
            '&:hover': {
              borderRadius: '3px',
              backgroundColor: gliColors.brand5,
            },
          },
        },
        {
          props: { variant: 'textGrey' },
          style: {
            color: gliColors.grey6,
            borderRadius: '3px',
            padding: '4px',
            width: 'auto',
            height: '32px',
            lineHeight: '22px',
            '&:hover': {
              borderRadius: '3px',
              backgroundColor: gliColors.grey2,
            },
          },
        },
      ],
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          fontStyle: 'normal',
          textDecoration: 'none',
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '20px',
          color: gliColors.brand1,
        },
      },
      variants: [
        {
          props: { variant: 'large' },
          style: {
            fontStyle: 'normal',
            textDecoration: 'none',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '20px',
            color: gliColors.brand1,
          },
        },
      ],
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
  },
})

export default theme
