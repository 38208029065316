import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import GoogleMap from 'google-maps-react-markers'
import { Box, Typography } from '@mui/material'

// Adjust wrapper as necessary. Could use JSX instead of styled components if preferred.
const Wrapper = styled.main`
  width: 100%;
  height: 100%;
`
const HomeMarker = ({ text }) => {
  return (
    <Box>
      <img src="images/locations-map/search-marker.png" />
    </Box>
  )
}

const HitMarker = ({ hit }) => {
  return (
    <Box>
      <img src={hit.logo_url ? hit.logo_url : hit.icon} />
    </Box>
  )
}

const Map = ({ affinityHits, location, children, ...props }) => {
  console.error('test')
  console.error(location)

  return (
    <>
      <Box
        sx={{
          height: '400px',
        }}
      >
        <GoogleMap
          apiKey={process.env.REACT_APP_GMAPS_API_KEY}
          height={'400px'}
          mapMinHeight={'400px'}
          {...props}
        >
          <HomeMarker text={location?.name} lat={location?.latitude} lng={location?.longitude} />
          {affinityHits?.results?.hits.map(hit => (
            <HitMarker
              key={`hit-${hit.index}`}
              hit={hit}
              lat={hit.geometry.location.lat}
              lng={hit.geometry.location.lng}
            />
          ))}
          {children}
        </GoogleMap>
      </Box>
    </>
  )
}

Map.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
}

Map.defaultProps = {
  children: null,
}

export default Map
