// import rangersLogo from 'assets/images/locations-map/game-stats/rangers.png';
// import astrosLogo from 'assets/images/locations-map/game-stats/astros.png';
// import athleticsLogo from 'assets/images/locations-map/game-stats/athletics.png';
// import marinersLogo from 'assets/images/locations-map/game-stats/mariners.png';
// import newYorkLogo from 'assets/images/locations-map/game-stats/newYork.png';
// import redsLogo from 'assets/images/locations-map/game-stats/reds.png';
// import redsoxLogo from 'assets/images/locations-map/game-stats/redsox.png';
// import whiteSoxLogo from 'src/assets/images/locations-map/game-stats/whiteSox.png';
import Apr8GameData from 'src/assets/stats/locations-map/4/8.json'
import Apr9GameData from 'src/assets/stats/locations-map/4/9.json'
import Apr10GameData from 'src/assets/stats/locations-map/4/10.json'
import Apr15GameData from 'src/assets/stats/locations-map/4/15.json'
import Apr16GameData from 'src/assets/stats/locations-map/4/16.json'
import Apr17GameData from 'src/assets/stats/locations-map/4/17.json'
import Apr25GameData from 'src/assets/stats/locations-map/4/25.json'
import Apr26GameData from 'src/assets/stats/locations-map/4/26.json'
import Apr27GameData from 'src/assets/stats/locations-map/4/27.json'
import Apr28GameData from 'src/assets/stats/locations-map/4/28.json'
import Apr29GameData from 'src/assets/stats/locations-map/4/29.json'
import Apr30GameData from 'src/assets/stats/locations-map/4/30.json'
import May1GameData from 'src/assets/stats/locations-map/5/1.json'
import May2GameData from 'src/assets/stats/locations-map/5/2.json'
import May3GameData from 'src/assets/stats/locations-map/5/3.json'
import May4GameData from 'src/assets/stats/locations-map/5/4.json'
import May16GameData from 'src/assets/stats/locations-map/5/16.json'
import May17GameData from 'src/assets/stats/locations-map/5/17.json'
import May18GameData from 'src/assets/stats/locations-map/5/18.json'
import May20GameData from 'src/assets/stats/locations-map/5/20.json'
import May21GameData from 'src/assets/stats/locations-map/5/21.json'
import May22GameData from 'src/assets/stats/locations-map/5/22.json'
import May31GameData from 'src/assets/stats/locations-map/5/31.json'
import { constants } from 'src/utils'

let rangersLogo = 'assets/images/locations-map/game-stats/rangers.png'
let astrosLogo = 'assets/images/locations-map/game-stats/rangers.png'
let athleticsLogo = 'assets/images/locations-map/game-stats/rangers.png'
let marinersLogo = 'assets/images/locations-map/game-stats/rangers.png'
let newYorkLogo = 'assets/images/locations-map/game-stats/rangers.png'
let redsLogo = 'assets/images/locations-map/game-stats/rangers.png'
let redsoxLogo = 'assets/images/locations-map/game-stats/rangers.png'
let whiteSoxLogo = 'assets/images/locations-map/game-stats/rangers.png'

// export const BASE_URL = 'https://gli-django-api-darkmetroid.vercel.app/api';
export const BASE_URL = `${constants.API_URL}v1`
// 'http://localhost:8000/api';

//'https://gli-django-api-darkmetroid.vercel.app/api';

// export const BASE_URL = 'https://locations-dashboard-app.vercel.app';

export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoibGVld2FuZ2RldiIsImEiOiJja2tnbDU2c2gwMHNvMndsdDF1d2pxNTQ2In0.zKeo06DtCh6fLifrbCZCFA'

export const DEFAULT_VIEWPORT = {
  longitude: -79.3966596,
  latitude: 43.6565291,
  zoom: 14,
}

export const google = window.google

export const DEFAULT_DATE = new Date('2022-04-01')

// mapbox gl poi-label layer category_en
export const categories = [
  'All Categories',
  'Bank',
  'Bar',
  'Cafe',
  'Gas Station',
  'Hospital Grounds',
  'Hotel',
  'Mall',
  'Marketplace',
  'Restaurant',
  'Shop',
  'Supermarket',
]

export const category_distances = [5, 10, 20, 50, 100, 200, 500]

// Scales up the device total / android and iphone counts
export const countMultiplier = 503

// A mapping of jays home games for 2022. Months as keys, days as a value list.
export const jays_home_games = {
  '04': [8, 9, 10, 15, 16, 17, 25, 26, 27, 28, 29, 30],
  '05': [1, 2, 3, 4, 16, 17, 18, 20, 21, 22, 31],
}

export const jaysTeamId = 238

// Flags jays home games for april and may. Returns false otherwise.
export const isHomeGame = function (day) {
  return (
    (day.getMonth() == 3 && jays_home_games['04'].includes(day.getDate())) ||
    (day.getMonth() == 4 && jays_home_games['05'].includes(day.getDate()))
  )
}

export const getGameData = function (day) {
  let monthStr = (day.getMonth() + 1).toString()
  let dayStr = day.getDate().toString()
  if (isHomeGame(day)) {
    return team_info[monthStr][dayStr]['gameData']
  }
  return null
}

export const team_info = {
  4: {
    8: {
      eventId: '2412992',
      teamId: 237,
      gameData: Apr8GameData,
      img: rangersLogo,
      gameChartData: [
        { x: '19:07pm', y: 91 },
        { x: '19:17pm', y: 97 },
        { x: '19:27pm', y: 83 },
        { x: '19:37pm', y: 98 },
        { x: '19:47pm', y: 85 },
        { x: '19:57pm', y: 85 },
        { x: '20:07pm', y: 72 },
        { x: '20:17pm', y: 77 },
        { x: '20:27pm', y: 58 },
        { x: '20:37pm', y: 78 },
        { x: '20:47pm', y: 65 },
        { x: '20:57pm', y: 72 },
        { x: '21:07pm', y: 63 },
        { x: '21:17pm', y: 86 },
        { x: '21:27pm', y: 77 },
        { x: '21:37pm', y: 73 },
        { x: '21:47pm', y: 64 },
        { x: '21:57pm', y: 80 },
        { x: '22:07pm', y: 72 },
        { x: '22:17pm', y: 89 },
        { x: '22:27pm', y: 83 },
        { x: '22:37pm', y: 99 },
        { x: '22:47pm', y: 86 },
        { x: '22:57pm', y: 94 },
        { x: '23:07pm', y: 78 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
        '22:17pm',
        '22:37pm',
        '22:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 3,
        runs5: 4,
        runs6: 1,
        runs7: 1,
        runs8: 1,
        runs9: 'x',
        runsTotal: 10,
        hitsTotal: 9,
        errorsTotal: 1,
      },
      awayLine: {
        name: 'Texas Rangers',
        runs1: 4,
        runs2: 2,
        runs3: 0,
        runs4: 1,
        runs5: 0,
        runs6: 0,
        runs7: 1,
        runs8: 0,
        runs9: 0,
        runsTotal: 8,
        hitsTotal: 8,
        errorsTotal: 1,
      },
    },
    9: {
      eventId: '2412998',
      teamId: 237,
      gameData: Apr9GameData,
      img: rangersLogo,
      gameChartData: [
        { x: '15:07pm', y: 81 },
        { x: '15:17pm', y: 99 },
        { x: '15:27pm', y: 86 },
        { x: '15:37pm', y: 80 },
        { x: '15:47pm', y: 81 },
        { x: '15:57pm', y: 84 },
        { x: '16:07pm', y: 65 },
        { x: '16:17pm', y: 85 },
        { x: '16:27pm', y: 80 },
        { x: '16:37pm', y: 83 },
        { x: '16:47pm', y: 67 },
        { x: '16:57pm', y: 89 },
        { x: '17:07pm', y: 66 },
        { x: '17:17pm', y: 94 },
        { x: '17:27pm', y: 72 },
        { x: '17:37pm', y: 80 },
        { x: '17:47pm', y: 80 },
      ],
      gameChartXLabels: [
        '15:17pm',
        '15:37pm',
        '15:57pm',
        '16:17pm',
        '16:37pm',
        '16:57pm',
        '17:17pm',
        '17:37pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 2,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 1,
        runs6: 1,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 4,
        hitsTotal: 9,
        errorsTotal: 1,
      },
      awayLine: {
        name: 'Texas Rangers',
        runs1: 0,
        runs2: 0,
        runs3: 3,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 3,
        hitsTotal: 9,
        errorsTotal: 0,
      },
    },
    10: {
      eventId: '2413004',
      teamId: 237,
      gameData: Apr10GameData,
      img: rangersLogo,
      gameChartData: [
        { x: '13:37pm', y: 77 },
        { x: '13:47pm', y: 75 },
        { x: '13:57pm', y: 87 },
        { x: '14:07pm', y: 70 },
        { x: '14:17pm', y: 81 },
        { x: '14:27pm', y: 66 },
        { x: '14:37pm', y: 83 },
        { x: '14:47pm', y: 62 },
        { x: '14:57pm', y: 77 },
        { x: '15:07pm', y: 61 },
        { x: '15:17pm', y: 82 },
        { x: '15:27pm', y: 69 },
        { x: '15:37pm', y: 81 },
        { x: '15:47pm', y: 63 },
        { x: '15:57pm', y: 79 },
        { x: '16:07pm', y: 60 },
        { x: '16:17pm', y: 76 },
        { x: '16:27pm', y: 64 },
        { x: '16:37pm', y: 99 },
        { x: '16:47pm', y: 90 },
        { x: '16:57pm', y: 87 },
        { x: '17:07pm', y: 80 },
      ],
      gameChartXLabels: [
        '13:47pm',
        '14:07pm',
        '14:27pm',
        '14:47pm',
        '15:07pm',
        '15:27pm',
        '15:47pm',
        '16:07pm',
        '16:27pm',
        '16:47pm',
        '17:07pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 4,
        runs2: 1,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 6,
        hitsTotal: 10,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Texas Rangers',
        runs1: 0,
        runs2: 1,
        runs3: 0,
        runs4: 6,
        runs5: 2,
        runs6: 0,
        runs7: 3,
        runs8: 0,
        runs9: 0,
        runsTotal: 12,
        hitsTotal: 11,
        errorsTotal: 0,
      },
    },
    15: {
      eventId: '2413058',
      teamId: 237,
      gameData: Apr15GameData,
      img: athleticsLogo,
      gameChartData: [
        { x: '19:07pm', y: 87 },
        { x: '19:17pm', y: 89 },
        { x: '19:27pm', y: 70 },
        { x: '19:37pm', y: 67 },
        { x: '19:47pm', y: 62 },
        { x: '19:57pm', y: 78 },
        { x: '20:07pm', y: 63 },
        { x: '20:17pm', y: 78 },
        { x: '20:27pm', y: 60 },
        { x: '20:37pm', y: 76 },
        { x: '20:47pm', y: 59 },
        { x: '20:57pm', y: 77 },
        { x: '21:07pm', y: 61 },
        { x: '21:17pm', y: 80 },
        { x: '21:27pm', y: 89 },
        { x: '21:37pm', y: 83 },
        { x: '21:47pm', y: 79 },
        { x: '21:57pm', y: 99 },
        { x: '22:07pm', y: 91 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 1,
        runs2: 1,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 1,
        runs7: 1,
        runs8: 0,
        runs9: 'x',
        runsTotal: 4,
        hitsTotal: 11,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Oakland Athletics',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 1,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 6,
        errorsTotal: 0,
      },
    },
    16: {
      eventId: '2413064',
      teamId: 237,
      gameData: Apr16GameData,
      img: athleticsLogo,
      gameChartData: [
        { x: '15:07pm', y: 77 },
        { x: '15:17pm', y: 80 },
        { x: '15:27pm', y: 88 },
        { x: '15:37pm', y: 88 },
        { x: '15:47pm', y: 83 },
        { x: '15:57pm', y: 99 },
        { x: '16:07pm', y: 80 },
        { x: '16:17pm', y: 92 },
        { x: '16:27pm', y: 66 },
        { x: '16:37pm', y: 92 },
        { x: '16:47pm', y: 75 },
        { x: '16:57pm', y: 85 },
        { x: '17:07pm', y: 85 },
        { x: '17:17pm', y: 83 },
        { x: '17:27pm', y: 68 },
        { x: '17:37pm', y: 68 },
        { x: '17:47pm', y: 80 },
        { x: '17:57pm', y: 90 },
        { x: '18:07pm', y: 77 },
        { x: '18:17pm', y: 89 },
      ],
      gameChartXLabels: [
        '15:17pm',
        '15:37pm',
        '15:57pm',
        '16:17pm',
        '16:37pm',
        '16:57pm',
        '17:17pm',
        '17:37pm',
        '17:57pm',
        '18:17pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 1,
        runs2: 0,
        runs3: 0,
        runs4: 1,
        runs5: 0,
        runs6: 3,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 5,
        hitsTotal: 10,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Oakland Athletics',
        runs1: 0,
        runs2: 3,
        runs3: 2,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 2,
        runsTotal: 7,
        hitsTotal: 9,
        errorsTotal: 0,
      },
    },
    17: {
      eventId: '2413071',
      teamId: 237,
      gameData: Apr17GameData,
      img: athleticsLogo,
      gameChartData: [
        { x: '13:37pm', y: 86 },
        { x: '13:47pm', y: 88 },
        { x: '13:57pm', y: 91 },
        { x: '14:07pm', y: 69 },
        { x: '14:17pm', y: 85 },
        { x: '14:27pm', y: 71 },
        { x: '14:37pm', y: 85 },
        { x: '14:47pm', y: 84 },
        { x: '14:57pm', y: 99 },
        { x: '15:07pm', y: 80 },
        { x: '15:17pm', y: 92 },
        { x: '15:27pm', y: 77 },
        { x: '15:37pm', y: 98 },
        { x: '15:47pm', y: 75 },
        { x: '15:57pm', y: 81 },
        { x: '16:07pm', y: 92 },
        { x: '16:17pm', y: 88 },
        { x: '16:27pm', y: 87 },
        { x: '16:37pm', y: 81 },
        { x: '16:47pm', y: 80 },
      ],
      gameChartXLabels: [
        '13:47pm',
        '14:07pm',
        '14:27pm',
        '14:47pm',
        '15:07pm',
        '15:27pm',
        '15:47pm',
        '16:07pm',
        '16:27pm',
        '16:47pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 1,
        runs2: 1,
        runs3: 1,
        runs4: 0,
        runs5: 1,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 4,
        hitsTotal: 7,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Oakland Athletics',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 2,
        runs6: 0,
        runs7: 0,
        runs8: 1,
        runs9: 0,
        runsTotal: 3,
        hitsTotal: 6,
        errorsTotal: 2,
      },
    },
    25: {
      eventId: '2413079',
      teamId: 226,
      gameData: Apr25GameData,
      img: redsoxLogo,
      gameChartData: [
        { x: '19:07pm', y: 95 },
        { x: '19:17pm', y: 92 },
        { x: '19:27pm', y: 99 },
        { x: '19:37pm', y: 91 },
        { x: '19:47pm', y: 92 },
        { x: '19:57pm', y: 89 },
        { x: '20:07pm', y: 81 },
        { x: '20:17pm', y: 89 },
        { x: '20:27pm', y: 85 },
        { x: '20:37pm', y: 90 },
        { x: '20:47pm', y: 76 },
        { x: '20:57pm', y: 94 },
        { x: '21:07pm', y: 64 },
        { x: '21:17pm', y: 87 },
        { x: '21:27pm', y: 92 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 1,
        runs6: 0,
        runs7: 1,
        runs8: 4,
        runs9: 'x',
        runsTotal: 6,
        hitsTotal: 10,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Boston Red Sox',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 1,
        runs7: 0,
        runs8: 2,
        runs9: 0,
        runsTotal: 2,
        hitsTotal: 7,
        errorsTotal: 0,
      },
    },
    26: {
      eventId: '2413082',
      teamId: 226,
      gameData: Apr26GameData,
      img: redsoxLogo,
      gameChartData: [
        { x: '19:07pm', y: 87 },
        { x: '19:17pm', y: 80 },
        { x: '19:27pm', y: 85 },
        { x: '19:37pm', y: 87 },
        { x: '19:47pm', y: 62 },
        { x: '19:57pm', y: 72 },
        { x: '20:07pm', y: 58 },
        { x: '20:17pm', y: 70 },
        { x: '20:27pm', y: 54 },
        { x: '20:37pm', y: 87 },
        { x: '20:47pm', y: 57 },
        { x: '20:57pm', y: 70 },
        { x: '21:07pm', y: 65 },
        { x: '21:17pm', y: 69 },
        { x: '21:27pm', y: 73 },
        { x: '21:37pm', y: 99 },
        { x: '21:47pm', y: 91 },
        { x: '21:57pm', y: 88 },
        { x: '22:07pm', y: 84 },
        { x: '22:17pm', y: 81 },
        { x: '22:27pm', y: 75 },
        { x: '22:37pm', y: 71 },
        { x: '22:47pm', y: 69 },
        { x: '22:57pm', y: 94 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
        '22:17pm',
        '22:37pm',
        '22:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 1,
        runs4: 1,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 3,
        runs10: 0,
        runsTotal: 5,
        hitsTotal: 9,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Boston Red Sox',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 1,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 4,
        runs9: 0,
        runs10: 0,
        runsTotal: 5,
        hitsTotal: 9,
        errorsTotal: 0,
      },
    },
    27: {
      eventId: '2413086',
      teamId: 226,
      gameData: Apr27GameData,
      img: redsoxLogo,
      gameChartData: [
        { x: '19:07pm', y: 88 },
        { x: '19:17pm', y: 90 },
        { x: '19:27pm', y: 94 },
        { x: '19:37pm', y: 97 },
        { x: '19:47pm', y: 82 },
        { x: '19:57pm', y: 90 },
        { x: '20:07pm', y: 70 },
        { x: '20:17pm', y: 92 },
        { x: '20:27pm', y: 86 },
        { x: '20:37pm', y: 83 },
        { x: '20:47pm', y: 80 },
        { x: '20:57pm', y: 87 },
        { x: '21:07pm', y: 81 },
        { x: '21:17pm', y: 99 },
        { x: '21:27pm', y: 74 },
        { x: '21:37pm', y: 83 },
        { x: '21:47pm', y: 90 },
        { x: '21:57pm', y: 93 },
        { x: '22:07pm', y: 87 },
        { x: '22:17pm', y: 97 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
        '22:17pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 5,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Boston Red Sox',
        runs1: 1,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 2,
        runs7: 0,
        runs8: 2,
        runs9: 2,
        runsTotal: 7,
        hitsTotal: 13,
        errorsTotal: 0,
      },
    },
    28: {
      eventId: '2413092',
      teamId: 226,
      gameData: Apr28GameData,
      img: redsoxLogo,
      gameChartData: [
        { x: '15:07pm', y: 63 },
        { x: '15:17pm', y: 65 },
        { x: '15:27pm', y: 60 },
        { x: '15:37pm', y: 68 },
        { x: '15:47pm', y: 69 },
        { x: '15:57pm', y: 68 },
        { x: '16:07pm', y: 56 },
        { x: '16:17pm', y: 70 },
        { x: '16:27pm', y: 59 },
        { x: '16:37pm', y: 79 },
        { x: '16:47pm', y: 66 },
        { x: '16:57pm', y: 71 },
        { x: '17:07pm', y: 89 },
        { x: '17:17pm', y: 86 },
        { x: '17:27pm', y: 99 },
        { x: '17:37pm', y: 86 },
        { x: '17:47pm', y: 81 },
        { x: '17:57pm', y: 89 },
      ],
      gameChartXLabels: [
        '15:17pm',
        '15:37pm',
        '15:57pm',
        '16:17pm',
        '16:37pm',
        '16:57pm',
        '17:17pm',
        '17:37pm',
        '17:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 1,
        hitsTotal: 6,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Boston Red Sox',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 0,
        hitsTotal: 4,
        errorsTotal: 1,
      },
    },
    29: {
      eventId: '2413100',
      teamId: 242,
      gameData: Apr29GameData,
      img: astrosLogo,
      gameChartData: [
        { x: '19:07pm', y: 92 },
        { x: '19:17pm', y: 99 },
        { x: '19:27pm', y: 89 },
        { x: '19:37pm', y: 91 },
        { x: '19:47pm', y: 88 },
        { x: '19:57pm', y: 63 },
        { x: '20:07pm', y: 21 },
        { x: '20:17pm', y: 22 },
        { x: '20:27pm', y: 22 },
        { x: '20:37pm', y: 23 },
        { x: '20:47pm', y: 21 },
        { x: '20:57pm', y: 22 },
        { x: '21:07pm', y: 21 },
        { x: '21:17pm', y: 22 },
        { x: '21:27pm', y: 20 },
        { x: '21:37pm', y: 20 },
        { x: '21:47pm', y: 20 },
        { x: '21:57pm', y: 20 },
        { x: '22:07pm', y: 19 },
        { x: '22:17pm', y: 18 },
        { x: '22:27pm', y: 17 },
        { x: '22:37pm', y: 19 },
        { x: '22:47pm', y: 19 },
        { x: '22:57pm', y: 18 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
        '22:17pm',
        '22:37pm',
        '22:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 1,
        runs3: 3,
        runs4: 0,
        runs5: 0,
        runs6: 1,
        runs7: 2,
        runs8: 0,
        runs9: 0,
        runsTotal: 7,
        hitsTotal: 13,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Houston Astros',
        runs1: 1,
        runs2: 0,
        runs3: 3,
        runs4: 1,
        runs5: 0,
        runs6: 5,
        runs7: 0,
        runs8: 1,
        runs9: 0,
        runsTotal: 11,
        hitsTotal: 14,
        errorsTotal: 0,
      },
    },
    30: {
      eventId: '2413102',
      teamId: 242,
      gameData: Apr30GameData,
      img: astrosLogo,
      gameChartData: [
        { x: '15:07pm', y: 78 },
        { x: '15:17pm', y: 78 },
        { x: '15:27pm', y: 67 },
        { x: '15:37pm', y: 87 },
        { x: '15:47pm', y: 65 },
        { x: '15:57pm', y: 84 },
        { x: '16:07pm', y: 87 },
        { x: '16:17pm', y: 78 },
        { x: '16:27pm', y: 50 },
        { x: '16:37pm', y: 66 },
        { x: '16:47pm', y: 57 },
        { x: '16:57pm', y: 72 },
        { x: '17:07pm', y: 82 },
        { x: '17:17pm', y: 80 },
        { x: '17:27pm', y: 80 },
        { x: '17:37pm', y: 90 },
        { x: '17:47pm', y: 91 },
        { x: '17:57pm', y: 74 },
      ],
      gameChartXLabels: [
        '15:17pm',
        '15:37pm',
        '15:57pm',
        '16:17pm',
        '16:37pm',
        '16:57pm',
        '17:17pm',
        '17:37pm',
        '17:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 1,
        runs2: 0,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 2,
        hitsTotal: 6,
        errorsTotal: 1,
      },
      awayLine: {
        name: 'Houston Astros',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 1,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 8,
        errorsTotal: 0,
      },
    },
  },
  5: {
    1: {
      eventId: '2413109',
      teamId: 242,
      gameData: May1GameData,
      img: astrosLogo,
      gameChartData: [
        { x: '13:37pm', y: 90 },
        { x: '13:47pm', y: 86 },
        { x: '13:57pm', y: 80 },
        { x: '14:07pm', y: 90 },
        { x: '14:17pm', y: 71 },
        { x: '14:27pm', y: 60 },
        { x: '14:37pm', y: 81 },
        { x: '14:47pm', y: 56 },
        { x: '14:57pm', y: 52 },
        { x: '15:07pm', y: 38 },
        { x: '15:17pm', y: 62 },
        { x: '15:27pm', y: 59 },
        { x: '15:37pm', y: 50 },
        { x: '15:47pm', y: 54 },
        { x: '15:57pm', y: 55 },
        { x: '16:07pm', y: 44 },
        { x: '16:17pm', y: 60 },
        { x: '16:27pm', y: 48 },
        { x: '16:37pm', y: 61 },
      ],
      gameChartXLabels: [
        '13:47pm',
        '14:07pm',
        '14:27pm',
        '14:47pm',
        '15:07pm',
        '15:27pm',
        '15:47pm',
        '16:07pm',
        '16:27pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 2,
        runs7: 1,
        runs8: 0,
        runs9: 'x',
        runsTotal: 3,
        hitsTotal: 3,
        errorsTotal: 1,
      },
      awayLine: {
        name: 'Houston Astros',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 1,
        runs7: 1,
        runs8: 0,
        runs9: 0,
        runsTotal: 2,
        hitsTotal: 8,
        errorsTotal: 1,
      },
    },
    2: {
      eventId: '2413113',
      teamId: 234,
      gameData: May2GameData,
      img: newYorkLogo,
      gameChartData: [
        { x: '19:07pm', y: 92 },
        { x: '19:17pm', y: 85 },
        { x: '19:27pm', y: 84 },
        { x: '19:37pm', y: 86 },
        { x: '19:47pm', y: 81 },
        { x: '19:57pm', y: 88 },
        { x: '20:07pm', y: 69 },
        { x: '20:17pm', y: 86 },
        { x: '20:27pm', y: 73 },
        { x: '20:37pm', y: 89 },
        { x: '20:47pm', y: 70 },
        { x: '20:57pm', y: 75 },
        { x: '21:07pm', y: 90 },
        { x: '21:17pm', y: 78 },
        { x: '21:27pm', y: 72 },
        { x: '21:37pm', y: 87 },
        { x: '21:47pm', y: 82 },
        { x: '21:57pm', y: 75 },
        { x: '22:07pm', y: 53 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 2,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 2,
        hitsTotal: 9,
        errorsTotal: 1,
      },
      awayLine: {
        name: 'New York Yankees',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 2,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 1,
        runsTotal: 3,
        hitsTotal: 7,
        errorsTotal: 0,
      },
    },
    3: {
      eventId: '2413119',
      teamId: 234,
      gameData: May3GameData,
      img: newYorkLogo,
      gameChartData: [
        { x: '19:07pm', y: 58 },
        { x: '19:17pm', y: 74 },
        { x: '19:27pm', y: 58 },
        { x: '19:37pm', y: 66 },
        { x: '19:47pm', y: 45 },
        { x: '19:57pm', y: 61 },
        { x: '20:07pm', y: 57 },
        { x: '20:17pm', y: 68 },
        { x: '20:27pm', y: 65 },
        { x: '20:37pm', y: 67 },
        { x: '20:47pm', y: 58 },
        { x: '20:57pm', y: 93 },
        { x: '21:07pm', y: 60 },
        { x: '21:17pm', y: 57 },
        { x: '21:27pm', y: 61 },
        { x: '21:37pm', y: 56 },
        { x: '21:47pm', y: 54 },
        { x: '21:57pm', y: 76 },
        { x: '22:07pm', y: 61 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 1,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 8,
        errorsTotal: 2,
      },
      awayLine: {
        name: 'New York Yankees',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 1,
        runs7: 6,
        runs8: 0,
        runs9: 2,
        runsTotal: 9,
        hitsTotal: 10,
        errorsTotal: 1,
      },
    },
    4: {
      eventId: '2413124',
      teamId: 234,
      gameData: May4GameData,
      img: newYorkLogo,
      gameChartData: [
        { x: '19:07pm', y: 86 },
        { x: '19:17pm', y: 81 },
        { x: '19:27pm', y: 76 },
        { x: '19:37pm', y: 81 },
        { x: '19:47pm', y: 73 },
        { x: '19:57pm', y: 80 },
        { x: '20:07pm', y: 65 },
        { x: '20:17pm', y: 89 },
        { x: '20:27pm', y: 67 },
        { x: '20:37pm', y: 73 },
        { x: '20:47pm', y: 47 },
        { x: '20:57pm', y: 74 },
        { x: '21:07pm', y: 44 },
        { x: '21:17pm', y: 66 },
        { x: '21:27pm', y: 62 },
        { x: '21:37pm', y: 89 },
        { x: '21:47pm', y: 82 },
        { x: '21:57pm', y: 91 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 1,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 2,
        hitsTotal: 4,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'New York Yankees',
        runs1: 0,
        runs2: 0,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 5,
        errorsTotal: 0,
      },
    },
    16: {
      eventId: '2413129',
      teamId: 236,
      gameData: May16GameData,
      img: marinersLogo,
      gameChartData: [
        { x: '19:07pm', y: 83 },
        { x: '19:17pm', y: 92 },
        { x: '19:27pm', y: 80 },
        { x: '19:37pm', y: 89 },
        { x: '19:47pm', y: 86 },
        { x: '19:57pm', y: 81 },
        { x: '20:07pm', y: 68 },
        { x: '20:17pm', y: 65 },
        { x: '20:27pm', y: 51 },
        { x: '20:37pm', y: 68 },
        { x: '20:47pm', y: 52 },
        { x: '20:57pm', y: 64 },
        { x: '21:07pm', y: 53 },
        { x: '21:17pm', y: 79 },
        { x: '21:27pm', y: 62 },
        { x: '21:37pm', y: 68 },
        { x: '21:47pm', y: 64 },
        { x: '21:57pm', y: 54 },
        { x: '22:07pm', y: 49 },
        { x: '22:17pm', y: 70 },
        { x: '22:27pm', y: 83 },
        { x: '22:37pm', y: 53 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
        '22:17pm',
        '22:37pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 1,
        runs2: 1,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 1,
        runs7: 2,
        runs8: 1,
        runs9: 'x',
        runsTotal: 6,
        hitsTotal: 10,
        errorsTotal: 1,
      },
      awayLine: {
        name: 'Seattle Mariners',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 1,
        runs8: 0,
        runs9: 1,
        runsTotal: 2,
        hitsTotal: 4,
        errorsTotal: 1,
      },
    },
    17: {
      eventId: '2413132',
      teamId: 236,
      gameData: May17GameData,
      img: marinersLogo,
      gameChartData: [
        { x: '19:07pm', y: 85 },
        { x: '19:17pm', y: 91 },
        { x: '19:27pm', y: 91 },
        { x: '19:37pm', y: 91 },
        { x: '19:47pm', y: 56 },
        { x: '19:57pm', y: 70 },
        { x: '20:07pm', y: 87 },
        { x: '20:17pm', y: 74 },
        { x: '20:27pm', y: 71 },
        { x: '20:37pm', y: 72 },
        { x: '20:47pm', y: 65 },
        { x: '20:57pm', y: 76 },
        { x: '21:07pm', y: 52 },
        { x: '21:17pm', y: 62 },
        { x: '21:27pm', y: 62 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 3,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 3,
        hitsTotal: 6,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Seattle Mariners',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 0,
        hitsTotal: 6,
        errorsTotal: 0,
      },
    },
    18: {
      eventId: '2413134',
      teamId: 236,
      gameData: May18GameData,
      img: marinersLogo,
      gameChartData: [
        { x: '19:07pm', y: 84 },
        { x: '19:17pm', y: 84 },
        { x: '19:27pm', y: 84 },
        { x: '19:37pm', y: 79 },
        { x: '19:47pm', y: 69 },
        { x: '19:57pm', y: 79 },
        { x: '20:07pm', y: 84 },
        { x: '20:17pm', y: 84 },
        { x: '20:27pm', y: 53 },
        { x: '20:37pm', y: 87 },
        { x: '20:47pm', y: 51 },
        { x: '20:57pm', y: 74 },
        { x: '21:07pm', y: 66 },
        { x: '21:17pm', y: 72 },
        { x: '21:27pm', y: 45 },
        { x: '21:37pm', y: 54 },
        { x: '21:47pm', y: 49 },
        { x: '21:57pm', y: 58 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 7,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Seattle Mariners',
        runs1: 1,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 1,
        runs6: 0,
        runs7: 2,
        runs8: 0,
        runs9: 1,
        runsTotal: 5,
        hitsTotal: 10,
        errorsTotal: 0,
      },
    },
    20: {
      eventId: '2413141',
      teamId: 241,
      gameData: May20GameData,
      img: redsLogo,
      gameChartData: [
        { x: '19:07pm', y: 64 },
        { x: '19:17pm', y: 78 },
        { x: '19:27pm', y: 77 },
        { x: '19:37pm', y: 89 },
        { x: '19:47pm', y: 87 },
        { x: '19:57pm', y: 88 },
        { x: '20:07pm', y: 84 },
        { x: '20:17pm', y: 91 },
        { x: '20:27pm', y: 83 },
        { x: '20:37pm', y: 84 },
        { x: '20:47pm', y: 87 },
        { x: '20:57pm', y: 85 },
        { x: '21:07pm', y: 81 },
        { x: '21:17pm', y: 91 },
        { x: '21:27pm', y: 78 },
        { x: '21:37pm', y: 66 },
        { x: '21:47pm', y: 75 },
        { x: '21:57pm', y: 89 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 2,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 2,
        hitsTotal: 9,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Cincinnati Reds',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 1,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 8,
        errorsTotal: 1,
      },
    },
    21: {
      eventId: '2413145',
      teamId: 241,
      gameData: May21GameData,
      img: redsLogo,
      gameChartData: [
        { x: '15:07pm', y: 82 },
        { x: '15:17pm', y: 90 },
        { x: '15:27pm', y: 89 },
        { x: '15:37pm', y: 93 },
        { x: '15:47pm', y: 84 },
        { x: '15:57pm', y: 91 },
        { x: '16:07pm', y: 85 },
        { x: '16:17pm', y: 81 },
        { x: '16:27pm', y: 63 },
        { x: '16:37pm', y: 87 },
        { x: '16:47pm', y: 79 },
        { x: '16:57pm', y: 83 },
        { x: '17:07pm', y: 81 },
        { x: '17:17pm', y: 89 },
        { x: '17:27pm', y: 69 },
      ],
      gameChartXLabels: [
        '15:17pm',
        '15:37pm',
        '15:57pm',
        '16:17pm',
        '16:37pm',
        '16:57pm',
        '17:17pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 1,
        runs5: 0,
        runs6: 0,
        runs7: 2,
        runs8: 0,
        runs9: 'x',
        runsTotal: 3,
        hitsTotal: 7,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Cincinnati Reds',
        runs1: 0,
        runs2: 0,
        runs3: 0,
        runs4: 1,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 1,
        hitsTotal: 7,
        errorsTotal: 1,
      },
    },
    22: {
      eventId: '2413149',
      teamId: 241,
      gameData: May22GameData,
      img: redsLogo,
      gameChartData: [
        { x: '13:37pm', y: 94 },
        { x: '13:47pm', y: 77 },
        { x: '13:57pm', y: 71 },
        { x: '14:07pm', y: 59 },
        { x: '14:17pm', y: 68 },
        { x: '14:27pm', y: 66 },
        { x: '14:37pm', y: 80 },
        { x: '14:47pm', y: 69 },
        { x: '14:57pm', y: 78 },
        { x: '15:07pm', y: 62 },
        { x: '15:17pm', y: 61 },
        { x: '15:27pm', y: 49 },
        { x: '15:37pm', y: 51 },
        { x: '15:47pm', y: 31 },
        { x: '15:57pm', y: 42 },
        { x: '16:07pm', y: 51 },
        { x: '16:17pm', y: 56 },
        { x: '16:27pm', y: 48 },
        { x: '16:37pm', y: 50 },
        { x: '16:47pm', y: 48 },
        { x: '16:57pm', y: 68 },
      ],
      gameChartXLabels: [
        '13:47pm',
        '14:07pm',
        '14:27pm',
        '14:47pm',
        '15:07pm',
        '15:27pm',
        '15:47pm',
        '16:07pm',
        '16:27pm',
        '16:47pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 1,
        runs3: 1,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 2,
        hitsTotal: 4,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Cincinnati Reds',
        runs1: 2,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 0,
        runs6: 0,
        runs7: 0,
        runs8: 1,
        runs9: 0,
        runsTotal: 3,
        hitsTotal: 6,
        errorsTotal: 2,
      },
    },
    31: {
      eventId: '2413155',
      teamId: 228,
      gameData: May31GameData,
      img: whiteSoxLogo,
      gameChartData: [
        { x: '19:07pm', y: 94 },
        { x: '19:17pm', y: 87 },
        { x: '19:27pm', y: 61 },
        { x: '19:37pm', y: 83 },
        { x: '19:47pm', y: 85 },
        { x: '19:57pm', y: 89 },
        { x: '20:07pm', y: 76 },
        { x: '20:17pm', y: 80 },
        { x: '20:27pm', y: 85 },
        { x: '20:37pm', y: 83 },
        { x: '20:47pm', y: 79 },
        { x: '20:57pm', y: 75 },
        { x: '21:07pm', y: 81 },
        { x: '21:17pm', y: 84 },
        { x: '21:27pm', y: 62 },
        { x: '21:37pm', y: 75 },
        { x: '21:47pm', y: 55 },
        { x: '21:57pm', y: 68 },
        { x: '22:07pm', y: 56 },
        { x: '22:17pm', y: 92 },
        { x: '22:27pm', y: 67 },
        { x: '22:37pm', y: 77 },
      ],
      gameChartXLabels: [
        '19:17pm',
        '19:37pm',
        '19:57pm',
        '20:17pm',
        '20:37pm',
        '20:57pm',
        '21:17pm',
        '21:37pm',
        '21:57pm',
        '22:17pm',
        '22:37pm',
      ],
      homeLine: {
        name: 'Toronto Blue Jays',
        runs1: 0,
        runs2: 2,
        runs3: 0,
        runs4: 0,
        runs5: 4,
        runs6: 0,
        runs7: 0,
        runs8: 0,
        runs9: 'x',
        runsTotal: 6,
        hitsTotal: 12,
        errorsTotal: 0,
      },
      awayLine: {
        name: 'Cincinnati Reds',
        runs1: 1,
        runs2: 0,
        runs3: 0,
        runs4: 0,
        runs5: 2,
        runs6: 2,
        runs7: 0,
        runs8: 0,
        runs9: 0,
        runsTotal: 5,
        hitsTotal: 13,
        errorsTotal: 0,
      },
    },
  },
}
