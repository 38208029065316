import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Box, Button } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const PdfButton = ({ fileName }) => {
  const handleDownloadPdf = () => {
    const content = document.getElementById('pdf-content'); // The ID of the main content area

    html2canvas(content, {
      useCORS: true,
      ignoreElements: element => {
        // Return true for elements to ignore
        return (
          element.classList.contains('gm-fullscreen-control') ||
          element.classList.contains('gm-style-cc') ||
          element.classList.contains('gmnoprint') ||
          // element.tagName === 'HEADER' || // Example: exclude <header> element
          false // Include all other elements
        );
      }
    }).then(canvas => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${fileName || 'download'}.pdf`);
    });
  };

  return (
    <Box mt="8px">
      <Button onClick={handleDownloadPdf} 
        startIcon={<PictureAsPdfIcon sx={{
          fontSize: '22px !important'
        }}/>}>
        Download PDF
      </Button>
    </Box>

  );
};

export default PdfButton;
