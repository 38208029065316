import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../auth/AuthProvider'
import { Box, TextField } from '@material-ui/core'
import { Button } from '@mui/material'

const LoginPage = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const { setAuthenticated, login } = useContext(AuthContext)

  const handleLogin = async e => {
    e.preventDefault()
    try {
      await login(email, password)
      console.error('handler called')
      setAuthenticated(true)
      navigate('/traffic')
      window.location.reload()
    } catch (error) {
      setError('Authentication failed')
    }
  }

  return (
    <Box>
      <h2>Login</h2>
      {error && <p>{error}</p>}
      <form onSubmit={handleLogin}>
        <Box
          sx={{
            display: 'flex !important',
            flexDirection: 'column',
          }}
        >
          <TextField
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            fullWidth
          />
          <TextField
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Password"
            fullWidth
          />
          <Button
            type="submit"
            sx={{
              marginTop: '10px !important',
            }}
          >
            Login
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default LoginPage
