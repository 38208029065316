import React, { useState, useContext, useEffect } from 'react';
import AppContext from 'src/contexts-global/AppContext';
import MultipleSelectChip from 'src/components/common/multi-select-chip';
import { Box } from '@mui/material';
import theme from 'src/themes';

const PersonaSelector = () => {

  // Global context
  const { appState, allPersonas, setSelectedPersonas } = useContext(AppContext);

  const personas = allPersonas;

  return (
    <Box> 
      <MultipleSelectChip
        label="Personas"
        options={personas}
        selectedOptions={appState.selectedPersonas}
        onChange={setSelectedPersonas}
      />
    </Box>
  );
}

export default PersonaSelector