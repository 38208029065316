import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

export default function ModularLineGraph(props) {

  // Config and formatting logic can be seen in ModularDemos //

  const {data, options, height, width} = props;

  return (
      <Line data={data} options={options} height={height} width={'100%'} />
  )
}
