import { Box, Divider, Typography } from '@mui/material'
import { set } from 'date-fns'
import * as React from 'react'
import { useEffect, useState, useContext } from 'react'
import theme from 'src/themes'
import AppContext from 'src/contexts-global/AppContext'

var seedrandom = require('seedrandom')

function ControlPanel({ affiliate }) {
  const { sponsorsList, setSponsorsList, selectedSponsor, setSelectedSponsor } =
    useContext(AppContext)

  const [uplift, setUplift] = useState(null)
  const [conversion, setConversion] = useState(null)
  const [brand, setBrand] = useState('')
  const [brandURL, setBrandURL] = useState('')

  const rngRange = (rng, min, max) => {
    var random = rng()
    var range = max - min
    var adjustment = range * random
    var result = min + adjustment
    return Math.round(result * 100) / 100
  }

  useEffect(() => {
    console.log(affiliate)
  }, [affiliate?.name])

  // Helper function for making farther locations smaller
  const deg2rad = deg => {
    return deg * (Math.PI / 180)
  }

  const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
    var R = 6371 // Radius of the earth in km
    var dLat = deg2rad(lat2 - lat1) // deg2rad below
    var dLon = deg2rad(lon2 - lon1)
    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    var d = R * c // Distance in km
    return d
  }

  useEffect(() => {
    if (affiliate) {
      console.log(affiliate)
      let rng = seedrandom(affiliate.id)

      let uplift = rngRange(rng, 0.01, 4.6)
      let conversion = rngRange(rng, 4.9, 8.4)

      // Temporary Hack to do branding.
      let brand = affiliate.id.split('-')[0]
      if (brand == 'Sobeys') {
        let distance = getDistanceFromLatLonInKm(
          affiliate.latitude,
          affiliate.longitude,
          43.6434661,
          -79.3790989,
        )
        if (distance < 5) {
          uplift = rngRange(rng, 0.01, 4.6)
          conversion = rngRange(rng, 4.9, 10.4)
        } else if (distance > 40) {
          uplift = 0
          conversion = 0
        } else {
          uplift = rngRange(rng, 0.0001, 0.03)
          conversion = uplift + rngRange(rng, 0.0, 0.3)
        }
      } else if (brand == 'Nike') {
        let distance = getDistanceFromLatLonInKm(
          affiliate.latitude,
          affiliate.longitude,
          43.6434661,
          -79.3790989,
        )
        if (distance < 50) {
          uplift = rngRange(rng, 0.01, 1.1)
          conversion = uplift + rngRange(rng, 0.1, 2.3)
        } else {
          uplift = 0
          conversion = 0
        }
      } else if (brand == 'Sportcheck') {
        let distance = getDistanceFromLatLonInKm(
          affiliate.latitude,
          affiliate.longitude,
          43.6434661,
          -79.3790989,
        )
        if (distance < 50) {
          uplift = rngRange(rng, 0.01, 1.1)
          conversion = uplift + rngRange(rng, 0.1, 1.1)
        } else {
          uplift = 0
          conversion = 0
        }
      } else if (brand == 'Cgoose') {
        let distance = getDistanceFromLatLonInKm(
          affiliate.latitude,
          affiliate.longitude,
          43.6434661,
          -79.3790989,
        )
        if (distance < 50) {
          uplift = rngRange(rng, 0.01, 0.5)
          conversion = uplift + rngRange(rng, 0.1, 0.4)
        } else {
          uplift = 0
          conversion = 0
        }
      } else if (brand == 'BMOAirport') {
        if (affiliate.name == 'Billy Bishop Toronto City Airport') {
          uplift = rngRange(rng, 0.01, 0.5)
          conversion = uplift + rngRange(rng, 0.1, 0.4)
        }
      } else if (brand == 'PizzaPizza') {
        let distance = getDistanceFromLatLonInKm(
          affiliate.latitude,
          affiliate.longitude,
          43.6331702,
          -79.4186162,
        )
        if (distance < 50) {
          uplift = rngRange(rng, 0.01, 3.5)
          conversion = uplift + rngRange(rng, 0.1, 1.4)
        } else {
          uplift = 0
          conversion = 0
        }
      } else if (brand == 'BurgerKing') {
        let distance = getDistanceFromLatLonInKm(
          affiliate.latitude,
          affiliate.longitude,
          43.6331702,
          -79.4186162,
        )
        if (distance < 60) {
          uplift = rngRange(rng, 0.01, 2.5)
          conversion = uplift + rngRange(rng, 0.1, 1.4)
        } else {
          uplift = 0
          conversion = 0
        }
      } else if (brand == 'Avis') {
        let distance = getDistanceFromLatLonInKm(
          affiliate.latitude,
          affiliate.longitude,
          43.6331702,
          -79.4186162,
        )
        if (distance < 50) {
          uplift = rngRange(rng, 0.01, 0.3)
          conversion = uplift + rngRange(rng, 0.1, 0.2)
        } else {
          uplift = 0
          conversion = 0
        }
      } else if (brand == 'HotelX') {
        uplift = rngRange(rng, 0.01, 2.5)
        conversion = uplift + rngRange(rng, 0.1, 1.5)
      }

      uplift = affiliate.conversion * 100 * rngRange(rng, 0.1, 0.25)
      uplift = uplift.toFixed(4)
      conversion = affiliate.conversion * 100
      conversion = conversion.toFixed(4)
      setUplift(uplift)
      setConversion(conversion)

      if (brand == 'TimHortons') {
        setBrand('Tim Hortons')
        setBrandURL('assets/images/affiliate-map/timHortons.png')
      } else if (brand == 'HomeHardware') {
        setBrand('Home Hardware')
        setBrandURL('assets/images/affiliate-map/homeHardware.png')
      } else if (brand == 'MaryBrowns') {
        setBrand('Mary Browns')
        setBrandURL('assets/images/affiliate-map/maryBrown.png')
      } else if (brand == 'Sobeys') {
        setBrand('Sobeys')
        setBrandURL('assets/images/affiliate-map/sobeys.png')
      } else if (brand == 'Nike') {
        setBrand('Nike')
        setBrandURL('assets/images/affiliate-map/nike.png')
      } else if (brand == 'Sportcheck') {
        setBrand('Sportcheck')
        setBrandURL('assets/images/affiliate-map/sportcheck.png')
      } else if (brand == 'Cgoose') {
        setBrand('Canada Goose')
        setBrandURL('assets/images/affiliate-map/canadagoose.png')
      } else if (brand == 'PizzaPizza') {
        setBrand('Pizza Pizza')
        setBrandURL('assets/images/affiliate-map/pizzapizza.png')
      } else if (brand == 'BurgerKing') {
        setBrand('Burger King')
        setBrandURL('assets/images/affiliate-map/burgerKing.png')
      } else if (brand == 'Avis') {
        setBrand('Avis')
        setBrandURL('assets/images/affiliate-map/avis.png')
      } else if (brand == 'HotelX') {
        setBrand('Hotel X')
        setBrandURL('assets/images/affiliate-map/hotelX.png')
      } else if (brand == 'BMOAirport') {
        setBrand('Airport')
        setBrandURL('assets/images/affiliate-map/airport.png')
      }
    }
  }, [affiliate])
  return (
    <>
      {affiliate && (
        <Box
          className="control-panel"
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            padding: '16px',
            width: '350px',
            background: theme.color.white,
            boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
          }}
        >
          <Box mb="8px">
            {selectedSponsor?.img && (
              <img
                className="affiliateLogo"
                height="45px"
                src={selectedSponsor.img}
                alt="Sponsor Logo"
              />
            )}
          </Box>
          <Typography variant="h3">{affiliate.name}</Typography>

          <Typography variant="body1">
            {affiliate.address}
            <br />
            {affiliate.city}, {affiliate.state}
            <br />
            {affiliate.zipcode}
          </Typography>
          <Divider
            sx={{
              marginTop: '16px',
            }}
          />
          <Box
            sx={{
              textAlign: 'center',
              marginTop: '8px',
            }}
          >
            <Typography>Location Conversion</Typography>
            <Typography variant="h1">{conversion}%</Typography>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              marginTop: '16px',
            }}
          >
            <Typography>Location Uplift</Typography>
            <Typography variant="h1">{uplift}%</Typography>
          </Box>
        </Box>
      )}
    </>
  )
}

export default React.memo(ControlPanel)
