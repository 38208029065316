'use strict';
import $ from 'jquery';
import moment from 'moment';

import React, { Component, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { ResponsiveBar } from '@nivo/bar'

import DatePicker from 'react-datepicker';
import Select from 'react-select'
import AsyncSelect from 'react-select/async';
import { Box, Grid, Typography } from '@mui/material';
import theme from 'src/themes';

const whatOptions = [
  {'value': 'totalvisit', label: "Total Visits"},
  {'value': 'dwell', label: "Average Dwell Time"},
  {'value': 'repeatvisit', label: "Repeat Visits"},
  {'value': 'firstvisit', label: "New Visitors"},
]

const whenOptions = [
  {'value': 'day', label: "Daily"},
  {'value': 'week', label: "Weekly"},
  {'value': 'month', label: "Monthly"},
]

const defaultWhoOptions = [
  {'value': 'Rogers Centre', label: "Rogers Centre"},
  {'value': 'Buffalo Bills', label: "Buffalo Bills"},
  {'value': 'City Average', label: "City Average"},

]

const WhatSelect = ({whatValue, onChange}) => (
  <Select className="whatSelect"
    value={whatValue}
    onChange={(e)=> onChange(e,)}
    placeholder="What would you like to compare?"
    options={whatOptions} />
)

const WhoSelect = ({whoValue, defaultLocations, onChange}) => (
  <Select isMulti className="whoSelect"
    placeholder="Which locations?"
    value={whoValue}
    onChange={(e)=> onChange(e, 'whoValue')}
    options={defaultWhoOptions}/>
)

const WhenSelect = ({whenValue, onChange}) => (
  <Select className="whenSelect" placeholder="Over what time period?"
    onChange={(e)=> onChange(e)}
    value={whenValue}
    options={whenOptions} />
)

const LocationBar = ({ data, keys, yAxisLabel, xAxisLabel }) => (
    <ResponsiveBar
        data={data}
        keys={keys}
        indexBy="time"
        groupMode='grouped'
        margin={{ top: 50, right: 200, bottom: 50, left: 60 }}
        padding={0.3}
        colors={[theme.color.bgBlue, theme.color.blue, theme.color.turquoise, theme.color.pink]}
        borderColor={{ from: 'color', modifiers: [ [ 'brighter', 1.6 ] ] }}
        borderWidth={1}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: xAxisLabel,
            legendPosition: 'middle',
            legendOffset: 32,
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: yAxisLabel,
            legendPosition: 'middle',
            legendOffset: -40
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 100,
                translateY: 0,
                itemsSpacing: 5,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
    />
)

export function CompetitiveTool () {
    const [data, setData] = useState([])
    const [whenValue, setWhenValue] = useState()
    const [whatValue, setWhatValue] = useState()
    const [whoValue, setWhoValue] = useState()
    const [keys, setKeys] = useState()

    const getDummyValue = () => {
        if(!whatValue){
            return 0
        }
        if (whatValue.value == 'dwell'){
            return Math.round(Math.random() * 120)
        } else if (whatValue.value == 'totalvisit'){
            return Math.round(Math.random() * 880)
        } else {
            return Math.round(Math.random() * 440)
        }
    }

    useEffect(() => {
        if (!whenValue || !whoValue || !whatValue) {
            return
        }

        let fields = []
        let tempData = []
        if (whenValue.value == 'month'){
            fields = moment.monthsShort()
        } else if (whenValue.value == 'week') {
            fields = moment.weekdaysShort()
        } else if (whenValue.value == 'day') {
            new Array(24).fill().forEach((acc, index) => {
                fields.push(moment( {hour: index} ).format('h:mm A'));
              })
        }
        for (let i = 0; i < fields.length; i++) {
            let tempDataItem = {
                'time': fields[i]
            }
            for (let whoIndex = 0; whoIndex < whoValue.length; whoIndex++){
                console.error(whoValue[whoIndex].value)
                tempDataItem[whoValue[whoIndex].value] = getDummyValue()
            }
            tempData.push(tempDataItem)
        }

        // Keys
        let keyList = []
        for (let whoIndex = 0; whoIndex < whoValue.length; whoIndex++){
            keyList.push(whoValue[whoIndex].value)
        }
        setKeys(keyList)
        setData(tempData)
        console.error(tempData)
        console.error(keys)
    }, [whenValue, whoValue, whatValue])
    
 
  return (
        <Grid container rowSpacing="32px" sx={{
            marginTop: '16px',
            textAlign: 'left',
        }}>
            <Grid item md={4} sx={{
                padding: '16px',
            }}>
                <WhatSelect 
                    whatValue={whatValue}
                    onChange={setWhatValue}/>
            </Grid>
            <Grid item md={4} sx={{
                padding: '16px',
            }}>
                <WhoSelect 
                    whoValue={whoValue}
                    onChange={setWhoValue}
                />
            </Grid>
            <Grid item md={4} sx={{
                padding: '16px',
            }}>
                <WhenSelect 
                    whenValue={whenValue}
                    onChange={setWhenValue}/>
            </Grid>
            <Grid item md={12} sx={{
            }}>
                <Box sx={{
                    width: '100%',
                    height: '450px',
                    marginBottom: '16px',
                }}>
                    {(!whatValue || !whenValue || !whoValue) ? (
                        <Typography
                            mt="50px"
                            variant="h2"
                            textAlign={'center'}
                            color="rgba(0,0,0,0.6)"
                        >Please make the required selections above to view your data</Typography>
                    ) : (
                        <LocationBar data={data} keys={keys} yAxisLabel={whatValue?.value == 'dwell' ? "Minutes" : "Visitors"} xAxisLabel={"Date"}/>
                    )}
                </Box>

            </Grid>
        </Grid>
  )
}
