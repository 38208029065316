import * as React from 'react';

function ControlPanel() {
  return (
    <div className="control-panel">
      <img className="affiliateLogo" src='assets/images/affiliate-map/timHortons.png'/>
      <h3 className="affiliateName">Queen St - Tim Hortons</h3>
      <h3 className="affiliateAddress">318 Queen St East, Acton, Ontario, L7J 1R2</h3>
      <hr />
      <div>
        <p className="locationMetricTitle">
            Traffic Overlap
        </p>
        <p className="locationMetric">
            13%
        </p>
      </div>
    </div>
  );
}

export default React.memo(ControlPanel);
