import { Tooltip, IconButton } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import theme from 'src/themes';

export default function HelpTooltip ({tooltip}) {

  return (
      <Tooltip title={tooltip ? tooltip : ''} placement="right-end"
        sx={{
          position: 'absolute',
          top: '0px',
          right: '0px',
        }}
      >
        <IconButton>
          <HelpIcon sx={{
            color: theme.color.grey9,
            
          }} />
        </IconButton>
      </Tooltip>
  );
}
