import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chart.js/auto';
import { grey } from '@material-ui/core/colors';


const ModularPieChart = (props) => {

  const { data, colors, inputLabel } = props;
  const basicTotal = data.reduce((total, entry) => total + entry.value, 0).toString();
  

  const chartData = {
    labels: inputLabel, 
    datasets: [
      {
        data,
        backgroundColor: colors,
      },
    ],
  };

  const chartOptions = {
    responsive: true, 
    maintainAspectRatio: false,
    cutout: '35%',
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          generateLabels: (chart) => {
            const labels = chart.data.labels.slice();
            const colors = chart.data.datasets[0].backgroundColor.slice();

            return labels.map((label, index) => ({
              text: label,
              fillStyle: colors[index],
              fontColor: 'black',
            }));
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      datalabels: {
        display: true,
        color: 'black',
        font: {
          size: 16,
          weight: 'bold',
        },
        formatter: (value, ctx) => {
          return value;
        },
      },
    },
  };

  const chartTotalStyle = { color: grey }

  return (
      <Doughnut data={chartData} options={chartOptions} width="300px" height="300px" />
  );
}

export default ModularPieChart;