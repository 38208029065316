import React, { useState, useLayoutEffect, useContext } from 'react';
import PageContainer from 'src/components/common/page-container';
import {Box, Card, CardContent, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import HelpTooltip from 'src/components/common/tooltip-help'; 
import AppContext from 'src/contexts-global/AppContext';

import DashboardToolbar from 'src/components/dashboard-toolbar/DashboardToolbar';
import CustomCard from 'src/components/common/custom-card';
import Map from 'src/components/map/Map';
import { CompetitiveTool } from 'src/components/competitive-analysis/CompetitiveTool';
import theme from 'src/themes';

const CompetitivePage = () => {
  const { setIsLoading } = useContext(AppContext);
  const [startDate, setStartDate] = useState(dayjs("2023-11-01", "YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs("2023-12-01", "YYYY-MM-DD"));
  const handleDateChange = (newDateRange) => {
    setStartDate(newDateRange[0]);
    setEndDate(newDateRange[1]);
  };

  useLayoutEffect(() => {
    setIsLoading(false)
  }, [])

  return (
    <PageContainer>
      <div id='pdf-content' style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
          {/* Dash Controls */}
          <Box container sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
            marginTop: '32px',
            marginBottom: '32px',
          }}>

          <DashboardToolbar
            page="traffic"

            startDate={startDate}
            endDate={endDate}
            onDateChange={handleDateChange}
          />
          </Box>
          <Grid container mt="16px" rowSpacing="32px" sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
          }}>
          <Grid item md={12} mb="64px">
          <Typography variant="h3" mt="32px" mb="16px" color={theme.color.black}
              sx={{
                fontSize: '28px',
              }}>
                  Competitive Analysis
                  </Typography>

            <CustomCard
              height={'600px'}
              body={
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  borderRadius: '12px',
                  padding: '16px',
          
              }}>
                <HelpTooltip 
                  sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                  }}
                  tooltip={'The number of returning vs new visitors for each day of the specified week.'} />
                <Box style={{ textAlign: 'center', 
                  display: 'flex', 
                  flexGrow: 1,
                  flexDirection: 'column',
                  }}>
                  <CompetitiveTool />
                </Box>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </div>
    </PageContainer>
  );
};

export default CompetitivePage;
