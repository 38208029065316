import React, { useContext, useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from 'src/auth/AuthProvider'
import { IconButton } from '@mui/material'
import { Menu as MenuIcon, Close as CloseIcon } from '@mui/icons-material'
import '@fontsource/poppins'
import theme from 'src/themes'

const Sidebar = ({ isMobile, isMobileSidebarOpen, setMobileSidebarOpen, showAffiliateBrands }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const { authenticated, setAuthenticated, logout } = useContext(AuthContext)

  const [locationReportsOpen, setLocationReportsOpen] = useState(true)
  const [manageAccountOpen, setManageAccountOpen] = useState(false)

  useEffect(() => {
    if (!authenticated) {
      return
    }
  }, [])

  const handleToggleSidebar = () => {
    setMobileSidebarOpen(!isMobileSidebarOpen)
  }

  const handleToggleLocationReports = () => {
    setLocationReportsOpen(!locationReportsOpen)
  }

  const handleToggleManageAccount = () => {
    setManageAccountOpen(!manageAccountOpen)
  }

  const handleLogout = () => {
    logout()
    setAuthenticated(false)
    navigate('/login')
    window.location.reload()
  }

  return (
    <div style={{ zIndex: 100 }}>
      {/* Hamburger icon to show the sidebar */}
      {isMobile && !isMobileSidebarOpen && (
        <IconButton
          onClick={handleToggleSidebar}
          color="inherit"
          aria-label="open sidebar"
          sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      <div
        style={{
          width: (isMobile && isMobileSidebarOpen) || !isMobile ? '275px' : '0',
          backgroundColor: theme.color.bgBlue,
          position: 'fixed',
          top: 0,
          bottom: 0,
          left: 0,
          transition: 'width 0.2s ease',
          boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
        }}
      >
        {(isMobile && isMobileSidebarOpen) || !isMobile ? (
          <>
            <div
              style={{
                padding: '20px',
                backgroundColor: theme.color.bgBlue,
                display: 'flex',
                width: '100%',
              }}
            >
              <div>
                <img
                  src="/images/logo/Dark.png"
                  alt="GLI Logo"
                  style={{
                    padding: '5px',
                    width: '100%',
                  }}
                />
              </div>
            </div>

            {/* Location Reports */}
            <div>
              <button
                style={{
                  width: '100%',
                  textAlign: 'left',
                  color: '#f0f0f0',
                  backgroundColor: theme.color.bgBlue,
                  border: 'none',
                  padding: '20px',
                  fontWeight: 'bold',
                  fontSize: '.8125rem',
                  // fontFamily: 'Poppins, sans-serif',
                  cursor: 'pointer',
                }}
                onClick={handleToggleLocationReports}
              >
                Location Reports
              </button>
              {locationReportsOpen && (
                // Dropdown transition doesn't work atm
                <div
                  style={{
                    height: locationReportsOpen ? '100%' : 0,
                    transition: 'height 0.2s ease',
                  }}
                >
                  <ul
                    style={{
                      backgroundColor: theme.color.midBlue,
                      margin: '0px',
                      padding: '20px 0px 10px 40px',
                    }}
                  >
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/traffic"
                        style={{
                          fontWeight: location.pathname === '/traffic' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        My Traffic Analytics
                      </Link>
                    </li>
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/affinity"
                        style={{
                          fontWeight: location.pathname === '/affinity' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Affinity Analytics
                      </Link>
                    </li>
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/distancetravelled"
                        style={{
                          fontWeight:
                            location.pathname === '/distancetravelled' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Distance Travelled
                      </Link>
                    </li>
                    {showAffiliateBrands &&
                      <li style={{ display: 'block', marginBottom: '10px' }}>
                        <Link
                          to="/affiliatebrands"
                          style={{
                            fontWeight: location.pathname === '/affiliatebrands' ? 'bold' : 'normal',
                            fontSize: '.8125rem',
                            color: '#f0f0f0',
                            textDecoration: 'none',
                          }}
                        >
                          Affiliate Brands
                        </Link>
                      </li>
                    }
                    {/* <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/economicimpact"
                        style={{
                          fontWeight: location.pathname === '/economicimpact' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Economic Impact
                      </Link>
                    </li>
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/competitiveanalysis"
                        style={{
                          fontWeight:
                            location.pathname === '/competitiveanalysis' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Competitive Analysis
                      </Link>
                    </li>
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/benchmarks"
                        style={{
                          fontWeight: location.pathname === '/benchmarks' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Benchmarks
                      </Link>
                    </li>
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/catchment"
                        style={{
                          fontWeight: location.pathname === '/catchment' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Catchment Analytics
                      </Link>
                    </li> */}

                    {/* <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link to="/sensors" style={{ fontWeight: location.pathname === '/sensors' ? 'bold' : 'normal', fontSize: '.8125rem', color: '#f0f0f0', textDecoration: 'none' }}>
                        Indoor Analytics
                      </Link>
                    </li> */}
                  </ul>
                </div>
              )}
            </div>

            {/* Manage Account */}
            <div>
              <button
                style={{
                  width: '100%',
                  textAlign: 'left',
                  color: '#f0f0f0',
                  backgroundColor: theme.color.bgBlue,
                  border: 'none',
                  padding: '20px',
                  fontWeight: 'bold',
                  fontSize: '.8125rem',
                  cursor: 'pointer',
                }}
                onClick={handleToggleManageAccount}
              >
                Manage Account
              </button>
              {manageAccountOpen && (
                <div>
                  <ul
                    style={{
                      backgroundColor: '#1d4880',
                      margin: '0px',
                      padding: '20px 0px 10px 40px',
                    }}
                  >
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/subscription"
                        style={{
                          fontWeight: location.pathname === '/subscription' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Subscription & Payment
                      </Link>
                    </li>
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/changepassword"
                        style={{
                          fontWeight: location.pathname === '/changepassword' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Change Password
                      </Link>
                    </li>
                    <li style={{ display: 'block', marginBottom: '10px' }}>
                      <Link
                        to="/settings"
                        style={{
                          fontWeight: location.pathname === '/settings' ? 'bold' : 'normal',
                          fontSize: '.8125rem',
                          color: '#f0f0f0',
                          textDecoration: 'none',
                        }}
                      >
                        Settings
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <button
              style={{
                width: '100%',
                textAlign: 'left',
                border: 'none',
                padding: '20px',
                fontWeight: 'bold',
                fontSize: '.8125rem',
                cursor: 'pointer',
                color: '#f0f0f0',
                backgroundColor: theme.color.bgBlue,
              }}
              onClick={handleLogout}
            >
              Logout
            </button>
          </>
        ) : null}
      </div>

      {/* X icon to hide the sidebar */}
      {isMobile && isMobileSidebarOpen && (
        <IconButton
          onClick={handleToggleSidebar}
          color="inherit"
          aria-label="close sidebar"
          sx={{ position: 'fixed', top: '10px', left: '10px', zIndex: 1 }}
        >
          <CloseIcon />
        </IconButton>
      )}
    </div>
  )
}

export default Sidebar
