import React from 'react';
import Map from 'src/components/map/Map';

const MapView = () => {
  const defaultProps = {
    center: {
      lat: 37.7749,
      lng: -122.4194,
    },
    zoom: 11,
  };

  return (
    <Map
      defaultCenter={defaultProps.center}
      defaultZoom={defaultProps.zoom}
      style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
    >
      {/* Add your map markers or other components here */}
    </Map>
  );
};

const BenchmarksPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ padding: '10px' }}>
        <h1>Benchmarks Page</h1>
        <p>Some additional content here</p>
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ position: 'relative', width: '100%', height: '50%' }}>
          <MapView />
        </div>
      </div>
    </div>
  );
};

export default BenchmarksPage;
