import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { LocalizationProvider as LocalizationProviderPro } from '@mui/x-date-pickers-pro';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CustomWeekSelector from './CustomWeekSelector';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { CalendarMonth } from '@mui/icons-material';
import theme from 'src/themes';

const DateSelector = ({ type, label, selectedDate, onDateChange }) => {
  const renderSelector = () => {
    // Month picker
    if (type === 'month') {
      return (
        <DatePicker
          label={label}
          value={selectedDate}
          onChange={(date) => onDateChange(date)}
          views={['year', 'month']}
          openTo="year"
          sx={{
            width: '100%',
          }}
        />
      );
    // Week picker
    } else if (type === 'week') {
      return (
        <CustomWeekSelector
          selectedDate={selectedDate}
          onDateChange={(date) => onDateChange(date)}
        />
      );
    // Date Range picker
    } else if (type === 'dateRange') {
      return (
        <DateRangePicker
          label={label}
          value={selectedDate}
          
          slotProps={{
            textField: {
              InputProps: {
                startAdornment: (
                  <CalendarMonth sx={{
                    color: theme.color.midBlue,
                    marginRight: '5px',
                  }}/>
                ),
              },
            }
          }}
          onChange={(date) => onDateChange(date)}
        />
      );
    }
  };

  return (
    <>
      {(type === 'month' || type === 'week') &&
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']}>
            {renderSelector()}
          </DemoContainer>
        </LocalizationProvider>
      }

      {type === 'dateRange' &&
        <LocalizationProviderPro dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateRangePicker']}>
            {renderSelector()}
          </DemoContainer>
        </LocalizationProviderPro>
      }
    </>

  );
};

export default DateSelector;
