import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

export default function ModularBarGraph(props) {

  // Config and formatting logic can be seen in ModularDemos //

  const {data, options, height, width} = props;

  return (
      <Bar data={data} options={options} height={height} width={'100%'} />
  )
}
