import { Box, Card, CardContent, Typography } from '@mui/material';
import { ReactElement } from 'react';
import theme from 'src/themes';

interface CustomCardProps {
    body: ReactElement
    backgroundColor?: string
    height?: string
}
export default function CustomCard ({body, backgroundColor, height}: CustomCardProps) {
    const graphContainerStyle = {
        minHeight: '400px', 
        height: height ? height : 'auto',
        margin: '.5rem',
    };

  return (
    <Card style={graphContainerStyle}
        sx={{
            boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
            height: '100%',
            borderRadius: '12px',
            background: backgroundColor ? backgroundColor : theme.color.white,
            position: 'relative',
        }}
    >
        {body}
  </Card>  )
}