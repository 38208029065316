import { Box, Card, CardContent, Typography } from '@mui/material';

export default function DataCard({children}) {

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', 
      flexGrow: 1, 
      }}>
      {children}
    </Box>
  )
}