import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { FormControl, InputLabel, MenuItem, Typography } from '@mui/material';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selectedOptions: string[], theme: Theme) {
  return {
    fontWeight:
      selectedOptions.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

type OptionsProp = {
  chipLabel: string;
  chipValue: string;
};

type MultipleSelectChipProps = {
  label: string;
  options: OptionsProp[];
  selectedOptions: string[]; // Ensure selectedOptions is consistently an array of strings
  onChange: (selectedOptions: string[]) => void;
};

export default function MultipleSelectChip({
  label,
  options,
  selectedOptions,
  onChange,
}: MultipleSelectChipProps) {
  const theme = useTheme();

  const handleChange = (event: SelectChangeEvent<typeof selectedOptions>) => {
    const {
      target: { value },
    } = event;

    // Ensure value is consistently an array of strings
    onChange(typeof value === 'string' ? value.split(',') : value);
  };

  const onDelete = (value: string) => {
    // Ensure selectedOptions is consistently an array of strings
    const tempSelectedOptions = selectedOptions.filter((option) => option !== value);
    onChange(tempSelectedOptions);
  };

  const style = {
    margin: '1rem',
  };

  return (
    <div style={style}>
      <FormControl fullWidth variant="outlined">
        <Typography
          sx={{
            color: theme.color.white,
          }}
        >{label}</Typography>
        <Select
          multiple
          value={selectedOptions}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label={label} />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  key={value}
                  label={value}
                  onMouseDown={(e) => e.stopPropagation()}
                  onDelete={() => onDelete(value)}
                />
              ))}
            </Box>
          )}
          sx={{
            background: theme.color.white,
          }}
          MenuProps={MenuProps}
        >
          {options.map((option) => (
            <MenuItem
              key={option.chipLabel}
              value={option.chipLabel}
              style={getStyles(option.chipValue, selectedOptions, theme)}
            >
              {option.chipLabel}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
