import React, { useContext } from 'react';
import AppContext from 'src/contexts-global/AppContext';
import { CircularProgress } from '@mui/material';
import theme from 'src/themes';

export default function PageContainer ({children}) {

  // Global context
  const { appState, setAppState, setSelectedPersonas, setLocation } = useContext(AppContext);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', width: '100%', }}>
      {appState.isLoading ? (
        <CircularProgress size="50px" sx={{
          height: '50px',
          marginTop: '20%',
          alignSelf: 'center' 
        }}/> 
       ) : ( 
        children
       )}
    </div>
  )
}