import React, { useState } from 'react'
import { Box, Card, CardContent, Typography } from '@mui/material'
import CardTitle from 'src/components/common/card-title-template'
import FlexColumnContainer from '../common/flex-column-container'
import FlexRowContainer from '../common/flex-row-container'
import theme from 'src/themes'
import CustomCard from '../common/custom-card'

const AffinityTable = data => {

  const handleImageLoad = (hit, event) => {
    // Image loaded successfully, do nothing
  };

  const handleImageError = (hit, event) => {
    // Image failed to load, set the source to hit.icon
    event.target.src = hit.icon;
  };

  console.log('test')
  console.log(Object.keys(data))

  return (
    <div style={{ width: '100%' }}>
      <CustomCard
        style={{ minHeight: '200px', color: theme.color.white, margin: '1rem' }}
        body={
          <Box
            sx={{
              padding: '16px',
            }}
          >
            <Typography variant="h3">Affinity Markets</Typography>
            <div
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', height: '60px' }}
            >
              <div style={{ flex: '1' }}></div>
              <div
                style={{
                  flex: '0 0 10%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                Before
              </div>
              <div
                style={{
                  flex: '0 0 10%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                Dwell
              </div>
              <div
                style={{
                  flex: '0 0 10%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold',
                }}
              >
                After
              </div>
            </div>
            <FlexColumnContainer>
              {data?.data?.results?.hits.map(hit => {
                console.error(hit)
                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                      height: '60px',
                    }}
                  >
                    <div style={{ flex: '0 0 10%', display: 'flex', alignItems: 'center' }}>
                      {/* Logo */}
                      <img
                        // src={hit.logo_url ? hit.logo_url : hit.icon}
                        src={hit.logo_url}
                        alt="Logo"
                        style={{ maxWidth: '100%', maxHeight: '100%' }}
                        onLoad={(event) => handleImageLoad(hit, event)}
                        onError={(event) => handleImageError(hit, event)}
                      />
                    </div>
                    <div
                      style={{
                        flex: '0 0 60%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Box>
                      <Typography sx={{
                        fontWeight: 700,
                        color: '#000',
                        display: 'block',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',

                      }}>
                     {hit.name}
                      </Typography>
                      </Box>
                      <Box>
                      <Typography>
                        {hit.vicinity}
                      </Typography>
                      </Box>
                    </div>
                    <div
                      style={{
                        flex: '0 0 10%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {hit.before_visits}
                    </div>
                    <div
                      style={{
                        flex: '0 0 10%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {hit.location_dwell}m
                    </div>
                    <div
                      style={{
                        flex: '0 0 10%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {hit.after_visits}
                    </div>
                  </div>
                )
              })}
            </FlexColumnContainer>
          </Box>
        }
      />
    </div>
  )
}

export default AffinityTable
