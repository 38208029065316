import React, { useState, useContext, useEffect } from 'react';
import AppContext from 'src/contexts-global/AppContext'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import StoreIcon from '@mui/icons-material/Store';
import theme from 'src/themes';

const SponsorSelector = () => {

  const { setSelectedSponsor, selectedSponsor, sponsorsList } = useContext(AppContext);

  const handleSponsorChange = (event) => {
    for (let i = 0; i < sponsorsList.length; i++) {
      if (sponsorsList[i].id === event.target.value) {
        setSelectedSponsor(sponsorsList[i])  
      }
    }

    // console.log(event.target.value)
  };

  return (
    <Box marginTop="16px">
      <FormControl fullWidth >
        <InputLabel>Sponsor</InputLabel>
        <Select
          value={selectedSponsor?.id ? selectedSponsor.id : ""}
          label="Sponsor"
          onChange={handleSponsorChange}
          startAdornment={
            <StoreIcon sx={{
              color: theme.color.midBlue,
              marginRight: '5px'
            }} />
          }
        >
          {sponsorsList?.map((sponsor) => (
            <MenuItem key={sponsor.id} value={sponsor.id}>
              {sponsor.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

export default SponsorSelector