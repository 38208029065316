import React, { useEffect, useState, useContext, useLayoutEffect } from 'react'
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import AppContext from 'src/contexts-global/AppContext'
import Sidebar from './components/sidebar/Sidebar'
import TrafficPage from 'src/pages/dashboard/TrafficPage'
import AffinityPage from 'src/pages/dashboard/AffinityPage'
import BenchmarksPage from 'src/pages/dashboard/BenchmarksPage'
import SensorsPage from 'src/pages/dashboard/SensorsPage'
import CatchmentPage from 'src/pages/dashboard/CatchmentPage'
import DistanceTravelledPage from 'src/pages/dashboard/DistanceTravelledPage'
import AffiliateBrandsPage from 'src/pages/dashboard/AffiliateBrandsPage'
import PrivateRoute from './auth/PrivateRoute'
import LoginPage from './pages/login/LoginPage'
import NotFound from './pages/login/NotFound'
import { AuthContext } from './auth/AuthProvider'
import SubscriptionPage from './pages/manage/SubscriptionPage'
import ChangePasswordPage from './pages/manage/ChangePasswordPage'
import SettingsPage from './pages/manage/SettingsPage'
import LogoBar from './components/logo-bar/LogoBar.js'
import { GetClient, GetLocationsList, GetUserSettings } from 'src/requests/api-requests'
import theme from './themes'
import CompetitivePage from './pages/dashboard/CompetitivePage'
import EconomicImpactPage from './pages/dashboard/EconomicImpactPage'
import { Box } from '@mui/material'

const App = () => {
  const {
    setLocation,
    setLocationsList,
    userData,
    location,
    locationsList,
    setUserData,
    allPersonas,
    setAllPersonas,
    setSelectedPersonas,
  } = useContext(AppContext)
  const { authenticated } = useContext(AuthContext)

  const threshold = 1000 // Change the threshold value to your desired breakpoint
  const [isMobile, setIsMobile] = useState(window.innerWidth <= threshold)
  const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false)
  const [showAffiliateBrands, setShowAffiliateBrands] = useState(false)

  useEffect(() => {
    if (authenticated) {
      const fetchClient = async () => {
        try {
          GetClient().then(data => {
            if(data?.show_affiliate) {
              setShowAffiliateBrands(true)
              console.log('hey')
              console.log(data.show_affiliate)
            }
          })
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
      fetchClient()
    }
    if (authenticated && locationsList!) {
      const fetchLocations = async () => {
        try {
          // // Dummy API call to retrieve location info (list of locations and their address details)
          // // Simulate a delay of 2 seconds as if it's a slow API call
          // await new Promise((resolve) => setTimeout(resolve, 2000))

          GetLocationsList().then(data => {
            setLocationsList(data)
            // if (affiliateBrandsDisableList.includes(data[0]?.client)) {
            //   setShowAffiliateBrands(false)
            // }
          })
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
      const fetchUserInfo = async () => {
        try {
          GetUserSettings().then(data => {
            setUserData(data)
          })
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }
      fetchUserInfo()
      fetchLocations()
    }
  }, [])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= threshold)
    }

    handleResize() // Check initial viewport width
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [threshold])

  return (
    <Router>
      <LogoBar />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <Box
          sx={{
            flex: '1',
            display: 'flex',
            marginLeft: '275px',
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
            paddingLeft: isMobile ? '25px' : '0px',
            transition: 'padding 0.2s ease-in-out',
            overflow: 'auto',
            background: theme.color.white,
          }}
        >
          <Sidebar
            isMobile={isMobile}
            isMobileSidebarOpen={isMobileSidebarOpen}
            setMobileSidebarOpen={setMobileSidebarOpen}
            showAffiliateBrands={showAffiliateBrands}
          />
          <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/traffic"
              element={
                <PrivateRoute>
                  <TrafficPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/affinity"
              element={
                <PrivateRoute>
                  <AffinityPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/distancetravelled"
              element={
                <PrivateRoute>
                  <DistanceTravelledPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/catchment"
              element={
                <PrivateRoute>
                  <CatchmentPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/affiliatebrands"
              element={
                <PrivateRoute>
                  <AffiliateBrandsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/economicimpact"
              element={
                <PrivateRoute>
                  <EconomicImpactPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/competitiveanalysis"
              element={
                <PrivateRoute>
                  <CompetitivePage />
                </PrivateRoute>
              }
            />

            <Route
              path="/benchmarks"
              element={
                <PrivateRoute>
                  <BenchmarksPage />
                </PrivateRoute>
              }
            />
            {/* <Route path="/sensors" element={
              <PrivateRoute>
                <SensorsPage />
              </PrivateRoute>
            } /> */}
            <Route
              path="/subscription"
              element={
                <PrivateRoute>
                  <SubscriptionPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/changepassword"
              element={
                <PrivateRoute>
                  <ChangePasswordPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <PrivateRoute>
                  <SettingsPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={
                <PrivateRoute>
                  <TrafficPage />
                </PrivateRoute>
              }
            />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  )
}

export default App
