import React from 'react';

const SubscriptionPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ padding: '10px' }}>
        <h1>Subscription & Payments Page</h1>
        <p>Some additional content here</p>
      </div>
    </div>
  );
};

export default SubscriptionPage;
