import dayjs from "dayjs";

const locationsList = [
  "RogersCentre",
  "RogersCentre2",
  "RogersCentre3",
]

const startDate = "2023-08-01";
const endDate = "2023-09-30";

// Create datestring array with specified start/end dates.

const generateDateList = (startDate, endDate) => {
  const dateList = [];
  let currentDate = dayjs(startDate);
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dateList.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }
  return dateList;
}

const dateStringList = generateDateList(startDate, endDate);

// RNG for number data

const rng = (min, max) => {
  const randomDecimal = Math.random();
  const randomNumberInRange = min + (randomDecimal * (max - min + 1));
  return Math.floor(randomNumberInRange);
  }

let trafficReportDummyData = locationsList.map(location => ({
  location: location,
  data: dateStringList.map(dateString => {

    let totalVisits = rng(600, 1000)
    let returningVisits = rng(300, 500)
    let avgDwellTime = rng(3000, 6000)

    return {
      date: dateString,
      totalVisits: totalVisits,
      returningVisits: returningVisits,
      firstTimeVisits: totalVisits - returningVisits,
      avgDwellTime: avgDwellTime,
    }
  })
}))


export const dummyData = {
  clientInfo: {
    name: "Krusty Krabs Ltd.",
    address: "123 Pineapple Street",
    city: "Toronto",
    state: "ON",
    country: "CA",
    zipcode: "A1B 2C3",
    logoUrl: "somestaticfile.com",
    locationsList: locationsList,
  },
  trafficReport: trafficReportDummyData,
  affinityReport: {},
  catchmentReport: {},
  distanceTravelledReport: {},
}