import React, { useState, useEffect } from 'react';

import { makeStyles } from '@mui/styles';

import { DEFAULT_DATE } from "../utils/settings.js";

import Header from "./Header.js";
import SideBar from "./SideBar.js";
import Map from "./Map.js";
import RenderMode from "./RenderMode.js";
import Counts from "./Counts.js";
import PieChart from './PieChart.js';
import TimeSlider from "./TimeSlider.js";
// import StatisticsPanel from "./StatisticsPanel.js";
// import StatisticsToggle from "./StatisticsToggle.js";

import { dateFormat, secondsFromDate } from '../utils/util.js';
import { isHomeGame } from '../utils/settings.js';

const useStyles = makeStyles({
   root:{
      display:'flex',
      flexDirection:'column',
      width:'100%',
      height:'800px',

      // Needed for drawer position when clicking on tracking icon.
      position: "relative",
   },
   content:{
      position:'relative',
      flex:1,
      display:'flex',
      flexDirection:'row',
      height:0,
      //backgroundColor:'#f1f6fa'
   },
   sidebar:{
     position:'relative',
     display:'flex',
     flexDirection:'column',
     width:400,
     padding:0,
   },
   mapContainer:{
      position:'relative',
      flex:1,
   }
});

function Main(props) {
   const {
      year,
      month,
      day,
      longitude,
      latitude,
      name,
      address,
      countryCode
   } = props;

    const classes = useStyles();
    const [searchPlace, setSearchPlace] = useState({
       longitude: 0,
       latitude: 0,
       name: "",
       address: "",
    });
    const [statsDrawerToggle, setStatsDrawerToggle] = useState(false);
    const [filter, setFilter] = useState({
       date: DEFAULT_DATE,
       timeRange: [
         secondsFromDate(dateFormat(DEFAULT_DATE)),
         secondsFromDate(dateFormat(DEFAULT_DATE))+86399
      ],
      category: "All Categories",
      category_distance: 20
    });
    const [counts, setCounts] = useState([]);
    const [renderMode, setRenderMode] = useState('cluster');

   useEffect(()=>{
      // this.filter.date = new Date(year + "-" + month + "-" + day)
      // this.setFilter(newFilter)
      if (year && month && day) {
         setFilter({
            ...filter,
            date: new Date(year + "-" + month + "-" + day)
         });
      };
      if (longitude && latitude) {
         setSearchPlace({
            ...searchPlace,
            longitude: longitude,
            latitude: latitude,
            name: name,
            address: address
         });
      };
   }, [year, month, day, longitude, latitude, address]);

    return (
       <div className={classes.root} id="drawer-container">
           {/*<Header
              searchPlace={searchPlace}
              setSearchPlace={setSearchPlace}
              filter={filter}
              setFilter={setFilter}
            />*/}
           <div className={classes.content}>
              {/*
              <div className={classes.sidebar}>
                 <SideBar
                    searchPlace={searchPlace}
                    setSearchPlace={setSearchPlace}
                 />
              </div>
            */}
              <div className={classes.mapContainer}>
                 <Map
                    searchPlace={searchPlace}
                    filter={filter}
                    renderMode={renderMode}
                    setCounts={setCounts}
                    countryCode={countryCode}
                 />
                 <RenderMode
                    renderMode={renderMode}
                    setRenderMode={setRenderMode}
                 />
                {/*
                  <StatisticsToggle
                     statsDrawerToggle={statsDrawerToggle}
                     setStatsDrawerToggle={setStatsDrawerToggle} />
                */}
                  <Counts counts={counts} />
                  <PieChart counts={counts} />
                  <TimeSlider
                     filter={filter}
                     setFilter={setFilter}
                  />
                {/*
                 <StatisticsPanel
                  statsDrawerToggle={statsDrawerToggle}
                  setStatsDrawerToggle={setStatsDrawerToggle}
                  filter={filter}
                  counts={counts} />
                */}
               </div>
           </div>
       </div>
    );
}

export default Main;
