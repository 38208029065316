import React, { useState, useLayoutEffect, useContext } from 'react';
import Map from 'src/components/map/Map';
import AppContext from 'src/contexts-global/AppContext';
import dayjs from 'dayjs';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

import PageContainer from 'src/components/common/page-container';
import DashboardToolbar from 'src/components/dashboard-toolbar/DashboardToolbar';
import CustomCard from 'src/components/common/custom-card';
import { CompetitiveTool } from 'src/components/competitive-analysis/CompetitiveTool';
import theme from 'src/themes';
import MetricCard from 'src/components/metric-card';
import AffiliateTrafficMap from 'src/components/economic-impact/AffiliateTrafficMap';
import HelpTooltip from 'src/components/common/tooltip-help'; 

const EconomicImpactPage = () => {
  const { setIsLoading } = useContext(AppContext);
  const [startDate, setStartDate] = useState(dayjs("2023-11-01", "YYYY-MM-DD"));
  const [endDate, setEndDate] = useState(dayjs("2023-12-01", "YYYY-MM-DD"));
  const handleDateChange = (newDateRange) => {
    setStartDate(newDateRange[0]);
    setEndDate(newDateRange[1]);
  };

  useLayoutEffect(() => {
    setIsLoading(false)
  }, [])


  return (
    <PageContainer>
      <Box container sx={{
        paddingLeft: '32px',
        paddingRight: '32px',
        marginTop: '32px',
        marginBottom: '32px',
      }}>
        <DashboardToolbar
          page="economicimpact"
          endDate={endDate}
        />
      </Box>
      <Box container sx={{
            display: 'grid',
            gridTemplateColumns: "repeat(4, 1fr)",
            paddingLeft: '32px',
            paddingRight: '32px',
          }}>
          <MetricCard
            title="Total Economic Gain"
            metric={'$256,435'}
            helpText='The economic change in the region.'
            changeLabel={<Typography
              sx={{
                fontSize: '16px'
              }}
              color={'rgba(0,0,0,0.6)'}>
                <ArrowUpwardIcon fontSize='16px' sx={{
                color: 'rgba(0,0,0,0.6)',
              }} />
                {`40% than last month`}</Typography>}
            />
          <MetricCard
            title="Activity"
            metric={'34,342 steps'}
            helpText='The total number of steps taken in the region.'
            changeLabel={<Typography
              sx={{
                fontSize: '16px'
              }}
              color={'rgba(0,0,0,0.6)'}>
                <ArrowUpwardIcon fontSize='16px' sx={{
                color: 'rgba(0,0,0,0.6)',
              }} />
                {`30% higher than last month`}</Typography>}
            />
        </Box> 
        <Grid container mt="16px" rowSpacing="32px" sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
          }}>
          <Grid item md={12} mb="64px">
          <Typography variant="h3" mt="32px" mb="16px" color={theme.color.black}
              sx={{
                fontSize: '28px',
              }}>
                  Economic Impact
                  </Typography>

            <CustomCard
              height={'600px'}
              body={
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  borderRadius: '12px',
                  padding: '16px',
          
              }}>
                <HelpTooltip 
                  sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '10px',
                  }}
                  tooltip={'The number of returning vs new visitors for each day of the specified week.'} />
                <Box style={{ textAlign: 'center', 
                  display: 'flex', 
                  flexGrow: 1,
                  flexDirection: 'column',
                  }}>
                    <AffiliateTrafficMap />
                </Box>
                </Box>
              }
            />
          </Grid>
        </Grid>    </PageContainer>
  );
};

export default EconomicImpactPage;
