import { Box, Card, CardContent, Grid,Typography } from '@mui/material';
import FlexColumnContainer from 'src/components/common/flex-column-container';
import HelpTooltip from 'src/components/common/tooltip-help'; 
import theme from 'src/themes';

interface MetricCardProps{
    title: string
    metric: number
    changeLabel: string
    helpText: string
}
export default function MetricCard ({title, metric, changeLabel, helpText}: MetricCardProps) {
    const valueTooltipContainerStyle = {
        minWidth: '200px',
        margin: '10px',
        backgroundColor: theme.color.blue,
        border: 'none',
        boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
        borderRadius: '12px',
    
      };
    
  return (
    <Card style={valueTooltipContainerStyle}>
      <Box sx={{
        position: 'relative',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',

      }}>
        <Box sx={{
          position: 'absolute',
          right: '2px',
          top: '2px',
  
        }}>
          <HelpTooltip 
            tooltip={helpText} />
        </Box>
        <Typography variant="h5" sx={{
          fontWeight: 700,
          color: 'rgba(0,0,0,.6)',
          textAlign: 'center',
          marginBottom: '8px',
          marginTop: '22px',
        }}>
          {title}
        </Typography>
        <Typography variant="h1" sx={{ 
          color: theme.color.white,
          textAlign: 'center',
          marginBottom: '8px',
          }}>
          {metric}
        </Typography>
        {changeLabel && (
          <FlexColumnContainer>
            <Typography variant="body2" sx={{ 
                color: theme.color.darkTurquoise,
                textAlign: 'center' }}>
              {changeLabel}
            </Typography>
          </FlexColumnContainer>
        )}
      </Box>
    </Card>
)
}