import React from 'react';
import Map from 'src/components/map/Map';
import CatchmentMap from 'src/components/catchment/Main'


const CatchmentPage = () => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <div style={{ padding: '10px' }}>
        <h1>Catchment Analytics Page</h1>
        <p>Some additional content here</p>
      </div>
      <div style={{ flex: 1 }}>
        <div style={{ position: 'relative', width: '800px', height: '400px' }}>
          <CatchmentMap
            year={2022}
            month={4}
            day={1}
            longitude={-79.3891437}
            latitude={43.6417798}
            name={"RogersCentre"}
            address={"99 demo st"}
            countryCode={"CA"}
        />
        </div>
      </div>
    </div>
  );
};

export default CatchmentPage;
