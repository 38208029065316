import * as React from 'react';
import {useState, useMemo, useEffect} from 'react';
import {render} from 'react-dom';
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl
} from 'react-map-gl';

import ControlPanel from './control-panel';
import Pin from './pin';

const TOKEN = 'pk.eyJ1IjoicnNiYXVtYW5uIiwiYSI6ImNqd2YyNTU0MjE3cHgzem51b3dzNjExYTgifQ.HtsOFBVFj1E0H2ttW5tMLA'; // Set your mapbox token here

export default function App({affiliates, center}) {
  const [popupInfo, setPopupInfo] = useState(null);
  const [viewport, setViewport] = useState({
    latitude: 50,
    longitude: -85,
    zoom: 4.2,
    bearing: 0,
    pitch: 0
  });

  const [pins, setPins] = useState(null)

//   const pins = useMemo(
//     () =>
//     CITIES?.map((affiliate, index) => (
//         <Marker
//           key={`marker-${index}`}
//           longitude={affiliate.longitude}
//           latitude={affiliate.latitude}
//           anchor="bottom"

//         >
//           <Pin />
//         </Marker>
//       )),
//     []
//   );

  useEffect(() => {
    if (center) {
      setViewport((prevState => ({ ...prevState,
        latitude: center.latitude,
        longitude: center.longitude
      })))
    }
  }, [center])

  useEffect(() => {
    if(affiliates[0]) {
      console.log(affiliates)
      setPopupInfo(null)
      let newPins = affiliates?.map((affiliate, index) => (
          <Marker
            key={`marker-${index}`}
            longitude={affiliate.longitude}
            latitude={affiliate.latitude}
            anchor="bottom"
            onClick={() => setPopupInfo(affiliate)}
          >
            <Pin />
          </Marker>
        ))
      console.log(newPins)
      setPins(newPins)
      console.log('pins set')
    }


  }, [affiliates]);


  return (
    <>
      <Map
        { ...viewport }
        mapStyle="mapbox://styles/mapbox/light-v9"
        mapboxApiAccessToken={TOKEN}
        viewport={viewport}
        onViewportChange={setViewport}
        width="100%"
        height="100%"
      >

        {pins}

        {popupInfo && (
          <Popup
            anchor="top"
            longitude={Number(popupInfo.longitude)}
            latitude={Number(popupInfo.latitude)}
            onClose={() => setPopupInfo(null)}
          >
            <div style={{marginTop: '7px'}}>
              {popupInfo.name}
            </div>
          </Popup>
        )}
      </Map>

      <ControlPanel affiliate={popupInfo}/>
    </>
  );
}

export function renderToDom(container) {
  render(<App />, container);
}
