import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

const useStyles = makeStyles((theme) => ({
  weekSelector: {
    display: 'grid',
    gridTemplateColumns: 'repeat(7, 1fr)',
    gap: theme.spacing(1),
  },
}));

const CustomWeekSelector = ({ selectedDate, onDateChange }) => {
  const classes = useStyles();

  const handleWeekSelection = (weekStartDate) => {
    onDateChange(weekStartDate);
  };

  // Create an array of dates representing the days in a week
  const getWeekDays = (startDate) => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = startDate.clone().add(i, 'days');
      days.push(currentDate);
    }
    return days;
  };

  // Render the week selector grid
  const renderWeekSelector = () => {
    const currentWeekStart = selectedDate.startOf('week');
    const weekDays = getWeekDays(currentWeekStart);

    return weekDays.map((day) => (
      <Button
        key={day.format('YYYY-MM-DD')}
        variant={selectedDate.isSame(day, 'day') ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => handleWeekSelection(day)}
      >
        {day.format('ddd')}
      </Button>
    ));
  };

  return (
    <div className={classes.weekSelector}>
      {renderWeekSelector()}
    </div>
  );
};

export default CustomWeekSelector;
