import React, { createContext, useState, useEffect } from 'react'
import { refreshAccessToken } from 'src/requests/api-requests'
import { constants } from 'src/utils'

const AuthContext = createContext()

const isTokenValid = async () => {
  const token = localStorage.getItem('token')

  if (!token) {
    return false
  }

  try {
    // Decode the token
    const decodedToken = JSON.parse(atob(token.split('.')[1]))
    const expirationTime = decodedToken.exp * 1000 // Convert to milliseconds
    const currentTime = new Date().getTime()

    // Check if the token is about to expire (e.g., within the next 5 minutes)
    const timeUntilExpiration = expirationTime - currentTime
    const shouldRefresh = timeUntilExpiration < 5 * 60 * 1000

    if (shouldRefresh) {
      // Attempt to refresh the token
      const refreshedToken = await refreshAccessToken()
      console.log(refreshedToken)
      if (refreshedToken) {
        // If refresh is successful, update the token in local storage
        console.log('Token refresh successful')
        localStorage.setItem('token', refreshedToken)
        return true
      } else {
        // If refresh fails, log the user out
        console.log('Token refresh failed')
        logout()
        return false
      }
    }

    return currentTime < expirationTime
  } catch (error) {
    // Handle decoding errors or other issues
    return false
  }
}

// Make sure to set authenticated = true after calling this function.
const login = async (username, password) => {
  try {
    const response = await fetch(`${constants.API_URL}v1/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    })

    if (!response.ok) {
      throw new Error('Authentication failed')
    }

    const data = await response.json()
    console.log('Setting new tokens')
    localStorage.setItem('token', data.access_token)
    localStorage.setItem('refreshToken', data.refresh_token)

    return data
  } catch (error) {
    throw new Error('Authentication failed')
  }
}

// Make sure to set authenticated=false after calling this function.
const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refreshToken')
}

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(() => {
    const token = localStorage.getItem('token')
    return token && isTokenValid(token)
  })

  useEffect(() => {

    if (!authenticated && !localStorage.getItem('token')) {
      return
    }

    const checkAuthentication = async () => {
      const token = localStorage.getItem('token')
      if (token && (await isTokenValid(token))) {
        setAuthenticated(true)
      } else {
        setAuthenticated(false)
        window.location.reload()
      }
    }

    checkAuthentication()
  }, [])

  return (
    <AuthContext.Provider value={{ authenticated, setAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider, isTokenValid, login, logout }
