import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TimeIncrementSelector = ({ selectedTimeIncrement, handleTimeIncrementChange }) => {
  const timeIncrements = ['Day', 'Week', 'Month', 'Year'];


  const style = {
    margin: '1rem',
  }

  return (
    <div style={style}>
      <FormControl fullWidth variant="outlined">
        <InputLabel>Select Time Increment</InputLabel>
        <Select
          value={selectedTimeIncrement}
          onChange={handleTimeIncrementChange}
          label="Select Time Increment"
        >
          {timeIncrements.map((timeIncrement, index) => (
            <MenuItem key={timeIncrement} value={timeIncrement}>
              {timeIncrement}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default TimeIncrementSelector;