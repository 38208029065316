import React, { useState, useContext, useEffect } from 'react'
import AppContext from 'src/contexts-global/AppContext'
import { Button, Box, Card, CardContent, Typography, Grid } from '@mui/material'
import CardTitle from 'src/components/common/card-title-template'
import FlexColumnContainer from 'src/components/common/flex-column-container'
import FlexRowContainer from 'src/components/common/flex-row-container'
import PersonaSelector from 'src/components/dashboard-toolbar/selectors/PersonaSelector'
import DateSelector from 'src/components/dashboard-toolbar/selectors/DateSelector'
import LocationSelector from 'src/components/dashboard-toolbar/selectors/LocationSelector'
import SendIcon from '@mui/icons-material/Send'
import dayjs, { Dayjs } from 'dayjs';

import SponsorSelector from './selectors/SponsorSelector'
import AffinityTypeSelector from './selectors/AffinityTypeSelector'

import PdfButton from '../pdf-button/PdfButton'
import { Send } from '@mui/icons-material'
import theme from 'src/themes'
const DashboardToolbar = ({ page, startDate, endDate, onDateChange, setEndDate, setStartDate }) => {
  // Global context
  const { appState, location, userData } = useContext(AppContext)
  const [selectedDate, setSelectedDate] = useState([    dayjs('2022-04-17'),
  dayjs('2022-04-21'),
])

  useEffect(()=>{
    setSelectedDate([startDate, endDate])
  }, [startDate, endDate])
  
  return (
    <Grid container>
      <Grid item md={8} mt="16px" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: '30px',
            }}
          >
            {userData?.logo && (
              <img
                src={userData.logo}
                width="auto"
                height="100"
                style={{ padding: '0.5rem' }}
                alt="Company Logo"
              ></img>
            )}
          </Box>
          <Box>
            {location && (
              <>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: '42px',
                    lineHeight: 'normal',
                  }}
                >
                  {location?.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '16px',
                    marginTop: '8px',
                  }}
                >
                  {`${location?.address}, ${location?.city}, ${location?.state}, ${location?.country}, ${location?.zipcode}`}
                </Typography>
              </>
            )}
            <Grid container columnSpacing={'16px'} mt="8px">
              <Grid
                item
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <PdfButton fileName="MyTrafficPage" />
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                {/* <Box mt="8px">
                  <Button
                    sx={{
                      backgroundColor: theme.color.pink,
                      '&:hover': {
                        backgroundColor: `${theme.color.pinkA80} !important`,
                      },
                    }}
                    startIcon={
                      <SendIcon
                        sx={{
                          fontSize: '22px !important',
                        }}
                      />
                    }
                  >
                    Send Email
                  </Button>
                </Box> */}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
      <Grid item md={4} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
        <LocationSelector />

        {/* Date selector */}

        {(page === 'traffic' || page === 'affiliate-brands') && (
          <Box
            sx={{
              marginTop: '16px',
            }}
          >
            <DateSelector
              type="dateRange"
              label="Select Date"
              selectedDate={[startDate, endDate]}
              onDateChange={(date) => {
                onDateChange(date)
              }}
            />
          </Box>
        )}

        {(page === 'affinity' || page === 'distance-travelled') && (
          <Box
            sx={{
              marginTop: '16px',
            }}
          >
            <DateSelector
              type="month"
              label="Select Month"
              selectedDate={endDate}
              onDateChange={onDateChange}
            />
          </Box>
        )}
        {page === 'affiliate-brands' && <SponsorSelector />}
        {page === 'affinity' && <AffinityTypeSelector />}

        {/* <PersonaSelector /> */}
      </Grid>
    </Grid>
  )
}

export default DashboardToolbar
