import React from 'react';
import theme from 'src/themes';

const LogoBar = () => {
  return (
    <div
      style={{
        position: 'static',
        height: '60px',
        backgroundColor: theme.color.bgBlue,
        zIndex: 2,
        boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset',
      }}
    >
    </div>
  );
};

export default LogoBar;
