export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT

const API_URL = process.env.REACT_APP_API_URL
export const PORTAL_URL = ENVIRONMENT
  ? `https://apply.${ENVIRONMENT}.fig.ca/`
  : 'http://localhost:3000/'
export const FLINKS_IFRAME_SRC = `${process.env.REACT_APP_FLINKS_IFRAME_BASE_URL}v2/`
export const LOANAMOUNT_STEP = 500
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE
export const FRESHCHAT_TOKEN = '2af4563a-4787-4613-9a3f-63341af13559'
export const FRESHCHAT_HOST = 'https://fig-help.freshchat.com'
export const FRESHCHAT_SCRIPT_URL = 'https://fig-help.freshchat.com/js/widget.js'
export const SEGMENT_WRITE_KEY = process.env.REACT_APP_ORIGINATION_FRONTEND_SEGMENT_WRITE_KEY
export const IS_PRODUCTION = ENVIRONMENT === 'production'
export const BORROWELL_DASHBOARD_URL = 'https://app.borrowell.com/#/app/dashboard'
export const BORROWELL_PERSONAL_LOAN_URL = 'https://app.borrowell.com/#/app/product/loans/personal'
export const EXPIRY_DETAIL = 'The authorization token is expired'
export const Q2_PORTAL_URL =
  ENVIRONMENT === 'production'
    ? 'https://my.fig.ca/s/login'
    : ENVIRONMENT === 'staging'
    ? 'https://figfinancial--figuat.sandbox.my.site.com/s/login/'
    : 'https://figfinancial--figinteg.sandbox.my.site.com/s/login/'
export const KM_TO_MILES = 1.609
export default {
  ENVIRONMENT,
  API_URL,
  PORTAL_URL,
  FLINKS_IFRAME_SRC,
  IS_PRODUCTION,
  KM_TO_MILES,
}
