import React, { useState, useContext, useEffect } from 'react'
import AppContext from 'src/contexts-global/AppContext'
import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import StoreIcon from '@mui/icons-material/Store'
import theme from 'src/themes'

const AffinityTypeSelector = () => {
  const { setSelectedAffinityType, selectedAffinityType, affinityTypesList } =
    useContext(AppContext)

  const handleAffinityTypeChange = event => {
    for (let i = 0; i < affinityTypesList.length; i++) {
      if (affinityTypesList[i].chip === event.target.value) {
        setSelectedAffinityType(affinityTypesList[i])
      }
    }
  }

  return (
    <Box marginTop="16px">
      <FormControl fullWidth>
        <InputLabel>Place Type</InputLabel>
        <Select
          value={selectedAffinityType?.chip ? selectedAffinityType.chip : ''}
          label="Place Type"
          onChange={handleAffinityTypeChange}
          startAdornment={
            <StoreIcon
              sx={{
                color: theme.color.midBlue,
                marginRight: '5px',
              }}
            />
          }
        >
          {affinityTypesList?.map(affinityType => (
            <MenuItem key={affinityType.chip} value={affinityType.chip}>
              {affinityType.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default AffinityTypeSelector
