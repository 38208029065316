import log from 'loglevel'

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || ''
const ENABLE_LOG = ['development', 'local', ''].includes(ENVIRONMENT)

export default {
  info: (...args: any[]) => ENABLE_LOG && log.info(...args),
  error: (...args: any[]) => {
    // TO DO: Add Sentry Logging
    ENABLE_LOG && log.error(...args)
  },
  exception: (...args: any[]) => {
    // TO DO: Add Sentry Logging
    ENABLE_LOG && log.error(...args)
  },
}
