import React from 'react';

const formatDataForPieChart = (props) => {

  const {selectedId, personas, inputData, dataPhonesPersonas} = props;
  
  if (selectedId === 1 || selectedId > dataPhonesPersonas.length + 1) {
    // For 'Totals' persona or non-existent persona, return the sum of data as the total
    const androidTotal = inputData[0];
    const iOSTotal = inputData[1];

    return [
      { label: 'Android', value: androidTotal },
      { label: 'iOS', value: iOSTotal },
    ];
  } else {
    // For other personas, return data in the required format
    const personaData = dataPhonesPersonas[selectedId - 2];
    return personaData.map((value, index) => ({
      label: personas[index + 1],
      value: value,
    }));
  }
};

export default formatDataForPieChart;
