import { useState, useLayoutEffect, useEffect, useContext } from 'react'
import AppContext from 'src/contexts-global/AppContext'
import Map from 'src/components/map/Map'
import ModularPieChart from 'src/components/graphs-charts/modularPie'
import ModularBarGraph from 'src/components/graphs-charts/modularBar'
import DeviceTypeBarGraph from 'src/components/affinity/DeviceTypeBarGraph'
import TimeIncrementSelector from 'src/components/common/time-increment-selector'
import formatDataForPieChart from 'src/components/affinity/FormatPieChartData' // identifies if totals or a persona is selected, and then assigns corresponding data.
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import HelpTooltip from 'src/components/common/tooltip-help'
import DataCard from 'src/components/common/data-display-card'
import MapCard from 'src/components/common/map-card'
import CardTitle from 'src/components/common/card-title-template'
import PageContainer from 'src/components/common/page-container'
import FlexColumnContainer from 'src/components/common/flex-column-container'
import FlexRowContainer from 'src/components/common/flex-row-container'
import AffinityTable from 'src/components/affinity/AffinityTable'
import DashboardToolbar from 'src/components/dashboard-toolbar/DashboardToolbar'
import dayjs from 'dayjs'
import { dummyData } from 'src/contexts-global/DummyData'
import { useQuery } from 'react-query'
import CustomCard from 'src/components/common/custom-card'
import theme from 'src/themes'
import { Groups } from '@mui/icons-material'
import PersonaSelector from 'src/components/common/persona-selector'
import { GetAffinityHits, GetTrafficMetrics } from 'src/requests/api-requests'

const AffinityPage = () => {
  // Global context (for persona)

  const {
    appState,
    setAppState,
    setIsLoading,
    setLocation,
    location,
    locationsList,
    setAllPersonas,
    setSelectedPersonas,
    affinityTypesList,
    setAffinityTypesList,
    selectedAffinityType,
    setSelectedAffinityType,
  } = useContext(AppContext)
  const personas = appState.selectedPersonas

  // Page state (for date & location)

  const [startDate, setStartDate] = useState(dayjs('2023-08-01'))
  const [endDate, setEndDate] = useState(dayjs('2023-08-31'))

  const [affinityHits, setAffinityHits] = useState(null)

  useLayoutEffect(() => {
    setIsLoading(true)
    setLocation(null)
    setSelectedPersonas([])
    setAllPersonas([])
    setStartDate(null)
    setEndDate(null)
  }, [])

  useEffect(() => {
    if (!locationsList) {
      return
    }

    if (locationsList[0]) {
      setLocation(locationsList[0])
    }
  }, [locationsList])

  // Temp default dates from Traffic
  useEffect(() => {
    if (location && localStorage.getItem('token')) {
      try {
        GetTrafficMetrics(startDate, endDate, location) // API request for metrics data
          .then(data => {
            if (data?.results) {
              for (const date in data.results) {
                if (data.results[date].overall) {
                  const total_count = data.results[date].overall.total_count || 0
                  const repeat_count = data.results[date].overall.repeat_count || 0
                  data.results[date].overall.first_time_count = total_count - repeat_count
                }
              }
            }
            return data
          })
          .then(data => {
            const dateStringList = Object.keys(data?.results)

            if (!startDate && !endDate) {
              const defaultStartDate = dayjs(dateStringList[0]).add(1, 'month')
              const defaultEndDate = dayjs(dateStringList[dateStringList.length - 1])

              setStartDate(defaultStartDate)
              setEndDate(defaultEndDate)
            }
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }, [location, endDate])

  useEffect(() => {
    if (true) {
      // Temporary hardcoded options for selector
      const dummyAffinityTypesList = [
        { chip: 'airport', label: 'Airport' },

        { chip: 'bank', label: 'Bank' },

        { chip: 'bar', label: 'Bar/Pub' },

        { chip: 'beauty_salon', label: 'Beauty (Beauty Salon)' },
        { chip: 'hair_care', label: 'Beauty (Hair Care)' },
        { chip: 'spa', label: 'Beauty (Spa)' },

        { chip: 'cafe', label: 'Cafe' },

        { chip: 'car_dealer', label: 'Car Dealer' },

        { chip: 'casino', label: 'Casino' },

        { chip: 'convenience_store', label: 'Convenience' },

        { chip: 'drugstore', label: 'Drugstore' },
        { chip: 'meal_takeaway', label: 'Fast Food' },

        { chip: 'gas_station', label: 'Gas Station' },

        { chip: 'city_hall', label: 'Government (City Hall)' },
        { chip: 'local_government_office', label: 'Government (Local Office)' },

        { chip: 'gym', label: 'Gym' },

        { chip: 'hospital', label: 'Hospital' },

        { chip: 'library', label: 'Library' },

        { chip: 'liquor_store', label: 'Liquor Store' },

        { chip: 'lodging', label: 'Lodging' },

        { chip: 'movie_theater', label: 'Movie Theater' },

        { chip: 'museum', label: 'Museum' },

        { chip: 'night_club', label: 'Night Club' },

        { chip: 'pharmacy', label: 'Pharmacy' },

        { chip: 'restaurant', label: 'Restaurant' },

        { chip: 'electronics_store', label: 'Retail (Electronics)' },
        { chip: 'furniture_store', label: 'Retail (Furniture)' },
        { chip: 'book_store', label: 'Retail (Book)' },
        { chip: 'clothing_store', label: 'Retail (Clothing)' },
        { chip: 'shoe_store', label: 'Retail (Shoe)' },
        { chip: 'hardware_store', label: 'Retail (Hardware)' },
        { chip: 'jewelry_store', label: 'Retail (Jewelry)' },
        { chip: 'pet_store', label: 'Retail (Pet)' },

        { chip: 'school', label: 'School' },
        { chip: 'primary_school', label: 'School (Primary)' },
        { chip: 'secondary_school', label: 'School (Secondary)' },
        { chip: 'university', label: 'School (University)' },

        { chip: 'shopping_mall', label: 'Shopping Mall' },

        { chip: 'stadium', label: 'Stadium' },

        { chip: 'supermarket', label: 'Supermarket' },

        { chip: 'bus_station', label: 'Transportation (Bus Station)' },
        { chip: 'light_rail_station', label: 'Transportation (Light Rail Station)' },
        { chip: 'parking', label: 'Transportation (Parking)' },
        { chip: 'subway_station', label: 'Transportation (Subway Station)' },
        { chip: 'taxi_stand', label: 'Transportation (Taxi Stand)' },
        { chip: 'train_station', label: 'Transportation (Train Station)' },
        { chip: 'transit_station', label: 'Transportation (Transit Station)' },

        { chip: 'tourist_attraction', label: 'Tourist Attraction' },
        { chip: 'museum', label: 'Tourist Attraction (Museum)' },
        { chip: 'art_gallery', label: 'Tourist Attraction (Art Gallery)' },
        { chip: 'amusement_park', label: 'Tourist Attraction (Amusement Park)' },
        { chip: 'aquarium', label: 'Tourist Attraction (Aquarium)' },
        { chip: 'zoo', label: 'Tourist Attraction (Zoo)' },
      ]

      // Set options for selector
      setAffinityTypesList(dummyAffinityTypesList)

      // Set selector to the first option in the list'
      setSelectedAffinityType({ chip: 'restaurant', label: 'Restaurant' })
    }
  }, [location, endDate])

  useEffect(() => {
    if (selectedAffinityType) {
      const fetchData = async () => {
        try {
          await GetAffinityHits(selectedAffinityType.chip, location).then(data => {
            console.log(data)
            setAffinityHits(data)
          })
          setIsLoading(false) // Set loading state to false when context has been updated
        } catch (error) {
          console.error('Error fetching data:', error)
          setIsLoading(false) // Handle error by setting loading state to false
        }
      }

      fetchData()
    }
  }, [selectedAffinityType])

  // Handlers

  const handleMonthChange = newMonth => {
    let newStartDate = newMonth.startOf('month')
    let newEndDate = newMonth.endOf('month')
    setStartDate(newStartDate)
    setEndDate(newEndDate)
  }

  // time increment change for persona selector

  const [selectedId, setSelectedId] = useState(1)

  const handleIdChange = event => {
    setSelectedId(parseInt(event.target.value, 10))
  }

  const [selectedTimeIncrement, setSelectedTimeIncrement] = useState('Day')

  const handleTimeIncrementChange = event => {
    setSelectedTimeIncrement(event.target.value)
  }

  // data for basic chart

  const phoneTypeDaily = ['Android', 'IOS']

  const customColors = [theme.color.midBlue, theme.color.turquoise]

  // switch for time inverval selection Phone comparisons

  let dataPhones = []

  switch (selectedTimeIncrement) {
    case 'Day':
      dataPhones = [460, 400]
      break
    case 'Week':
      dataPhones = [4000, 2020]
      break
    case 'Month':
      dataPhones = [12000, 4080]
      break
    case 'Year':
      dataPhones = [200000, 112500]
      break
    default:
      break
  }

  // Chart input data

  const inputData = dataPhones
  const inputLabel = phoneTypeDaily

  // Personas

  const personasWithTotal = [
    'Totals',
    'Health',
    'Entertainment',
    'Sports',
    'Coffee Fan',
    'Nightlife',
    'Asian Grocery',
    'Nature Lover',
  ]

  const personasSansTotal = [
    'Health',
    'Entertainment',
    'Sports',
    'Coffee Fan',
    'Nightlife',
    'Asian Grocery',
    'Nature Lover',
  ]

  // switch for phones by persona

  let dataPhonesPersonas = []

  switch (selectedTimeIncrement) {
    case 'Day':
      dataPhonesPersonas = [
        [80, 120], // [iOS, Android] distribution for 'Health'
        [100, 60], // [iOS, Android] distribution for 'Entertainment'
        [35, 45], // [iOS, Android] distribution for 'Sports'
        [65, 75], // [iOS, Android] distribution for 'Coffee Fan'
        [160, 120], // [iOS, Android] distribution for 'Nightlife'
      ]
      break
    case 'Week':
      dataPhonesPersonas = [
        [800, 600], // [iOS, Android] distribution for 'Health'
        [750, 370], // [iOS, Android] distribution for 'Entertainment'
        [300, 260], // [iOS, Android] distribution for 'Sports'
        [600, 380], // [iOS, Android] distribution for 'Coffee Fan'
        [1120, 840], // [iOS, Android] distribution for 'Nightlife'
      ]
      break
    case 'Month':
      dataPhonesPersonas = [
        [3200, 2800], // [iOS, Android] distribution for 'Health'
        [2700, 2100], // [iOS, Android] distribution for 'Entertainment'
        [1200, 1200], // [iOS, Android] distribution for 'Sports'
        [2200, 2000], // [iOS, Android] distribution for 'Coffee Fan'
        [5200, 3200], // [iOS, Android] distribution for 'Nightlife'
      ]
      break
    case 'Year':
      dataPhonesPersonas = [
        [45000, 30000], // [iOS, Android] distribution for 'Health'
        [45000, 15000], // [iOS, Android] distribution for 'Entertainment'
        [18000, 12000], // [iOS, Android] distribution for 'Sports'
        [35000, 17500], // [iOS, Android] distribution for 'Coffee Fan'
        [90000, 15000], // [iOS, Android] distribution for 'Nightlife'
      ]
      break
    default:
      break
  }

  // Bar Data //

  // Switch for time interval selected //

  let barGraphPhoneData = []

  switch (selectedTimeIncrement) {
    case 'Day':
      barGraphPhoneData = [24, 50, 56, 30, 20, 35, 60]
      break
    case 'Week':
      barGraphPhoneData = [340, 200, 560, 600, 100, 150, 800]
      break
    case 'Month':
      barGraphPhoneData = [2000, 2000, 6600, 4000, 3000, 2500, 4000]
      break
    case 'Year':
      barGraphPhoneData = [26000, 54000, 50000, 39000, 25000, 25000, 68000]
      break
    default:
      break
  }

  const barGraphData = {
    labels: personasSansTotal,

    datasets: [
      {
        data: barGraphPhoneData,
        backgroundColor: ['#62E467', '#FFD232', '#84B6FF', '#FB8500', '#ef476f', 'purple', 'aqua'],
        borderColor: [],
        // borderWidth: 1
      },
    ],
  }

  const barOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    barThickness: 30,

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }
  // props for piechart //

  const propsForPieChart = {
    selectedId: selectedId,
    personas: personasWithTotal,
    inputData: inputData,
    dataPhonesPersonas: dataPhonesPersonas,
  }

  // props for map

  const mapDefaultProps = {
    center: {
      lat: 37.7749,
      lng: -122.4194,
    },
    zoom: 13,
  }

  // styles
  const graphContainerStyle = {
    minHeight: '500px',
    margin: '.5rem',
  }

  const textAlign = {
    textAlign: 'center',
  }

  // Render
  return (
    <PageContainer>
      <Box
        container
        sx={{
          paddingLeft: '32px',
          paddingRight: '32px',
          marginTop: '32px',
          marginBottom: '32px',
        }}
      >
        <DashboardToolbar page="affinity" endDate={endDate} onDateChange={handleMonthChange} />
      </Box>

      {/* First Row: Before Visits Pie Chart and Map */}
      <Grid
        container
        mt="16px"
        sx={{
          paddingLeft: '32px',
          paddingRight: '32px',
          maxHeight: '600px',
        }}
      >
        <Grid item md={4}>
          <CustomCard
            body={
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography variant="h2" mt="16px" mb="16px">
                  Overall Device Types
                </Typography>
                <Box>
                  <ModularPieChart
                    colors={customColors}
                    data={
                      selectedId !== 1
                        ? formatDataForPieChart({
                            ...propsForPieChart,
                            dataPhonesPersonas: dataPhonesPersonas,
                          })
                        : formatDataForPieChart({
                            ...propsForPieChart,
                            dataPhonesPersonas: dataPhones, // If selectedId is 1, use dataPhones instead
                          })
                    }
                    inputLabel={inputLabel}
                  />
                </Box>
              </Box>
            }
          />
          {/* <Card style={graphContainerStyle}>    
                <HelpTooltip 
                tooltip={'Related visits before and after the main event, sorted by phone type.'}
                />
                <CardContent style={textAlign}>
                  <CardTitle>
                    Before Visits
                  </CardTitle>
                    <DataCard>
                    </DataCard>
                </CardContent>
                <CardContent style={textAlign}>
                  <CardTitle>
                    After Visits
                  </CardTitle>
                <DataCard>
                  <ModularPieChart
                      color={customColors}
                      data={
                        selectedId !== 1
                          ? formatDataForPieChart({
                              ...propsForPieChart,
                              dataPhonesPersonas: dataPhonesPersonas,
                            })
                          : formatDataForPieChart({
                              ...propsForPieChart,
                              dataPhonesPersonas: dataPhones, // If selectedId is 1, use dataPhones instead
                            })
                      }
                      inputLabel={inputLabel}
                      style={{ height: '80%', width: 'auto' }}
                    />
                </DataCard>    
                </CardContent>
              </Card> */}
        </Grid>
        <Grid item md={8}>
          <CustomCard
            body={
              <>
                {location && (
                  <Map
                    defaultCenter={{
                      lat: location?.latitude,
                      lng: location?.longitude,
                    }}
                    location={location}
                    affinityHits={affinityHits}
                    defaultZoom={mapDefaultProps.zoom}
                    style={{ width: '100%', height: '100%' }}
                  />
                )}
              </>
            }
          />
        </Grid>
      </Grid>

      {/* Second Row: After Visits Pie Chart and DeviceTypeBarGraph */}
      {/* <FlexRowContainer>
            <div style={{ width: '33%'}}>
              <Card style={{minWidth: '200px',
                            minHeight: '220px', 
                            margin: '.5rem',}}>
                <HelpTooltip tooltip={'Total visits by persona'} />
                <CardContent style={{ textAlign: 'center' }}>
                  <CardTitle>
                    Totals:
                  </CardTitle>
                  <DataCard>
                    <ModularBarGraph
                        data={barGraphData}
                        options={barOptions}
                    /> 
                  </DataCard>  
                </CardContent>
              </Card>
            </div>
            <div style={{ width: '66%' }}>
              <Card style={graphContainerStyle}>
                <HelpTooltip
                tooltip={'Visits broken down by persona and phone type.'}
                />
                <CardContent style={{ textAlign: 'center' }}>
                    <CardTitle>
                      Device Types
                    </CardTitle>
                    <DataCard>
                      <DeviceTypeBarGraph />
                    </DataCard>
                </CardContent>
              </Card>
            </div>
          </FlexRowContainer>   */}
      <Grid
        container
        mt="32px"
        sx={{
          paddingLeft: '32px',
          paddingRight: '32px',
          maxHeight: '600px',
        }}
      >
        <Grid item md={12}>
          <AffinityTable data={affinityHits} />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: 'block',
          marginTop: '64px',
          backgroundImage: "url('images/header-fade@2x.png')",
          backgroundRepeat: 'repeat-x',
          backgroundPosition: 'center top',
          width: '100%',
        }}
      >
        {/* <Grid container padding="32px" mb="32px" mt="16px" rowSpacing={'16px'}>
          <Grid item md={8}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Groups
                fontSize="50px"
                marginRight="8px"
                sx={{
                  fontSize: '55px',
                  color: theme.color.white,
                  marginRight: '12px',
                }}
              />
              <Typography
                variant="h1"
                sx={{
                  color: theme.color.white,
                  fontSize: '50px',
                }}
              >
                Persona Analysis
              </Typography>
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: theme.color.white,
                fontSize: '18px',
                marginTop: '8px',
              }}
            >
              Gain insights by understanding how your customers behave. <br /> Personas give you a
              sense of what market segments visit you, and the differences between them.
            </Typography>
          </Grid>
          <Grid item md={4}>
            <PersonaSelector />
          </Grid>
          <Grid item md={12}>
            <CustomCard
              backgroundColor="rgba(255, 255, 255, 0.9)"
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <HelpTooltip
                    sx={{
                      position: 'absolute',
                      right: '16px',
                      top: '16px',
                    }}
                    tooltip={
                      'The average dwell time per visit (in minutes) for each day of the specified week.'
                    }
                  />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2">Average Dwell Time</Typography>
                    <DataCard></DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>
        </Grid> */}
      </Box>
    </PageContainer>
  )
}

export default AffinityPage
