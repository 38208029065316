import { isTokenValid, logout } from 'src/auth/AuthProvider'
import { constants } from 'src/utils'

// Function to handle API requests with authentication
export const makeAuthenticatedRequest = async (url, options) => {
  const token = localStorage.getItem('token')

  if (!token || !isTokenValid(token)) {
    // Token is invalid or expired, attempt to refresh
    await refreshAccessToken()
  }

  // Get the new token after refresh
  const newToken = localStorage.getItem('token')

  // Make the API request with the new token
  return fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      Authorization: `Bearer ${newToken}`,
    },
  })
}

// Function to refresh the access token
export const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refreshToken')

  if (!refreshToken) {
    return null
  }

  console.log(refreshToken)

  try {
    const response = await fetch(`${constants.API_URL}v1/token_refresh/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refresh: refreshToken }),
    })

    if (!response.ok) {
      throw new Error('Token refresh failed')
    }

    const data = await response.json()
    console.log('Fetched refresh access token:')
    console.log(data.access)
    localStorage.setItem('token', data.access)
    return data.access
  } catch (error) {
    console.error('Token refresh error:', error)
    return null
  }
}

// API request for client data
export const GetClient = async () => {
  try {
    let url = `${constants.API_URL}v1/client/`

    const response = await makeAuthenticatedRequest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('API request failed')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// API request for list of locations
export const GetLocationsList = async () => {
  try {
    let url = `${constants.API_URL}v1/locations/`

    const response = await makeAuthenticatedRequest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('API request failed')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const GetUserSettings = async () => {
  try {
    let url = `${constants.API_URL}v1/user_settings/`

    const response = await makeAuthenticatedRequest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('API request failed')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// API request for list of sponsors
export const GetAffiliates = async (affiliateGroupID, locationID) => {
  try {
    let url = `${constants.API_URL}v1/affiliates/?affiliate_group_id=${affiliateGroupID}&location_id=${locationID}`

    const response = await makeAuthenticatedRequest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('API request failed')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// API request for My Traffic data
export const GetTrafficMetrics = async (startDate, endDate, location) => {
  try {
    const location_id = location?.id
    let url = `${constants.API_URL}v1/metrics/traffic/?location_id=${location_id}`

    if (startDate && endDate) {
      const start_date = startDate.format('YYYY-MM-DD')
      const end_date = endDate.format('YYYY-MM-DD')
      url += `&start_date=${start_date}&end_date=${end_date}`
    } else {
      url += `&default_dates=true`
    }

    const response = await makeAuthenticatedRequest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('API request failed')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// API request for list of sponsors
export const GetAffinityHits = async (affinityTypeID, location) => {
  try {
    let url = `${constants.API_URL}v1/getAffinityHits/?latitude=${location.latitude}&longitude=${location.longitude}&month=7&place_type=${affinityTypeID}&platform=gli`

    const response = await makeAuthenticatedRequest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })

    if (response.ok) {
      return await response.json()
    } else {
      throw new Error('API request failed')
    }
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// Return default ending month for Distance Travelled page (check last available day in db)
export const GetDistanceTravelledDefaultEndDate = async (location) => {
  try {
    const location_id = location.id
    const url = `${constants.API_URL}v1/distanceTravelledDefaultEndDate?locationId=${location_id}`
    const response = await makeAuthenticatedRequest(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) {
      throw new Error('API request (GetDistanceTravelledDefaultEndDate) failed')
    }
    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

// API requests for Distance Travelled data (2 requests)
export const GetDistanceTravelledMetrics = async (endDate, location) => {
  try {
    if (!endDate) {
      throw new Error('API Request failed (endDate does not exist)')
    }
    const location_id = location.id
    let urlDate = ''

    const month = endDate.add(1, 'month').month()
    const year = endDate.year()
    urlDate += `&month=${month}`
    urlDate += `&year=${year}`
      
    const persona = 'overall'
    let urlCity = ''
    let urlPostalCode = ''
    if (location?.country === 'US') {
      // Visit % by City
      urlCity = `${constants.API_URL}v1/distanceTravelled?analyticsCategory=totalVisits&fileName=adm2.json&locationId=${location_id}&persona=${persona}&regionCode=US&whenValue=month`
      // Visit % by Postal Code
      urlPostalCode = `${constants.API_URL}v1/distanceTravelled?analyticsCategory=totalVisits&fileName=pos4.json&locationId=${location_id}&persona=${persona}&regionCode=US&whenValue=month`
    } else {
      // Visit % by City
      urlCity = `${constants.API_URL}v1/distanceTravelled?analyticsCategory=totalVisits&fileName=sta2.json&locationId=${location_id}&persona=${persona}&regionCode=CA&whenValue=month`
      // Visit % by Postal Code
      urlPostalCode = `${constants.API_URL}v1/distanceTravelled?analyticsCategory=totalVisits&fileName=pos3.json&locationId=${location_id}&persona=${persona}&regionCode=CA&whenValue=month`
    }
    const response = await makeAuthenticatedRequest(urlCity + urlDate, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('API request 1 (city data) failed')
        }
        return response.json()
      })
      .then(async data1 => {
        const data2 = await makeAuthenticatedRequest(urlPostalCode + urlDate, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }).then(response => {
          if (!response.ok) {
            throw new Error('API request 2 (postal code data) failed')
          }
          return response.json()
        })
        // Merge data from the two requests into one object
        let data = {
          city: {},
          postalCode: {},
        }
        data.city = data1
        data.postalCode = data2
        return data
      })
    return response
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}

export const GetDistanceTravelledPersona = async () => {
  try {
    const response = {
      coffee_shop_lovers: {
        mean: 16,
        median: 20,
        topCity: 'Toronto',
        topPostalCode: 'M5V',
      },
      discount_shoppers: {
        mean: 35,
        median: 28,
        topCity: 'Toronto',
        topPostalCode: 'M4F',
      },
      fast_food: {
        mean: 23,
        median: 24,
        topCity: 'Mississauga',
        topPostalCode: 'L5T',
      },
      grocery_shoppers: {
        mean: 53,
        median: 47,
        topCity: 'Toronto',
        topPostalCode: 'M4T',
      },
    }

    return response
  } catch (error) {
    console.error('Error fetching data:', error)
  }
}
